import _DatabaseModel from "./_DatabaseModel";

class Audit extends _DatabaseModel {
  static model = "audit";
  static showSize = 8;
  static editDialogComponent = "audits-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name", sortable: false },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["activity", "destroy"],
  };

  static filter = {
    parts: {
      fields: ["name", "id"],
      extended_fields: ["description"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["report"];

  static basicInfo(object) {
    return [
      {
        labelKey: "audit.label.audit_organization",
        value: object.standard_soa.person,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "audit.label.representative",
        value: object.representative,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "audit.label.auditor",
        value: object.auditor,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "standard.standard",
        value: object.standard_soa.standard.name,
        type: "plain",
      },
      {
        labelKey: "audit.label.submission_date",
        value: object.submission_date,
        type: "date",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "audit.label.requirements",
      permission: "audit_tab_valuation",
      component: "AuditsValuationTab",
      input_data: (context) => context.object.standard_soa?.standard.sections,
      disabled: () => false,
    },
    {
      titleKey: "standard_soa.standard_soa",
      permission: "audit_tab_soa",
      component: "AuditsValuationTab",
      input_data: (context) => context.object.standard_soa?.standard.sections,
      parameters: { soaAuditTab: true },
      disabled: () => false,
    },
    {
      titleKey: "document.documents",
      permission: "audits_tab_documents",
      component: "DocumentsTab",
      disabled: () => false,
    },
  ];
}

export default Audit;
