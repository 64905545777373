<template>
  <div class="py-2">
    <h4>{{ $t("background_job.background_job") }}</h4>
    <pre>{{ item.object.handler }}</pre>
    <h4 class="pt-2">{{ $t("background_job.label.last_error") }}</h4>
    <pre>{{ item.object.last_error }}</pre>
  </div>
</template>

<script>
export default {
  name: "BackgroundJobExpandDetail",
  props: {
    item: Object,
  },
};
</script>

<style scoped></style>
