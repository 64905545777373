<template>
  <v-form v-if="value" class="pt-2">
    <div v-if="selectedFields">
      <div
        v-for="field of selectedFields.filter((i) =>
          multiSearchItems.includes(i)
        )"
        :key="field"
      >
        <filter-multi-search-component
          v-model="value[field]"
          :label="$t(`form.label.${field}`)"
          :scanner="field === 'id' && model === 'asset'"
        />
      </div>
    </div>

    <v-select
      v-if="checkField('solve_status')"
      v-model="value.solve_status"
      :items="basicMixin_toSelect(solve_status)"
      :label="$t('assets_issue.label.solve_status')"
      multiple
      clearable
    />

    <v-select
      v-if="checkField('automated_action_type')"
      v-model="value.automated_action_type"
      :items="klass.translatedSelect('automated_action_types')"
      :label="$t('automated_action.label.automated_action_type')"
    />

    <v-select
      v-if="checkField('run_type')"
      v-model="value.run_type"
      :items="klass.translatedSelect('run_types')"
      :label="$t('automated_action.label.run_type')"
    />

    <issue-state-loadable-autocomplete
      v-if="checkField('issue_state_id')"
      v-model="value.issue_state_id"
      multiple
      clearable
      :on-screen="filterDrawer"
      without
    />

    <issue-priority-loadable-autocomplete
      v-if="checkField('issue_priority_id')"
      v-model="value.issue_priority_id"
      multiple
      clearable
      :on-screen="filterDrawer"
      without
    />

    <tag-definition-loadable-autocomplete
      v-if="checkField('tag_definitions')"
      v-model="value.tag_definitions"
      multiple
      clearable
      :on-screen="filterDrawer"
      without
      :model="model"
    />

    <v-select
      v-if="checkField('calculation')"
      v-model="value.calculation"
      :label="$t('kpi.label.calculation')"
      :items="klass.translatedSelect('calculations')"
    >
    </v-select>

    <v-select
      v-if="checkField('visualization')"
      v-model="value.visualization"
      :label="$t('kpi.label.visualization')"
      :items="klass.translatedSelect('visualizations')"
    >
    </v-select>

    <v-select
      v-if="checkField('transaction_type')"
      v-model="value.transaction_type"
      :items="basicMixin_toSelect(transaction_type)"
      :label="$t('warehouse_transaction.label.transaction_type')"
      multiple
      clearable
    ></v-select>

    <!-- TODO: check autocomplete="chrome-off" for external_email -->
    <template v-for="m in loadableFields">
      <base-loadable-autocomplete
        v-if="checkField(`${m}_id`)"
        :key="m"
        v-model="value[`${m}_id`]"
        :model="m"
        :multiple="m !== 'audit'"
        clearable
        :on-screen="filterDrawer"
      />
    </template>

    <base-loadable-autocomplete
      v-for="field of selectedFields.filter((f) => warehouseItems.includes(f))"
      :key="field"
      v-model="value[field]"
      model="warehouse"
      :label="$t(`form.label.${field}`)"
      multiple
      clearable
      :on-screen="filterDrawer"
    ></base-loadable-autocomplete>

    <v-select
      v-if="checkField('service_type')"
      v-model="value.service_type"
      :items="klass.translatedSelect('service_types')"
      :label="$t('service.label.service_type')"
    ></v-select>

    <v-select
      v-if="checkField('price_type')"
      v-model="value.price_type"
      :items="klass.translatedSelect('price_types')"
      :label="$t('price_item.label.price_type')"
    ></v-select>

    <v-select
      v-if="checkField('data_type')"
      v-model="value.data_type"
      :items="klass.translatedSelect('data_types')"
      :label="$t('template.label.data_type')"
    ></v-select>

    <input-date-picker-dialog
      v-if="checkField('effective_at')"
      v-model="value.effective_at"
      :label="$t('contract.label.effective_at')"
    ></input-date-picker-dialog>

    <v-autocomplete
      v-if="checkField('activity_type')"
      v-model="value.activity_type"
      :items="klass.translatedSelect('activity_types')"
      :label="$t('activity.list_label.activity_type')"
      :filter="customFilter"
      clearable
      multiple
      :append-icon="mdiCheckAll"
      @click:append="
        $set(
          value,
          'activity_type',
          klass.translatedSelect('activity_types').map((i) => i.value)
        )
      "
    />

    <v-select
      v-if="checkField('report_type')"
      v-model="value.report_type"
      :items="klass.translatedSelect('report_types')"
      :label="$t('report.label.report_type')"
    ></v-select>

    <v-select
      v-if="checkField('target_entity')"
      v-model="value.target_entity"
      :items="klass.translatedSelect('target_entities')"
      :label="$t('data_set.label.target_entity')"
    ></v-select>

    <input-date-picker-dialog
      v-if="checkField('valid_at')"
      v-model="value.valid_at"
      :label="$t('rule.label.valid_at')"
    ></input-date-picker-dialog>

    <base-loadable-autocomplete
      v-if="checkField('service_id')"
      v-model="value.service_id"
      model="service"
      multiple
      clearable
      :on-screen="filterDrawer"
      without
    ></base-loadable-autocomplete>

    <base-searcher
      v-if="checkField('asset_id')"
      v-model="value.asset_id"
      type="asset"
      :label="$t('asset.asset')"
      multiple
      load-names
    ></base-searcher>

    <base-searcher
      v-if="checkField('asset_resource_id')"
      v-model="value.asset_resource_id"
      type="asset"
      :label="$t('form.label.asset_resource_id')"
      multiple
      load-names
    ></base-searcher>

    <base-searcher
      v-if="checkField('parent_id')"
      v-model="value.parent_id"
      :type="model === 'issue' ? 'asset' : model"
      :label="$t('form.label.parent_id')"
      multiple
      load-names
    ></base-searcher>

    <base-loadable-autocomplete
      v-if="checkField('category_id')"
      v-model="value.category_id"
      :model="`${model}_category`"
      multiple
      clearable
      :on-screen="filterDrawer"
      :label="$t('form.label.category_id')"
    ></base-loadable-autocomplete>

    <base-searcher
      v-if="checkField('person_id')"
      v-model="value.person_id"
      type="person"
      :label="
        model === 'issue'
          ? $t('issue_assignee.issue_assignee')
          : model === 'activity' || model === 'document'
          ? $t('form.label.creator_id')
          : model === 'soa' || model === 'standard_soa'
          ? $t('form.label.organization_id')
          : $t('person.person')
      "
      multiple
      load-names
    ></base-searcher>

    <base-loadable-autocomplete
      v-if="checkField('person_relation_id')"
      v-model="value.person_relation_id"
      model="person_relation"
      multiple
      clearable
      :filter="
        (i) => {
          return i.entity_type === toModelUpperCase(model);
        }
      "
      :on-screen="filterDrawer"
    />

    <v-select
      v-if="checkField('tier')"
      v-model="value.tier"
      :items="klass.translatedSelect('tier_types')"
      :label="$t('license.label.type')"
      multiple
      clearable
    ></v-select>

    <v-select
      v-if="checkField('contract_type')"
      v-model="value.contract_type"
      :items="klass.translatedSelect('contract_types')"
      :label="$t('form.label.contract_type')"
      clearable
    ></v-select>

    <v-combobox
      v-if="checkField('entity_id')"
      v-model="value.entity_id"
      :label="
        model === 'activity'
          ? $t('activity.list_label.object_id')
          : $t('form.label.relation_type_id')
      "
      multiple
      small-chips
      clearable
      append-icon=""
    >
      <template #selection="{ item, parent }">
        <v-chip small close @click:close="parent.selectItem(item)">
          {{ item }}
        </v-chip>
      </template>
    </v-combobox>

    <v-autocomplete
      v-if="checkField('entity_type') && model === 'activity'"
      v-model="value.entity_type"
      :items="objectTypeArr"
      :label="$t('activity.list_label.object_type')"
      item-value="id"
      item-text="name"
      :filter="customFilter"
      clearable
      multiple
    />

    <v-autocomplete
      v-if="checkField('entity_type') && model !== 'activity'"
      v-model="value.entity_type"
      :items="basicMixin_toSelect(entity_type)"
      :label="$t('form.label.person_relation_id')"
      :filter="customFilter"
      clearable
      multiple
      :append-icon="mdiCheckAll"
      @click:append="
        $set(
          value,
          'entity_type',
          Object.keys(entity_type).filter((k) => k !== '0')
        )
      "
    />

    <v-select
      v-if="checkField('subscription_type')"
      v-model="value.subscription_type"
      :items="klass.translatedSelect('subscription_types')"
      :label="$t('form.label.subscription_type')"
      multiple
      clearable
    ></v-select>

    <v-select
      v-if="checkField('currency')"
      v-model="value.currency"
      :items="klass.currencies"
      :label="$t('price_list.label.currency')"
      clearable
    ></v-select>

    <filter-size-condition
      v-if="checkField('file_size_range')"
      v-model="value.file_size_range"
    />

    <base-searcher
      v-if="checkField('customer_id')"
      v-model="value.customer_id"
      type="person"
      :label="$t(`form.label.customer_id`)"
      multiple
      load-names
      self-off
    ></base-searcher>
    <base-searcher
      v-if="checkField('service_organization_id')"
      v-model="value.service_organization_id"
      type="person"
      :label="$t(`form.label.service_organization_id`)"
      multiple
      load-names
      self-off
    ></base-searcher>
    <base-searcher
      v-if="checkField('creator_id')"
      v-model="value.creator_id"
      type="person"
      :label="$t(`form.label.creator_id`)"
      multiple
      load-names
    ></base-searcher>

    <base-loadable-autocomplete
      v-if="checkField('external_email_id')"
      v-model="value.external_email_id"
      model="external_email"
      multiple
      clearable
      :on-screen="filterDrawer"
      :transform="(i) => ({ id: i.id, name: i.email })"
    />

    <v-select
      v-if="checkField('status')"
      v-model="value.status"
      :items="basicMixin_toSelect(status)"
      :label="$t('requirement.label.status')"
      multiple
      clearable
    />
    <base-loadable-autocomplete
      v-if="checkField('connector_id')"
      v-model="value.connector_id"
      model="connector"
      :label="
        model === 'activity'
          ? $t('form.label.activity.connector_id')
          : $t('form.label.connector_id')
      "
      multiple
      clearable
      :on-screen="filterDrawer"
      :without="model !== 'activity'"
    />
    <filter-time-condition
      v-if="checkField('time_filter')"
      v-model="value.time_filter"
      :fields="parts.time_filter"
    ></filter-time-condition>
    <filter-template-condition
      v-if="checkField('detail')"
      v-model="value.detail"
      :type="model"
    ></filter-template-condition>
    <filter-template-condition
      v-if="checkField('detail_on_asset')"
      v-model="value.detail_on_asset"
      type="asset"
      label="form.label.detail_on_asset"
    ></filter-template-condition>

    <v-checkbox
      v-if="checkField('author_system')"
      v-model="value.author_system"
      :label="$t('form.label.author_system')"
    ></v-checkbox>

    <div v-for="tt of toggleItems" :key="tt">
      <filter-toggle
        v-if="checkField(tt) && klass"
        v-model="value[`${tt}`]"
        :options="klass[`${tt}_options`]"
        class="pt-3"
      ></filter-toggle>
    </div>
  </v-form>
</template>

<script>
import { MODELS } from "../../../../_config/constants";
import BasicMixin from "../../../mixins/BasicMixin";
import FilterToggle from "../../../../_functions/pages/data_sets/filter/FilterToggle";
import FilterMultiSearchComponent from "../../../../_functions/pages/data_sets/filter/FilterMultiSearchComponent";
import FilterTimeCondition from "../../../../_functions/pages/data_sets/filter/FilterTimeCondition";
import FilterSizeCondition from "../../../../_functions/pages/data_sets/filter/FilterSizeCondition";
import BaseSearcher from "../../../../_functions/pages/components/searcher/BaseSearcher";
import InputDatePickerDialog from "../inputs/InputDatePickerDialog";
import FilterTemplateCondition from "../../../../_functions/pages/data_sets/filter/FilterTemplateCondition";
import { mdiCheckAll } from "@mdi/js";
import BaseLoadableAutocomplete from "./BaseLoadableAutocomplete";
import IssueStateLoadableAutocomplete from "../../../../_functions/pages/issue_states_and_priorities/IssueStateLoadableAutocomplete";
import IssuePriorityLoadableAutocomplete from "../../../../_functions/pages/issue_states_and_priorities/IssuePriorityLoadableAutocomplete";
import TagDefinitionLoadableAutocomplete from "../../../../_functions/pages/tag_definitions/TagDefinitionLoadableAutocomplete.vue";

export default {
  name: "BaseFilterInner",
  components: {
    TagDefinitionLoadableAutocomplete,
    IssuePriorityLoadableAutocomplete,
    IssueStateLoadableAutocomplete,
    FilterMultiSearchComponent,
    FilterTimeCondition,
    FilterSizeCondition,
    FilterTemplateCondition,
    FilterToggle,
    BaseSearcher,
    InputDatePickerDialog,
    BaseLoadableAutocomplete,
  },
  mixins: [BasicMixin],
  props: {
    parts: Object,
    model: String,
    selectedFields: Array,
    klass: Function,
    filterDrawer: Boolean,
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      multiSearchItems: [
        "name",
        "description",
        "solution",
        "id",
        "key",
        "en",
        "sk",
        "email",
        "gtin",
        "code_1",
        "code_2",
      ],
      entity_type: {
        person: "person.person",
        asset: "asset.asset",
        issue: "issue.issue",
        contract: "contract.contract",
        service: "service.service",
        rule: "rule.rule",
        0: "document.label.without_relation",
      },
      solve_status: {
        not_run: "assets_issue.label.not_run",
        success: "assets_issue.label.success",
        warning: "assets_issue.label.warning",
        fail: "assets_issue.label.fail",
      },
      status: {
        compliance: "audit.label.compliance",
        partial_compliance: "audit.label.partial_compliance",
        non_compliance: "audit.label.non_compliance",
        not_applicable: "audit.label.not_applicable",
        0: "audit.label.unrated",
      },
      toggleItems: [
        "blocked",
        "not_planned",
        "on",
        "otp_required_for_login",
        "public",
        "public_token_valid",
        "with_user",
        "persistence",
        "below_min",
        "above_opt",
      ],
      warehouseItems: [
        "warehouse_id",
        "source_warehouse_id",
        "target_warehouse_id",
      ],
      transaction_type: {
        in: "warehouse.label.transactions.in",
        out: "warehouse.label.transactions.out",
        transfer: "warehouse.label.transactions.transfer",
      },
      loadableFields: [
        "contract",
        "rule",
        "price_list",
        "price_item",
        "standard",
        "risk_level",
        "vulnerability",
        "threat",
        "control",
        "audit",
        "automated_action",
      ],
      mdiCheckAll,
    };
  },
  computed: {
    objectTypeArr() {
      return MODELS.map((item) => ({
        id: item,
        name: this.$t(`${this.toModel(item)}.${this.toModel(item)}`),
      }));
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
  methods: {
    checkField(type) {
      if (this.selectedFields) {
        return this.selectedFields.includes(type);
      }
    },
  },
};
</script>

<style scoped></style>
