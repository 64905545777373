<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-snackbar
        v-model="message.display"
        :color="message.type"
        top
        :timeout="message.timeout"
      >
        {{ message.text }}

        <template #action="{ attrs }">
          <div class="d-flex flex-column text-center">
            <base-text-button
              v-if="$route.query.token && message.type === 'error'"
              text
              color="text"
              :outlined="false"
              :button-text="$t('generic.send_reset')"
              v-bind="attrs"
              @click="goToPasswordRecovery"
            />
            <base-text-button
              text
              color="text"
              :outlined="false"
              :button-text="$t('action.close')"
              v-bind="attrs"
              @click="message.display = false"
            />
          </div>
        </template>
      </v-snackbar>

      <v-snackbar
        v-if="notice.message"
        v-model="snackbar"
        top
        class="pt-16"
        :color="notice.severity"
        vertical
        multi-line
        :timeout="-1"
      >
        <span class="white--text text-body-1">{{ notice.message }}</span>
        <template #action="{ attrs }">
          <base-text-button
            text
            color="text"
            :outlined="false"
            :button-text="$t('action.close')"
            v-bind="attrs"
            @click="snackbar = false"
          />
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import BaseTextButton from "./base/BaseTextButton";
export default {
  name: "FlashMessages",
  components: { BaseTextButton },
  data() {
    return {
      snackbar: true,
    };
  },
  computed: {
    message() {
      return this.$store.state.basic_module.message;
    },
    notice() {
      const notice = this.$store.state.basic_module.settings.notice;
      return notice || { message: "", severity: "" };
    },
  },
  methods: {
    goToPasswordRecovery() {
      this.message.display = false;
      this.$router.push("/public/auth/recovery");
    },
  },
};
</script>

<style scoped></style>
