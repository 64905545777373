<template>
  <v-menu offset-y :disabled="disabled">
    <template #activator="{ on }">
      <v-icon :class="clazz" :color="marker.color" v-on="on">
        {{ marker.icon }}
      </v-icon>
    </template>

    <v-list dense>
      <v-list-item
        v-for="(item, index) in filteredPermissionItems"
        :key="index"
        @click="changePermission(item.number)"
      >
        <v-list-item-icon class="mr-4">
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t(item.translate) }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiHelpCircle,
  mdiDiameterVariant,
} from "@mdi/js";

export default {
  name: "PermissionSetter",
  mixins: [BasicMixin],
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    permission: {
      type: Object,
      default: () => {},
    },
    setup: Object,
    permissionType: String,
    disabled: Boolean,
    application: Boolean,
    noRequest: Boolean,
    clazz: String,
  },
  data: () => ({
    permissionItems: [
      {
        icon: mdiDiameterVariant,
        color: "amber",
        number: null,
        translate: "permission.menu.unchanged",
        key: "unchanged",
      },
      {
        icon: mdiHelpCircle,
        color: "grey",
        number: 0,
        translate: "permission.menu.unset",
        key: "unset",
      },
      {
        icon: mdiCheckCircle,
        color: "blue",
        number: 1,
        translate: "permission.menu.allowObject",
        key: "allow",
      },
      {
        icon: mdiCheckCircle,
        color: "green",
        number: 2,
        translate: "permission.menu.allowObjectAndChildren",
        key: "allow_inheritance",
      },
      {
        icon: mdiCloseCircle,
        color: "red",
        number: 3,
        translate: "permission.menu.denyObject",
        key: "deny",
      },
      {
        icon: mdiCloseCircle,
        color: "red",
        number: 3,
        translate: "permission.menu.denyObjectAndChildren",
        key: "deny_inheritance",
      },
    ],
  }),
  computed: {
    filteredPermissionItems() {
      return this.permissionItems.filter((item) => {
        if (["person", "asset", "document"].includes(this.permissionType)) {
          return (
            [
              "unset",
              "allow",
              "allow_inheritance",
              "deny_inheritance",
            ].includes(item.key) ||
            (this.noRequest && item.key === "unchanged")
          );
        } else {
          return (
            ["unset", "allow", "deny"].includes(item.key) ||
            (this.noRequest && item.key === "unchanged")
          );
        }
      });
    },
    marker() {
      const result = {
        icon: mdiHelpCircle,
        color: "grey",
      };

      if (typeof this.permission === "undefined") {
        // end if undefined
        return result;
      }
      const number = (this.permission.mask >> this.header.bit) & 3;
      if (
        this.noRequest &&
        ((this.permission.apply_mask >> this.header.bit) & 3) === 0
      ) {
        result.icon = mdiDiameterVariant;
        result.color = "amber";
      } else {
        switch (number) {
          case 0: {
            result.icon = mdiHelpCircle;
            result.color = "grey";

            if (
              this.setup &&
              this.setup[this.header.type].allowed.includes(
                this.application
                  ? this.permission.entity_type
                  : this.permission.entity_id
              )
            ) {
              result.icon = mdiCheckCircle;
              result.color = "green lighten-3";
            } else if (
              this.setup &&
              this.setup[this.header.type].denied.includes(
                this.application
                  ? this.permission.entity_type
                  : this.permission.entity_id
              )
            ) {
              result.icon = mdiCloseCircle;
              result.color = "red lighten-3";
            }
            break;
          }
          case 1: {
            result.icon = mdiCheckCircle;
            result.color = "blue";
            break;
          }
          case 2: {
            result.icon = mdiCheckCircle;
            result.color = "green";
            break;
          }
          case 3: {
            result.icon = mdiCloseCircle;
            result.color = "red";
            break;
          }
          default: {
            result.icon = "";
            result.color = "";
            console.log("Error in switch " + this.name);
          }
        }
      }

      return result;
    },
  },
  methods: {
    changePermission(number) {
      if (this.disabled) {
        return;
      }

      if (this.noRequest) {
        if (number == null) {
          this.permission.apply_mask =
            this.permission.apply_mask & ~(3 << this.header.bit);
          return;
        }

        this.permission.apply_mask =
          this.permission.apply_mask | (3 << this.header.bit);
      }
      // nullify bits
      this.permission.mask = this.permission.mask & ~(3 << this.header.bit);

      // set bits
      this.permission.mask = this.permission.mask | (number << this.header.bit);

      if (this.noRequest) {
        // do no post to server
        return;
      }
      const config = {
        method: "post",
        url: this.application
          ? "permissions/setup_application"
          : "permissions/setup",
        custom_error: (response) => {
          this.$emit("reload_permissions", null);
          this.error_message("update", "permission", response);
        },
        after: () => {
          if (["asset", "person", "document"].includes(this.permissionType)) {
            this.$emit("reload_permissions", null);
          } else if (
            [
              "issue",
              "kpi",
              "contract",
              "service",
              "role",
              "warehouse",
              "price_list",
              "role",
              "application_part",
              "automated_action",
              "template_assignment",
            ].includes(this.permissionType) &&
            number === 0
          ) {
            this.$emit("reload_permissions", null);
          }
        },
      };
      this.actionsMixin_action(config, this.permission);
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  cursor: default !important;
}
</style>
