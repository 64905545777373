import _DatabaseModel, { i18n } from "./_DatabaseModel";

class Contract extends _DatabaseModel {
  static model = "contract";
  static editDialogComponent = "contracts-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    { textKey: "contract.label.effective_from", value: "effective_from" },
    { textKey: "contract.label.effective_to", value: "effective_to" },
    { textKey: "form.label.archived_at", value: "discarded_at" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "manageSubscription",
      "permittedPersons",
      "activity",
      "archive",
    ],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "effective_at",
        "customer_id",
        "service_organization_id",
        "contract_type",
        "persistence",
      ],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export", "link_to_document", "archive_multiple"];

  static contract_types = ["indefinite", "fixed_end", "fixed_prolong"];

  static cardFields(object) {
    return {
      title: object.name,
      date_from: object.effective_from,
      date_to: object.effective_to,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "contract.label.customer",
        value: object.customer,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "contract.label.service_organization",
        value: object.service_organization,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "service.services",
        value: object.services,
        type: "link_array",
        to: "services",
      },
      {
        labelKey: "contract.label.contract_type",
        value: i18n.t(`${"contract.contract_types"}.${object.contract_type}`),
        type: "plain",
      },
      {
        labelKey: "contract.label.valid_from",
        value: object.valid_from,
        type: "date",
      },
      {
        labelKey: "contract.label.effective_from",
        value: object.effective_from,
        type: "date",
      },
      {
        labelKey: "contract.label.effective_to",
        value: object.effective_to,
        type: "date",
        display_unset: true,
        condition: ["fixed_prolong", "fixed_end"].includes(
          object.contract_type
        ),
      },
      {
        labelKey: "contract.label.prolongation_interval",
        value: object.prolongation_interval,
        type: "interval",
        condition: ["fixed_prolong"].includes(object.contract_type),
      },
      {
        labelKey: "contract.label.notice_period",
        value: object.notice_period,
        type: "interval",
        condition: ["fixed_prolong", "indefinite"].includes(
          object.contract_type
        ),
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.archived_at",
        value: object.discarded_at,
        type: "date_time",
        condition: object.discarded_at,
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "document.documents",
      permission: "contracts_tab_documents",
      component: "DocumentsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default Contract;
