import { FIREBASE_KEY } from "../constants";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAV0A_ldtedGBECDFv7duTo5nQANeHU-No",
  authDomain: "ausemio-e687d.firebaseapp.com",
  databaseURL: "https://ausemio-e687d.firebaseio.com",
  projectId: "ausemio-e687d",
  storageBucket: "ausemio-e687d.appspot.com",
  messagingSenderId: "188757278740",
  appId: "1:188757278740:web:4fe1a3978267ff50c2e701",
};

let messaging = null;

export function setupFirebase() {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    // navigator.serviceWorker.getRegistration().then(function (reg) {
    //   reg.showNotification(payload.data.title, {
    //     icon: "/images/logo/symbol.png",
    //     vibrate: [300, 100, 400],
    //   });
    // });
  });
}

export function runStoreToken() {
  getToken(messaging, { vapidKey: FIREBASE_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log(currentToken);
        sendTokenToServer(currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
}

function sendTokenToServer(token) {
  if (!token) {
    return;
  }
  // sends current device token to app
  Vue.http.post("/jwt_sessions/save_token", { token: token }).then(
    () => {
      console.log("Token stored succesfully");
    },
    () => {
      console.log("Token store error");
    }
  );
}
