<template>
  <v-file-input
    v-model="innerFile"
    :label="label"
    :append-icon="mdiPaperclip"
    :hide-details="hideDetails"
    :disabled="disabled"
    prepend-icon=""
    :rules="getRule"
    validate-on-blur
    show-size
    counter
    :persistent-placeholder="!!placeholder"
    :placeholder="placeholder"
    :multiple="multiple"
    :accept="accept"
    @click:clear="$emit('input', [])"
    @change.native="setValue()"
  >
    <template v-if="multiple" #selection="{ index, text }">
      <v-chip small close @click:close="value.splice(index, 1)">
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>
</template>

<script>
import RulesMixin from "../../../mixins/RulesMixin";
import BasicMixin from "../../../mixins/BasicMixin";
import { mdiPaperclip } from "@mdi/js";

export default {
  name: "InputFile",
  mixins: [RulesMixin, BasicMixin],
  props: {
    label: String,
    hideDetails: [Boolean, String],
    disabled: Boolean,
    accept: String,
    value: {
      type: [File, Array],
    },
    placeholder: String,
    multiple: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    innerFile: [],
    mdiPaperclip,
  }),
  computed: {
    getRule() {
      if (this.placeholder && !this.value) {
        // file is on Backend saved
        return [];
      } else {
        return this.rules.concat([
          (value) =>
            !value ||
            this.validateFile(value) ||
            this.$t("form.error.big_file"),
        ]);
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.innerFile = this.value;
      },
      immediate: true,
    },
  },
  methods: {
    setValue() {
      if (this.multiple) {
        this.$emit("input", this.value.concat(this.innerFile));
      } else if (this.innerFile instanceof Array) {
        this.$emit("input", this.innerFile[0]);
      } else {
        this.$emit("input", this.innerFile);
      }
    },
    validateFile(value) {
      // validate file size
      if (value instanceof Array) {
        let valid = true;
        for (const file of value) {
          if (
            !(
              file.size / (1024 * 1024) <=
              this.basicMixin_getSafe(
                () => this.$store.state.basic_module.settings.doc_max_size,
                64
              )
            )
          ) {
            // if one of file is over limit
            valid = false;
            break;
          }
        }
        return valid;
      } else {
        return this.validateFile([value]);
      }
    },
  },
};
</script>

<style scoped></style>
