import { UNCHECKED_PARTS, LICENSED_PARTS } from "../../_config/constants";
import store from "../../_config/store/index";
import ActionsMixin from "./ActionsMixin";
import i18n from "../translations/i18n";
import {
  toModel,
  toModelUpperCase,
  modelPluralize,
  checkTranslation,
  getTranslation,
  capitalize,
  removeAccents,
} from "./HelperFunctions";

const BasicMixin = {
  mixins: [ActionsMixin],
  data() {
    return {
      content_types: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "image/webp",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "text/xml",
        "text/csv",
        "text/markdown",
        "application/xml",
        "application/json",
        "audio/mpeg",
        "audio/x-wav",
        "video/mp4",
      ],
    };
  },
  methods: {
    toModel: toModel,
    toModelUpperCase: toModelUpperCase,
    modelPluralize: modelPluralize,
    checkTranslation: checkTranslation,
    getTranslation: getTranslation,
    capitalize: capitalize,
    basicMixin_getSafe(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    canSeeAppPart(part) {
      const settings = store.state.basic_module.settings;
      if (part === "account" && !!settings.aad) return false;
      if (!part || UNCHECKED_PARTS.includes(part)) return true;

      let licensed = true;
      const license_name = LICENSED_PARTS[part];
      if (license_name && settings.license_parts) {
        // licensed part and license loaded
        licensed = settings.license_parts.includes(license_name);
      }
      // user has permission
      const permitted =
        store.state.basic_module.permissions.parts.includes(part);
      return licensed && permitted;
    },
    canSeeOneOfAppParts(parts) {
      if (!parts) return true;

      for (const item of parts) {
        if (this.canSeeAppPart(item)) return true;
      }

      return false;
    },
    basicMixin_canCreate() {
      if (
        [
          "contract",
          "service",
          "kpi",
          "warehouse",
          "price_list",
          "automated_action",
          "role",
        ].includes(this.klass.model)
      )
        return store.state.basic_module.permissions.create.includes(
          this.modelPluralize(this.klass.model)
        );

      if (this.isConfigurationModel())
        return store.state.basic_module.permissions.configuration;

      return true;
    },
    basicMixin_toSelect(inputHash, customTrans = false) {
      return Object.keys(inputHash).map((key) => {
        return {
          value: key,
          text: customTrans
            ? getTranslation(inputHash[key])
            : this.$t(inputHash[key]),
        };
      });
    },
    basicMixin_translateDefault(key, defaultString) {
      return this.$te(key) ? this.$t(key) : defaultString;
    },
    basicMixin_translateCountBackEndDate(originValue) {
      if (!originValue) {
        return;
      }
      // translates  0.Day to i18n.js TranslateCount  $tc(string, value)
      const split = originValue.toString().split(".");
      return this.$tc(split[1].toLowerCase(), parseInt(split[0]));
    },
    basicMixin_translateTrueFalse(value) {
      if (value === undefined || value === null) {
        return;
      }

      if (JSON.parse(value.toString().toLowerCase())) {
        return this.$t("generic.true");
      } else if (!JSON.parse(value.toString().toLowerCase())) {
        return this.$t("generic.false");
      } else {
        return this.$t("generic.error_value");
      }
    },
    basicMixin_loadErrorCallback(response) {
      // go back when error
      this.$router.push(
        `/${this.modelPluralize(this.model || this.klass.model)}`
      );
      // print error message
      this.error_message("load", this.model || this.klass.model, response);
    },
    error_message(action, modelSingularRepresentation, response, timeout) {
      const verbTranslation = this.$t("messages.cant");
      const actionTranslation = this.$t(`action.${action}`).toLowerCase();
      const modelTranslation = this.$t(`${modelSingularRepresentation}.acc`);
      const permission = this.basicMixin_getSafe(
        () => response.body.transaction.permission,
        ""
      )
        ? this.$t("messages.noPermissionError")
        : "";
      const validation = this.basicMixin_getSafe(
        () => response.body.transaction.message,
        ""
      );

      store.commit("basic_module/change_message", {
        text: `${verbTranslation} ${actionTranslation} ${modelTranslation}. ${permission} ${validation}`,
        type: "error",
        timeout: timeout,
      });
    },
    success_message(action, model, timeout) {
      const modelTranslation = this.$t(`${model}.${model}`);
      const verbTranslation = this.$t(`${model}.alert.verb`);
      const actionTranslation = this.$t(`${model}.alert.${action}`);

      store.commit("basic_module/change_message", {
        text: `${modelTranslation} ${verbTranslation} ${actionTranslation}.`,
        type: "success",
        timeout: timeout,
      });
    },
    messageCustom(key, type, params, timeout) {
      store.commit("basic_module/change_message", {
        text: i18n.t(key, params),
        type: type,
        timeout: timeout,
      });
    },
    convertFileSize(size) {
      if (!size) return "";

      if (size >= 1048576) {
        return `${Math.round(size / 1048576)} MB`;
      } else {
        return `${Math.ceil(size / 1024)} kB`;
      }
    },
    isModel(...models) {
      return models.includes(this.klass.model);
    },
    isConfigurationModel() {
      return [
        "asset_category",
        "person_category",
        "rule",
        "price_item",
        "connector",
        "mapper",
        "external_email",
        "issue_state",
        "issue_priority",
        "tag_definition",
        "person_relation",
        "field",
        "report",
        "translation",
        "holiday",
      ].includes(this.klass.model);
    },
    positiveStep(form) {
      if (form && !form.validate()) {
        this.messageCustom("messages.check", "error");
        return;
      }

      if (this.step === this.getStepper.length - 1) {
        this.save();
      } else {
        this.step += 1;
        this.stepper = this.getStepper[this.step];
      }
    },
    negativeStep() {
      if (this.step > 1) {
        this.step -= 1;
        this.stepper = this.getStepper[this.step];
      } else {
        this.dialog = false;
      }
    },
    getLicenseData() {
      this.$http.get("implementation/used_license", {}).then(
        (response) => {
          this.license_data = response.body;
        },
        (response) => {
          this.error_message("load", "about", response);
        }
      );
    },
    customFilter(_item, queryText, itemText) {
      return removeAccents(itemText.toLowerCase()).includes(
        removeAccents(queryText.toLowerCase())
      );
    },
  },
};

export default BasicMixin;
