<template>
  <v-tooltip
    open-delay="500"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :disabled="
      !$vuetify.breakpoint.smAndUp ||
      (!tooltipTranslation && !tooltipDisabledTranslation)
    "
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          v-shortkey="shortKey"
          :disabled="disabled"
          class="ml-1"
          :color="colorComputed"
          :outlined="outlined"
          :loading="loading"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <v-icon v-if="!!icon" left>{{ icon }}</v-icon>
          <span>{{ buttonText }}</span>
        </v-btn>
      </span>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import BaseButtonsMixin from "../../../mixins/BaseButtonsMixin";

export default {
  name: "BaseTextButton",
  mixins: [BaseButtonsMixin],
  props: {
    buttonText: String,
    outlined: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
