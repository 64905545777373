import _DatabaseModel from "./_DatabaseModel";

class Vulnerability extends _DatabaseModel {
  static model = "vulnerability";
  static showSize = 8;
  static editDialogComponent = "vulnerabilities-edit-dialog";
  static tableHeaders = [{ textKey: "form.label.name", value: "name" }];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "activity", "destroy"],
    reloadingModels: ["vulnerability_control", "control"],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];
  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "control.controls",
      permission: "vulnerability_tab_controls",
      component: "ControlsTab",
      input_data: (context) => context.object.vulnerability_controls,
      disabled: () => false,
    },
  ];
}

export default Vulnerability;
