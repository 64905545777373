<template>
  <v-card-actions
    :class="{
      'd-flex justify-center': $vuetify.breakpoint.xsOnly,
      'mobile-top-tab-height': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
    <base-text-button
      v-if="negativeTranslation"
      text
      :outlined="false"
      :button-text="$t(negativeTranslation)"
      @click="$emit('negative')"
    />

    <base-text-button
      v-if="positiveTranslation"
      :disabled="disabled"
      :color="positiveColor"
      :outlined="false"
      :loading="loading"
      :button-text="$t(positiveTranslation)"
      @click="$emit('positive')"
    />
  </v-card-actions>
</template>

<script>
import BaseTextButton from "../BaseTextButton";
export default {
  name: "BaseVCardActionsButtonsDialog",
  components: { BaseTextButton },
  inheritAttrs: true,
  props: {
    positiveTranslation: String,
    positiveColor: {
      type: String,
      default: "primary",
    },
    negativeTranslation: String,
    disabled: Boolean,
    loading: Boolean,
  },
};
</script>

<style scoped lang="scss">
.mobile-top-tab-height {
  height: 72px;
}
</style>
