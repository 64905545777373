import { render, staticRenderFns } from "./BaseVCardTitleDialogIntern.vue?vue&type=template&id=5ae86694&scoped=true"
import script from "./BaseVCardTitleDialogIntern.vue?vue&type=script&lang=js"
export * from "./BaseVCardTitleDialogIntern.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae86694",
  null
  
)

export default component.exports