const ScannerMixin = {
  methods: {
    async checkCameraPermission() {
      try {
        // Check if protocol is https
        if (!window.isSecureContext) {
          return this.$t("messages.publicIssue.httpsNeeded");
        }
        // Check if the user has a camera on their device
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );

        if (!hasCamera) {
          return this.$t("messages.publicIssue.noCamera");
        }

        // Check if camera permission is granted
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());

        return null;
      } catch (error) {
        if (
          error.name === "NotAllowedError" ||
          error.name === "NotFoundError"
        ) {
          return this.$t("messages.publicIssue.cameraAllow");
        } else {
          return (
            "An error occurred while checking camera permission: " +
            error.message
          );
        }
      }
    },
    resetScanner() {
      if (this.$refs.reader) this.$refs.reader.codeReader.reset();
    },
    startDecode() {
      this.$refs.reader.start();
    },
    stopDecode() {
      this.$refs.reader.codeReader.stopContinuousDecode();
    },
  },
};
export default ScannerMixin;
