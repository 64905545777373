const state = {
  reloading_model: "",
  timestamp: 0,
  data: {},
};

const mutations = {
  reload(state, reload) {
    state.reloading_model = reload.reload;
    state.data = reload.data;
    state.timestamp = new Date().getTime();
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
