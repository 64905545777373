import BaseVCardActionsButtonsDialog from "./card/BaseVCardActionsButtonsDialog";
import BaseVCardTitleDialogIntern from "./card/BaseVCardTitleDialogIntern";
import BaseVCardTitleShow from "./card/BaseVCardTitleShow";

export default {
  components: {
    "base-v-card-actions-buttons-dialog": BaseVCardActionsButtonsDialog,
    "base-v-card-title-dialog-intern": BaseVCardTitleDialogIntern,
    "base-v-card-title-show": BaseVCardTitleShow,
  },
};
