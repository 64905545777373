const PrintingDate = {
  methods: {
    date_time_format() {
      return `${this.date_format()} ${this.time_format()}`;
    },
    time_format(
      number = this.$store.state.basic_module.personalization.time_format
    ) {
      switch (number) {
        case 0:
          return "HH:mm:ss";
        case 1:
          return "hh:mm:ss A";
        default:
          return "HH:mm:ss";
      }
    },
    date_format(
      number = this.$store.state.basic_module.personalization.date_format
    ) {
      switch (number) {
        case 0:
          return "DD.MM.YYYY";
        case 1:
          return "DD. MMMM YYYY";
        case 2:
          return "YYYY-MM-DD";
        default:
          return "DD.MM.YYYY";
      }
    },
    timeFilterToString(def) {
      if (def.type === "fixed") {
        const startStr = def.from
          ? this.$moment(def.from).format(this.date_time_format())
          : this.$t("generic.unset");
        const endStr = def.to
          ? this.$moment(def.to).format(this.date_time_format())
          : this.$t("generic.unset");
        return `${startStr} - ${endStr}`;
      }

      if (def.type === "last") {
        const pastStr = this.$moment().subtract(def.number, def.unit).fromNow();
        return `${pastStr} ${this.$t("form.label.till_now")}`;
      }

      if (def.type === "whole") {
        const count = this.$tc(def.unit, parseInt(def.number));
        const endTime = this.$moment().add(def.offset, def.unit);
        const startTime = endTime.clone().add(-def.number + 1, def.unit);
        const startStr = startTime.startOf(def.unit).format(this.date_format());
        const endStr = endTime.endOf(def.unit).format(this.date_format());
        return `${count} (${startStr} - ${endStr})`;
      }
    },
  },
};

export default PrintingDate;
