import _DatabaseModel from "./_DatabaseModel";
import { checkTranslation, getTranslation } from "../mixins/HelperFunctions";
import { mdiExclamationThick } from "@mdi/js";

class IssuePriority extends _DatabaseModel {
  static model = "issue_priority";
  static editDialogComponent = "issue-state-and-priorities-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.id", value: "id", sortable: false },
    {
      textKey: "form.label.name",
      value: "name",
      sortable: false,
      priorityTag: true,
    },
    {
      textKey: "form.label.description",
      value: "description",
      sortable: false,
      translated: true,
    },
    {
      textKey: "other.actions",
      value: "actions",
      align: "right",
      cellClass: "text-no-wrap",
      sortable: false,
    },
  ];

  static operations = ["export"];

  static customSort = false;

  static cardFields(object) {
    return {
      title: getTranslation(object.name),
      subtitle: checkTranslation(object.description)
        ? getTranslation(object.description)
        : undefined,
      actions: ["up", "down", "edit", "delete"],
      src: {
        cardIcon: mdiExclamationThick,
        color: object.color,
      },
    };
  }
}

export default IssuePriority;
