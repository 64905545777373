<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row dense align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-img
              contain
              class="loading-img"
              aspect-ratio="1"
              :src="brandingMixin_loadingImg"
            ></v-img>
            <v-progress-linear
              indeterminate
              class="mt-2"
              height="8"
              color="primary"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BasicMixin from "../mixins/BasicMixin";
import LogInMixin from "../mixins/LogInMixin";
import BrandingMixin from "../mixins/BrandingMixin";

export default {
  name: "LoadingScreen",
  components: {},
  mixins: [BasicMixin, LogInMixin, BrandingMixin],
  mounted() {
    this.load_data();
  },
  methods: {
    async load_data() {
      if (this.$store.state.app_state.auth) {
        this.$http.post("login").then((response) => this.after(response));
      } else {
        this.$router.push("/public/auth/login");
      }
    },
    after(response) {
      this.logInMixin_setStore(response.body);
      this.$router.push({
        path: this.$store.state.basic_module.loaded_next,
        query: this.$store.state.basic_module.loaded_next_query,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.loading-img {
  padding: 0 25% 0 25%;
}
</style>
