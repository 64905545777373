<template>
  <v-row dense>
    <v-col cols="12" class="py-1">
      <v-autocomplete
        v-model="selectedField"
        item-text="name"
        item-value="id"
        :items="availableFields"
        :label="$t(label)"
        :filter="customFilter"
        return-object
        hide-details
        @change="fieldChanged"
      >
      </v-autocomplete>
    </v-col>

    <v-col v-if="newCondition.id" cols="12" class="py-1 pr-1">
      <v-switch
        v-if="['Integer', 'Time'].includes(newCondition.data_type)"
        :label="$t('form.label.range')"
        hide-details
        @change="rangeChanged"
      />

      <v-row v-if="newCondition.range" dense>
        <v-col cols="5">
          <v-text-field
            v-if="newCondition.data_type === 'Integer'"
            v-model="newCondition.range.from"
            :label="$t('form.label.from')"
            type="number"
          />
          <input-time-picker-dialog
            v-else
            v-model="newCondition.range.from"
            :label="$t('form.label.time_from')"
          />
        </v-col>

        <v-col cols="5">
          <v-text-field
            v-if="newCondition.data_type === 'Integer'"
            v-model="newCondition.range.to"
            :label="$t('form.label.to')"
            type="number"
          />
          <input-time-picker-dialog
            v-else
            v-model="newCondition.range.to"
            :label="$t('form.label.time_to')"
          />
        </v-col>

        <v-col cols="auto" class="pt-6 ml-auto">
          <v-icon @click="addToInner()"> {{ mdiPlus }} </v-icon>
        </v-col>
      </v-row>

      <v-row v-else dense>
        <v-col
          v-if="
            ['String', 'Text', 'Integer', 'Time'].includes(
              newCondition.data_type
            )
          "
          cols="12"
        >
          <filter-multi-search-component
            ref="multi"
            v-model="newCondition.value"
            :label="$t('form.label.value_in_detail')"
            :append-outer-icon="mdiPlus"
            @addToInner="addToInner(true)"
          />
        </v-col>

        <template
          v-else-if="
            newCondition.data_type === 'Date' ||
            newCondition.data_type === 'DateTime'
          "
        >
          <time-filter-form ref="form" v-model="newCondition.time_filter">
            <v-col cols="auto" class="pt-6 ml-auto">
              <v-icon @click="addToInner()">{{ mdiPlus }}</v-icon>
            </v-col>
          </time-filter-form>
        </template>

        <v-col
          v-else-if="['Select', 'Radio'].includes(newCondition.data_type)"
          cols="12"
        >
          <v-autocomplete
            v-model="newCondition.value"
            :label="$t('form.label.value_in_detail')"
            :filter="customFilter"
            :items="basicMixin_toSelect(selectedField.options, true)"
            multiple
            clearable
            :append-outer-icon="mdiPlus"
            @click:append-outer="addToInner()"
          />
        </v-col>

        <v-col
          v-else-if="['Person', 'Asset'].includes(newCondition.data_type)"
          cols="12"
        >
          <base-searcher
            v-model="newCondition.value"
            :type="newCondition.data_type.toLowerCase()"
            :label="
              newCondition.data_type === 'Asset'
                ? $t('asset.asset')
                : $t('person.person')
            "
            multiple
            load-names
            :append-icon="mdiPlus"
            @append-click-method="addToInner()"
          ></base-searcher>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      v-for="(item, index) of innerCondition"
      :key="index"
      cols="12"
      class="py-1"
    >
      <strong>{{ getField(item.id).name }}</strong>
      <v-icon class="float-right" @click="removeFromInner(index)">
        {{ mdiClose }}
      </v-icon>
      <div
        v-if="
          (item.data_type === 'Asset' && assetNames.length > 0) ||
          (item.data_type === 'Person' && personNames.length > 0)
        "
      >
        {{ item.value.map((i) => getName(i, item.data_type)).join(", ") }}
      </div>
      <div
        v-else-if="['Boolean', 'Switch', 'Password'].includes(item.data_type)"
      >
        {{ basicMixin_translateTrueFalse(item.value === "1") }}
      </div>
      <div v-else-if="typeof item.value === 'string'">
        {{ item.value }}
      </div>
      <div
        v-else-if="
          item.value &&
          typeof item.value === 'object' &&
          item.data_type !== 'Asset' &&
          item.data_type !== 'Person'
        "
      >
        {{ item.value.join(", ") }}
      </div>
      <div v-else-if="item.range">
        {{ item.range.from }} - {{ item.range.to }}
      </div>
      <div v-else-if="item.time_filter">
        {{ timeFilterToString(item.time_filter) }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import PrintingDate from "../../../../_generic/mixins/PrintingDate";
import FilterMultiSearchComponent from "./FilterMultiSearchComponent";
import InputTimePickerDialog from "../../../../_generic/pages/components/inputs/InputTimePickerDialog";
import BaseSearcher from "../../components/searcher/BaseSearcher";
import TimeFilterForm from "./TimeFilterForm.vue";
import { mdiClose, mdiPlus } from "@mdi/js";

export default {
  name: "FilterTemplateCondition",
  components: {
    InputTimePickerDialog,
    FilterMultiSearchComponent,
    BaseSearcher,
    TimeFilterForm,
  },
  mixins: [BasicMixin, RulesMixin, PrintingDate],
  props: {
    value: Array,
    type: String,
    label: {
      type: String,
      default: "form.label.detail",
    },
  },
  data: () => ({
    innerCondition: [],
    selectedField: null,
    newCondition: {},
    timeFilter: {},
    personNames: [],
    assetNames: [],
    mdiClose,
    mdiPlus,
  }),
  computed: {
    allFields() {
      return this.$store.getters["loadable/get_list"]("field").filter(
        (f) => f.usage[this.type]
      );
    },
    availableFields() {
      let already = [];
      if (this.value && this.value.length > 0) {
        already = this.value.map((f) => f.id);
      }

      return this.allFields.filter((f) => !already.includes(f.key));
    },
  },
  watch: {
    innerCondition() {
      this.$emit("input", this.innerCondition);
    },
    value: {
      handler() {
        this.innerCondition = this.value || [];
        if (this.value && this.value.length > 0) {
          this.fetchNames();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("loadable/fetchItems", "field");
  },
  methods: {
    addToInner(combobox) {
      if (combobox) this.$refs.multi.$refs.combobox.blur();

      this.$nextTick(() => {
        if (!this.newCondition.id) return;
        if (this.$refs.form && !this.$refs.form.validate()) return;

        this.innerCondition.push({ ...this.newCondition });
        this.newCondition = {};
        this.selectedField = null;
      });
    },
    fieldChanged(item) {
      this.newCondition = { id: item.key, data_type: item.data_type };

      if (["Date", "DateTime"].includes(item.data_type))
        this.$set(this.newCondition, "time_filter", { type: "fixed" });
      else if (["Boolean", "Switch", "Password"].includes(item.data_type)) {
        this.newCondition.value = "1";
        this.addToInner();
      }
    },
    rangeChanged(value) {
      if (value) this.$set(this.newCondition, "range", {});
      else this.$delete(this.newCondition, "range");
    },
    removeFromInner(index) {
      this.innerCondition.splice(index, 1);
    },
    getName(item, type) {
      const findItem = this[`${this.toModel(type)}Names`].find(
        (i) => i.id === Number(item)
      );
      if (findItem) {
        return findItem.name;
      }
    },
    fetchNames() {
      for (const model of ["asset", "person"]) {
        let ids = [];
        const fields = this.value.filter(
          (i) => i.data_type === this.toModelUpperCase(model)
        );

        for (const field of fields) {
          const toNum = field.value
            .map((i) => Number(i))
            .filter((id) => !this[`${model}Names`].some((n) => n.id === id));

          ids = ids.concat(toNum);
        }

        if (ids.length > 0) {
          this.$http
            .get(`${this.modelPluralize(model)}/load_names`, {
              params: {
                ids: ids,
              },
            })
            .then(
              (response) => {
                this[`${model}Names`] = this[`${model}Names`].concat(
                  response.body.object
                );
              },
              (response) => {
                this.error_message("load", model, response);
              }
            );
        }
      }
    },
    getField(key) {
      return this.allFields.find((f) => f.key === key) || {};
    },
  },
};
</script>

<style scoped></style>
