import _DatabaseModel from "./_DatabaseModel";

class BackgroundJob extends _DatabaseModel {
  static model = "background_job";
  static bottomNav = { mobile: ["list"], desktop: ["list"] };
  static showSize = 0;
  static createNewObject = false;

  static tableHeaders = [
    {
      textKey: "form.label.id",
      value: "id",
      class: "text-no-wrap",
      sortable: false,
    },
    { textKey: "form.label.created_at", value: "created_at", sortable: false },
    { textKey: "form.label.run_at", value: "run_at", sortable: false },
    { text: "Cron", value: "cron", sortable: false },
    { textKey: "form.label.attempts", value: "attempts", sortable: false },
    { textKey: "form.label.failed_at", value: "failed_at", sortable: false },
    { textKey: "", value: "data-table-expand", sortable: false },
  ];

  static isExpandable() {
    return true;
  }

  static customSort = false;
}

export default BackgroundJob;
