import _DatabaseModel from "./_DatabaseModel";

class Consequence extends _DatabaseModel {
  static model = "consequence";
  static editDialogComponent = "consequences-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name", sortable: false },
    {
      textKey: "form.label.description",
      value: "description",
      sortable: false,
    },
    { textKey: "form.label.value", value: "value", sortable: false },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static cardFields(object) {
    return {
      title: object.name,
      actions: ["edit", "delete"],
    };
  }
}

export default Consequence;
