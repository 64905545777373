<template>
  <div class="progress">
    <v-progress-circular
      :size="90"
      :width="6"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "BaseLoadingProgress",
};
</script>

<style scoped lang="scss">
.progress {
  min-height: 400px;
  padding-top: 200px;
  text-align: center;
}
</style>
