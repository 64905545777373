<template>
  <v-menu
    v-model="menu_model"
    offset-y
    :close-on-content-click="false"
    left
    max-height="50vh"
    :min-width="$vuetify.breakpoint.mdAndUp ? '600px' : '70vw'"
    :max-width="$vuetify.breakpoint.mdAndUp ? '600px' : '70vw'"
  >
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon class="ml-1" v-on="{ ...tooltip, ...menu }">
            <v-badge
              :offset-x="10"
              :offset-y="10"
              overlap
              color="red"
              :value="$store.state.notifications.total_unread > 0"
            >
              <template #badge>
                <span>{{ $store.state.notifications.total_unread }}</span>
              </template>
              <v-icon :color="$vuetify.theme.dark ? 'text' : 'primary'">{{
                mdiBell
              }}</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t("tooltips.navbar_icons.notifications") }}</span>
      </v-tooltip>
    </template>

    <v-card v-if="menu_model">
      <v-card-text>
        <div class="text-h5 text-center">
          {{ $t("notification.label.notifications_list") }}
        </div>

        <notification-card
          v-for="n in $store.state.notifications.list"
          :key="n.id"
          :item="n"
          @update-item="updateItem(n, $event)"
        ></notification-card>
        <div
          v-if="$store.state.notifications.list.length === 0"
          class="text-center py-6"
        >
          {{ $t("notification.label.empty") }}
        </div>
        <div class="mt-2 text-center">
          <span
            v-if="$store.state.notifications.list.length !== 0"
            class="link primary--text"
            @click="readAll"
            >{{ $t("notification.label.read_all") }}</span
          ><br />

          <router-link to="/notifications">{{
            $t("notification.label.view_all")
          }}</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import NotificationCard from "./NotificationCard";
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import { mdiBell } from "@mdi/js";

export default {
  name: "NotificationBell",
  components: { NotificationCard },
  mixins: [BasicMixin],
  data: () => ({
    model: "notification",
    menu_model: null,
    mdiBell,
    timer: null,
  }),
  watch: {
    "$route.fullPath"() {
      this.menu_model = false;
    },
  },
  created() {
    this.fetchLatestIfVisible();
    document.addEventListener("visibilitychange", () => {
      this.fetchLatestIfVisible();
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    readAll() {
      const config = {
        method: "post",
        message: { action: "load", model: this.model },
        url: `${this.modelPluralize(this.model)}/read_all`,
        after: this.after,
      };
      this.actionsMixin_action(config, {});
    },
    updateItem(item, data) {
      const matchItem = this.$store.state.notifications.list.find(
        (i) => i.id === item.id
      );
      matchItem.read_at = data.read_at;
    },
    after() {
      this.$store.dispatch("notifications/fetchItems");
    },
    fetchLatestIfVisible() {
      if (document.visibilityState === "visible") {
        this.$store.dispatch("notifications/fetchItems");
        this.timer = setTimeout(() => this.fetchLatestIfVisible(), 60000);
      } else clearTimeout(this.timer);
    },
  },
};
</script>

<style scoped></style>
