const PermissionsResetMixin = {
  methods: {
    reset_permission(id) {
      this.permissions_h.permissions[id].mask = 0;

      const config = {
        method: "post",
        message: { action: "update", model: "permission" },
        url: "permissions/setup",
        after: () => {
          this.reload_permissions();
        },
        custom_error: () => {
          this.reload_permissions();
        },
      };

      this.actionsMixin_action(config, this.permissions_h.permissions[id]);
    },
  },
};
export default PermissionsResetMixin;
