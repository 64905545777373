import _DatabaseModel from "./_DatabaseModel";

class Requirement extends _DatabaseModel {
  static model = "requirement";

  static tableHeaders = [
    {
      textKey: "audit.audit",
      value: "audit.name",
      class: "text-no-wrap",
      sortable: false,
    },
    {
      textKey: "standard.label.label",
      value: "provision.label",
      class: "text-no-wrap",
      sortable: false,
    },
    {
      textKey: "requirement.label.status",
      translationPrefix: "audit.label",
      value: "status",
    },
  ];

  static customSort = false;

  static filter = {
    parts: {
      fields: ["audit_id", "status"],
      extended_fields: [],
    },
  };
}

export default Requirement;
