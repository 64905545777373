import { runStoreToken } from "../../_config/packs/setup_firebase_integration";

const LogInMixin = {
  methods: {
    logInMixin_setStore(body) {
      this.$store.commit("app_state/set_auth", true);
      this.$store.state.basic_module.loaded = true;
      this.$store.state.basic_module.permissions = body.permissions;
      this.$store.state.basic_module.translations = body.translations;
      this.$store.state.basic_module.personalization = body.personalization;
      this.$store.state.basic_module.current_user = body.current_user;
      this.$store.state.basic_module.code_version = body.code_version;
      this.$store.state.basic_module.impersonator = body.impersonator;

      this.$vuetify.theme.dark = body.personalization.dark_mode;

      this.$moment.tz.setDefault(
        body.personalization.time_zone.replace(" ", "_")
      );
      this.$moment.updateLocale(body.personalization.locale, {
        week: {
          dow: body.personalization.week_first_day === "monday" ? 1 : 0,
        },
      });

      // set locale
      this.$i18n.locale = body.personalization.locale;
      this.$vuetify.lang.current = this.$i18n.locale;

      if (!body.impersonator) runStoreToken();
    },
  },
};

export default LogInMixin;
