<template>
  <div class="text-center">
    <v-icon v-if="value" color="success">
      {{ mdiCheck }}
    </v-icon>
    <v-icon v-else color="error">
      {{ mdiClose }}
    </v-icon>
  </div>
</template>

<script>
import { mdiClose, mdiCheck } from "@mdi/js";

export default {
  name: "BaseTableBoolean",
  props: {
    value: Boolean,
  },
  data: () => ({
    mdiClose,
    mdiCheck,
  }),
};
</script>

<style scoped></style>
