import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { mdiPlayCircleOutline, mdiStopCircleOutline } from "@mdi/js";

class AutomatedAction extends _DatabaseModel {
  static model = "automated_action";
  static editDialogComponent = "automated-actions-edit-dialog";

  static createNewObject = {
    object: {
      automated_action_type: "",
      name: "",
      description: "",
      on: false,
      run_type: "now",
      definition: { delay: {}, action: {}, trigger: [{}] },
    },
  };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    {
      textKey: "automated_action.label.automated_action_type",
      value: "automated_action_type",
      translationPrefix: "automated_action.automated_action_types",
    },
    {
      textKey: "automated_action.label.run_type",
      value: "run_type",
      translationPrefix: "automated_action.run_types",
    },
    { textKey: "automated_action.label.on", value: "on" },
  ];

  static on_options = {
    true: "filter.on_levels.on",
    false: "filter.on_levels.off",
  };

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "permittedPersons", "activity", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "automated_action_type", "run_type", "on"],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static automated_action_types = [
    "notify",
    "report",
    "create",
    "update",
    "archive",
    "destroy",
  ];

  static run_types = ["now", "delayed"];

  static cardFields(object) {
    return {
      src: {
        cardIcon: object.on ? mdiPlayCircleOutline : mdiStopCircleOutline,
        color: object.on ? "success" : "error",
      },
      title: object.name,
      subtitle: i18n.t(
        `automated_action.automated_action_types.${object.automated_action_type}`
      ),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "automated_action.label.automated_action_type",
        value: i18n.t(
          `automated_action.automated_action_types.${object.automated_action_type}`
        ),
        type: "plain",
      },
      {
        labelKey: "automated_action.label.run_type",
        value: i18n.t(`automated_action.run_types.${object.run_type}`),
        type: "plain",
      },
      {
        labelKey: "automated_action.label.on",
        value: object.on,
        type: "boolean",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "automated_action.label.definition",
      permission: "automated_actions_tab_definition",
      component: "JsonDefinitionTab",
      input_data: (context) => context.object,
      disabled: () => false,
    },
    {
      titleKey: "connector_run.connector_runs",
      permission: "automated_actions_tab_run",
      component: "ConnectorRunsTab",
      input_data: (context) => context.object.connector_runs,
      disabled: () => false,
    },
  ];
}

export default AutomatedAction;
