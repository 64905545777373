<template>
  <v-menu
    :min-width="$vuetify.breakpoint.xsOnly ? '100%' : 550"
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <span>
        <base-icon-button
          :tooltip-translation="
            chipText(options.sortBy[0], options.sortDesc[0])
          "
          :icon="mdiSort"
          v-on="on"
        />
      </span>
    </template>

    <v-sheet rounded class="pa-4">
      <div class="text-h6 pb-4">{{ $t("form.label.sort") }}</div>
      <template v-for="(sort, i) in sorts">
        <v-row :key="sort.value" align="center">
          <v-col cols="6" sm="5">{{ $t(sort.textKey) }}</v-col>
          <v-col cols="6" sm="7">
            <v-row no-gutters>
              <v-col
                v-for="(desc, ind) in [false, true]"
                :key="ind"
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <v-btn
                  :class="{
                    primary:
                      options.sortBy[0] === sort.value &&
                      options.sortDesc[0].toString() === desc.toString(),
                  }"
                  block
                  small
                  text
                  @click="changeSort(sort.value, desc)"
                >
                  {{ buttonText(sort.value, desc) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider
          v-if="i < sorts.length - 1"
          :key="i"
          class="my-2"
        ></v-divider>
      </template>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mdiSort } from "@mdi/js";
import BaseIconButton from "../../../_generic/pages/components/base/BaseIconButton";

export default {
  name: "BaseListSort",
  components: { BaseIconButton },
  props: {
    options: Object,
    sorts: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    mdiSort,
  }),
  methods: {
    buttonText(by, desc) {
      const suffix = desc ? "desc" : "asc";
      const translationKey = `sorts.${by}.${suffix}`;
      if (this.$te(translationKey)) return this.$t(translationKey);

      return this.$t(`sorts.${suffix}`);
    },
    chipText(by, desc) {
      const column = this.sorts.find((c) => c.value === by);
      if (column)
        return this.$t(column.textKey) + ": " + this.buttonText(by, desc);
    },
    changeSort(by, desc) {
      const newOptions = {
        ...this.options,
        sortBy: [by],
        sortDesc: [desc],
      };
      this.$emit("update:options", newOptions);
    },
  },
};
</script>

<style scoped></style>
