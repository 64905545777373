const state = {
  auth: false,
  selected_objects: [],
  current_object: null,
  mode: {
    person: "tree",
    asset: "tree",
    document: "tree",
    person_category: "tree",
    asset_category: "tree",
    issue: "card",
    person_calendar: "card",
  },
  person_calendar: {
    type: "month",
    start: new Date().toISOString().slice(0, 10),
    coloring: "default",
    person_ids: [],
    persons: [],
    asset_ids: [],
    assets: [],
  },
  dashboard: null,
  options: {
    sortBy: {
      activity: ["created_at"],
      issue: ["created_at"],
      person_calendar: ["created_at"],
      jwt_session: ["updated_at"],
    },
    sortDesc: {
      activity: ["true"],
      issue: ["true"],
      person_calendar: ["true"],
      jwt_session: ["true"],
    },
  },
};

const mutations = {
  set_tree_mode(state, input) {
    state.mode[input.model] = input.mode;
  },
  set_person_calendar(state, data) {
    state.person_calendar = { ...state.person_calendar, ...data };
  },
  set_dashboard(state, data) {
    state.dashboard = data;
  },
  set_sortBy(state, input) {
    state.options.sortBy[input.model] = input.data;
  },
  set_sortDesc(state, input) {
    state.options.sortDesc[input.model] = input.data;
  },
  set_auth(state, input) {
    state.auth = input;
  },
  set_current_object(state, object) {
    state.current_object = object;
  },
  reset(state) {
    state.auth = false;
    state.selected_objects = [];
    state.current_object = null;

    state.mode = {
      person: "tree",
      asset: "tree",
      document: "tree",
      person_category: "tree",
      asset_category: "tree",
      issue: "card",
      person_calendar: "card",
    };

    state.person_calendar = {
      type: "month",
      start: new Date().toISOString().slice(0, 10),
      coloring: "default",
      person_ids: [],
      persons: [],
      asset_ids: [],
      assets: [],
    };

    state.dashboard = null;

    state.options = {
      sortBy: {
        activity: ["created_at"],
        issue: ["created_at"],
        person_calendar: ["created_at"],
        jwt_session: ["updated_at"],
      },
      sortDesc: {
        activity: ["true"],
        issue: ["true"],
        person_calendar: ["true"],
        jwt_session: ["true"],
      },
    };
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
