export default {
  methods: {
    getGeoLocation(resolveFunction) {
      if (navigator.geolocation) {
        this.loading = true;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.accessToGeoLocation = true;
            resolveFunction({
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            });
            this.loading = false;
          },
          (error) => {
            this.accessToGeoLocation = false;
            this.loading = false;
            console.log(error.message);
          },
          { enableHighAccuracy: true }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        this.loading = false;
      }
    },
  },
};
