import BasicMixin from "../../mixins/BasicMixin";
import { modelPluralize } from "../../mixins/HelperFunctions";
import Vue from "vue";

const state = {};

const mutations = {
  FETCH_MODEL(state, data) {
    if (!state[data.model]) {
      Vue.set(state, data.model, {
        list: data.objects,
        valid: true,
      });
    } else {
      state[data.model].list = data.objects;
    }
  },
};

const getters = {
  get_list: (state) => (model) => {
    if (!state[model]) {
      Vue.set(state, model, {
        list: [],
        valid: false,
      });
    }
    return state[model].list;
  },
};

const actions = {
  fetchItems(context, model) {
    if (!state[model]) {
      Vue.set(state, model, {
        list: [],
        valid: false,
      });
    }

    if (!state[model].valid) {
      // make valid until reject response
      state[model].valid = true;

      Vue.http.get(`${modelPluralize(model)}/load`).then(
        (response) => {
          context.commit("FETCH_MODEL", {
            model: model,
            objects: response.body.objects,
          });
        },
        (response) => {
          state[model].valid = false;
          BasicMixin.methods.error_message("load", model, response);
        }
      );
    }
  },
  resetItems(context, model) {
    if (model in state) {
      state[model].valid = false;
      context.dispatch("fetchItems", model);
    }
  },
  resetAllItems() {
    for (const key in state) {
      state[key].valid = false;
      state[key].list = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
