<template>
  <v-text-field
    v-model="inner"
    type="text"
    :class="{ 'no-border': readonly, hide: !show }"
    class="my-0"
    :rules="rules"
    :label="label"
    validate-on-blur
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="readonly"
    :dense="readonly"
    @input="setShow($event)"
  >
    <template #append>
      <v-icon
        v-if="readonly"
        class="pl-1"
        @click.stop="copyPasswordToClipboard"
        >{{ mdiContentCopy }}</v-icon
      >
      <v-icon v-show="inner?.length > 0" class="pl-1" @click.stop="show = !show"
        >{{ show ? mdiEye : mdiEyeOff }}
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import { mdiEye, mdiEyeOff, mdiContentCopy } from "@mdi/js";
export default {
  name: "InputPassword",
  props: {
    value: String,
    label: String,
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      mdiContentCopy,
      mdiEye,
      mdiEyeOff,
      show: false,
    };
  },
  computed: {
    inner: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setShow(event) {
      if (event.length === 0) {
        this.show = false;
      }
    },
    copyPasswordToClipboard() {
      navigator.clipboard.writeText(this.value);
    },
  },
};
</script>

<style lang="scss">
.no-border > .v-input__control > .v-input__slot:before,
.no-border > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.no-border input {
  padding-top: 0 !important;
}
.hide > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  -webkit-text-security: disc;
}
</style>
