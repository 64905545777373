import _DatabaseModel, { i18n } from "./_DatabaseModel";
import {
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiStopCircleOutline,
} from "@mdi/js";

class Connector extends _DatabaseModel {
  static model = "connector";
  static editDialogComponent = "connectors-edit-dialog";
  static createNewObject = { object: { on: true } };

  static tableHeaders = [
    {
      textKey: "connector.label.status",
      value: "connector_status",
      sortable: false,
    },
    { textKey: "form.label.name", value: "name" },
    {
      textKey: "connector.label.type",
      value: "connector_type",
      translationPrefix: "connector.type",
    },
  ];

  static show = {
    buttons: ["edit", "key"],
    submenuActions: ["activity", "destroy"],
    reloadingModels: [],
  };

  static cardFields(object) {
    return {
      src: {
        cardIcon:
          object.on === false
            ? mdiStopCircleOutline
            : object.connector_status === "ok"
            ? mdiCheckCircleOutline
            : mdiCloseCircleOutline,
        color:
          object.connector_status === "error" || object.on === false
            ? "error"
            : "success",
      },
      title: object.name,
      subtitle: i18n.t(`connector.type.${object.connector_type}`),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "connector.label.type",
        value: i18n.t(`${"connector.type"}.${object.connector_type}`),
        type: "plain",
      },
      {
        labelKey: "mapper.mapper",
        value: object.mapper,
        type: "link",
        to: "mappers",
      },
      {
        labelKey: "connector.label.on",
        value: object.on,
        type: "boolean",
      },
      {
        labelKey: "connector.label.mail_ms_graph.tenant_id",
        value: object.setup.tenant_id,
        type: "plain",
        condition: object.connector_type === "mail_ms_graph",
      },
      {
        labelKey: "connector.label.mail_ms_graph.client_id",
        value: object.setup.client_id,
        type: "plain",
        condition: object.connector_type === "mail_ms_graph",
      },
      {
        labelKey: "connector.label.mail_ms_graph.mailbox",
        value: object.setup.mailbox,
        type: "plain",
        condition: object.connector_type === "mail_ms_graph",
      },
      {
        labelKey: "connector.label.mail_receiver.host",
        value: object.setup.host,
        type: "plain",
        condition: ["mail_receiver", "ldap_auth", "ftp", "sftp"].includes(
          object.connector_type
        ),
      },
      {
        labelKey:
          object.connector_type === "mail_receiver"
            ? "connector.label.mail_receiver.imap_port"
            : "connector.label.ldap.port",
        value: object.setup.port,
        type: "plain",
        condition: ["mail_receiver", "ldap_auth"].includes(
          object.connector_type
        ),
      },
      {
        labelKey: "connector.label.mail_receiver.smtp_port",
        value: object.setup.smtp_port,
        type: "plain",
        condition: object.connector_type === "mail_receiver",
      },
      {
        labelKey: "implementation.smtp.label.tls",
        value: object.setup.tls,
        type: "boolean",
        condition: ["mail_receiver", "ftp", "sftp"].includes(
          object.connector_type
        ),
      },
      {
        labelKey: "connector.label.url",
        value: object.setup.url,
        type: "plain",
        condition: ["pohoda_warehouse", "ca_sdm"].includes(
          object.connector_type
        ),
      },
      {
        labelKey: "connector.label.mail_receiver.user_name",
        value: object.setup.user_name,
        type: "plain",
        condition: [
          "mail_receiver",
          "ldap_auth",
          "ftp",
          "sftp",
          "ca_sdm",
        ].includes(object.connector_type),
      },
      {
        labelKey: "implementation.smtp.label.domain",
        value: object.setup.domain,
        type: "plain",
        condition: object.connector_type === "mail_receiver",
      },
      {
        labelKey: "implementation.smtp.label.authentication",
        value: object.setup.authentication,
        type: "plain",
        condition: object.connector_type === "mail_receiver",
      },
      {
        labelKey: "implementation.smtp.label.openssl",
        value: object.setup.openssl_verify_mode,
        type: "plain",
        condition: object.connector_type === "mail_receiver",
      },
      {
        labelKey: "implementation.smtp.label.starttls",
        value: object.setup.enable_starttls_auto,
        type: "boolean",
        condition: object.connector_type === "mail_receiver",
      },
      {
        labelKey: "connector.label.login",
        value: object.setup.login,
        type: "plain",
        condition: object.connector_type === "pohoda_warehouse",
      },
      {
        labelKey: "connector.label.pohoda_warehouse.ico",
        value: object.setup.ico,
        type: "plain",
        condition: object.connector_type === "pohoda_warehouse",
      },
      {
        labelKey: "connector.label.ftp.path",
        value: object.setup.path,
        type: "plain",
        condition: ["ftp", "sftp"].includes(object.connector_type),
      },
      {
        labelKey: "user.label.name_p",
        value: object.setup.name,
        type: "plain",
        condition: object.connector_type === "ldap_auth",
      },
      {
        labelKey: "form.label.email",
        value: object.setup.email,
        type: "plain",
        condition: object.connector_type === "ldap_auth",
      },
      {
        labelKey: "form.label.updated_at",
        value: object.updated_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "connector_run.connector_runs",
      permission: "connectors_tab_run",
      component: "ConnectorRunsTab",
      input_data: (context) => context.object.connector_runs,
      disabled: () => false,
    },
  ];
}

export default Connector;
