<template>
  <div class="fill-height text-truncate">
    #{{ event.issue.id }} {{ event.issue.name }}
    <template v-if="event.issue.assets.length >= 1">
      <br />
      {{ event.issue.assets[0].name }}
      <template v-if="event.issue.assets.length >= 2">
        {{
          $t("issue.label.and_more", { number: event.issue.assets.length - 1 })
        }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "CalendarEventBody",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
