<template>
  <base-edit-dialog
    :dialog.sync="dialog"
    :label="$t('template.template')"
    :loading="actionsMixin_running"
    @positive="saveData(`${model}_detail`)"
  >
    <v-form ref="form">
      <template-visualization
        v-if="dialog"
        ref="template_form"
        v-model="edit_object.properties"
        :template-assignments="get_template_assignments"
        expand-first
        @select-images="setImages"
      ></template-visualization>
    </v-form>
  </base-edit-dialog>
</template>

<script>
import BaseEditDialog from "../../../../_generic/pages/components/base/BaseEditDialog";
import DataEdit from "../../../../_generic/mixins/DataEdit";
import TemplateVisualization from "../components/TemplateVisualization";

export default {
  name: "TemplateEditDialog",
  components: { BaseEditDialog, TemplateVisualization },
  mixins: [DataEdit],
  props: {
    model: String,
  },
  data() {
    return {
      images: [],
      imagesId: [],
      document: { name: "", doc: "" },
    };
  },
  computed: {
    get_template_assignments() {
      return this.basicMixin_getSafe(
        () =>
          this.edit_object.template_assignments.filter(
            (ta) => ta.id === this.params.id && ta.update_allowed
          ),
        []
      );
    },
  },
  methods: {
    saveDialog() {
      this.dataEdit_defaultSave();
    },
  },
};
</script>

<style scoped></style>
