<template>
  <v-form ref="form">
    <v-row dense>
      <v-col cols="12">
        <div>
          <div class="py-2 text-caption grey--text">
            {{ $t("form.label.period_filter_method") }}
          </div>

          <v-btn-toggle
            :value="value.type"
            mandatory
            active-class="light-active"
            @change="update('type', $event)"
          >
            <v-btn
              v-for="(item, key) in basicMixin_toSelect(condition_types)"
              :key="key"
              :value="item.value"
              text
              small
            >
              {{ item.text }}
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
      <v-col v-if="value.type === 'fixed'" cols="5">
        <input-date-picker-dialog
          v-model="from"
          :label="$t('form.label.date_from')"
          @input="update('from', $event)"
        />
      </v-col>

      <v-col v-if="value.type === 'fixed'" cols="5">
        <input-date-picker-dialog
          v-model="to"
          :label="$t('form.label.date_to')"
          @input="update('to', $event)"
        />
      </v-col>

      <v-col v-if="value.type === 'whole'" cols="3">
        <v-text-field
          :value="value.offset"
          :label="$t('form.label.offset')"
          :hint="$t('form.label.current_hint')"
          type="number"
          @input="update('offset', parseInt($event))"
        ></v-text-field>
      </v-col>

      <v-col v-if="value.type === 'whole' || value.type === 'last'" cols="3">
        <v-text-field
          :value="value.number"
          :label="$t('form.label.count')"
          type="number"
          min="1"
          :rules="customRules.field_value_min_1"
          @input="update('number', parseInt($event))"
        ></v-text-field>
      </v-col>

      <v-col v-if="value.type === 'whole' || value.type === 'last'" cols="4">
        <v-select
          :value="value.unit"
          :items="basicMixin_toSelect(unit)"
          :label="$t('form.label.period')"
          :rules="customRules.field_required"
          @change="update('unit', $event)"
        />
      </v-col>

      <slot></slot>
    </v-row>
  </v-form>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import PrintingDate from "../../../../_generic/mixins/PrintingDate";
import InputDatePickerDialog from "../../../../_generic/pages/components/inputs/InputDatePickerDialog";

export default {
  name: "TimeFilterForm",
  components: {
    InputDatePickerDialog,
  },
  mixins: [BasicMixin, RulesMixin, PrintingDate],
  props: {
    value: Object,
  },
  data: () => ({
    from: "",
    to: "",
    condition_types: {
      fixed: "form.label.fixed",
      last: "form.label.last",
      whole: "form.label.whole",
    },
    unit: {
      day: "calendar.types.day",
      week: "calendar.types.week",
      month: "calendar.types.month",
      year: "calendar.types.year",
    },
  }),
  methods: {
    update(key, value) {
      if (Number.isNaN(value)) return;

      const newValue = { ...this.value, [key]: value };
      if (key === "from")
        newValue.from = this.$moment(value).startOf("day").toISOString();
      else if (key === "to")
        newValue.to = this.$moment(value).endOf("day").toISOString();

      this.$emit("input", newValue);
    },
    validate() {
      if (this.value.type === "fixed" && !this.from && !this.to) return false;
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>
