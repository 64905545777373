<template>
  <v-toolbar
    elevation="1"
    style="z-index: 10"
    :max-height="$vuetify.breakpoint.xsOnly ? 56 : 64"
  >
    <v-toolbar-title>
      <span :class="$vuetify.theme.dark ? 'text--text' : 'primary--text'">{{
        header
      }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <slot>
      <base-icon-button
        :icon="mdiClose"
        :tooltip-translation="$t('action.close')"
        tabindex="-1"
        @click="$emit('input', false)"
      />
    </slot>

    <slot name="title-bottom"></slot>
  </v-toolbar>
</template>

<script>
import { mdiClose } from "@mdi/js";
import BaseIconButton from "../BaseIconButton";

export default {
  name: "BaseVCardTitleDialogIntern",
  components: { BaseIconButton },
  props: {
    value: Boolean,
    model: String,
    editMode: Boolean,
    label: String,
  },
  data: () => ({
    mdiClose,
  }),
  computed: {
    header() {
      if (this.label) return this.label;

      if (this.editMode)
        return this.$t("action.update") + " " + this.$t(`${this.model}.acc`);

      if (this.model) {
        return (
          this.$t(`${this.model}.alert.new`) +
          " " +
          this.$t(`${this.model}.${this.model}`).replace(
            /^[A-Z](?![A-Z])/,
            (m) => m.toLowerCase()
          )
        );
      } else return "";
    },
  },
};
</script>

<style scoped></style>
