<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :label="$t('permission.label.detail')"
      ></base-v-card-title-dialog-intern>

      <v-card-text class="pt-6">
        <!--    PERMISSIONS    -->
        <v-data-table
          v-if="ownerType"
          :items="results"
          :headers="headers"
          :loading="loading"
          :items-per-page="-1"
          dense
          disable-sort
          hide-default-footer
          no-data-text=""
        >
          <template
            v-for="h in permissionsConstants[headersKey]"
            #[`header.${h.type}`]="{ header }"
          >
            <base-tooltip-icon
              :key="h.type"
              :icon="header.icon"
              :label="$t(`permission.tooltip.${header.type}`)"
            ></base-tooltip-icon>
          </template>

          <template #item.name="{ item }">
            <link-checked
              :id="item.owner.id"
              :to="item.class"
              :text="item.owner.name"
              :discarded-at="item.owner.discarded_at"
            ></link-checked>
          </template>

          <template
            v-for="h in permissionsConstants[headersKey]"
            #[`item.${h.type}`]="{ item }"
          >
            <permission-setter
              :key="h.type"
              :header="h"
              :permission="item.permission"
              :setup="item.permissions_hash"
              :permission-type="headersKey"
              :application="application"
              disabled
            ></permission-setter>
          </template>
        </v-data-table>
      </v-card-text>
      <base-v-card-actions-buttons-dialog
        negative-translation="action.close"
        @negative="dialog = false"
      ></base-v-card-actions-buttons-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import BaseVCard from "../../../../_generic/pages/components/base/BaseVCard";
import LinkChecked from "../../components/LinkChecked";
import BaseTooltipIcon from "../../../../_generic/pages/components/base/BaseTooltipIcon";
import permissionsConstants from "../formats/PermissionsConstants";
import PermissionSetter from "./PermissionSetter";

export default {
  name: "PermissionsTooltipDialog",
  components: {
    PermissionSetter,
    BaseTooltipIcon,
    LinkChecked,
  },
  mixins: [BasicMixin, BaseVCard],
  props: {
    headersKey: String,
    ownerType: String,
    ownerId: Number,
    application: Boolean,
  },
  data: () => ({
    dialog: false,
    loading: false,
    results: [],
  }),
  computed: {
    permissionsConstants() {
      return permissionsConstants;
    },
    headers() {
      const headersArray = [
        { text: this.$t("permission.label.owner"), value: "name" },
      ];

      for (const header of this.permissionsConstants[this.headersKey]) {
        header.text = this.$t(`permission.tooltip.${header.type}`);
        header.width = 52;
        header.value = header.type;
        headersArray.push(header);
      }

      return headersArray;
    },
  },
  methods: {
    openDialog(id) {
      this.dialog = true;
      this.fetchData(id);
    },
    fetchData(id) {
      this.loading = true;
      this.$http
        .get(`permissions/tooltip`, {
          params: {
            owner_type: this.ownerType,
            owner_id: this.ownerId,
            entity_type: this.headersKey,
            entity_id: id,
          },
        })
        .then(
          (response) => {
            this.results = response.body;
            this.loading = false;
          },
          (response) => {
            this.error_message("load", "permission", response);
          }
        );
    },
  },
};
</script>

<style scoped></style>
