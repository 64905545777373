import BasicMixin from "../mixins/BasicMixin";

const LogOutMixin = {
  mixins: [BasicMixin],
  methods: {
    async logOutMixin_logout() {
      if (
        this.$store.state.basic_module.settings.aad &&
        this.$msal.isAuthenticated()
      ) {
        const user = this.$msal.user();
        await this.$msal.instance.logoutPopup({ account: user });
      }
      // Delete token from BE
      this.$http.post("logout", {});

      this.$vuetify.theme.dark = false;
      this.$store.dispatch("loadable/resetAllItems");
      this.$store.commit("app_state/reset");
      this.messageCustom("messages.logout", "success");
      this.$router.push("/public/auth/login");

      this.$store.state.basic_module.loaded_next = "/";
      this.$store.state.basic_module.loaded_next_query = {};
    },
  },
};

export default LogOutMixin;
