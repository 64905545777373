import Vue from "vue";
import VueI18n from "vue-i18n";
import sk from "./sk";
import en from "./en";

Vue.use(VueI18n);

const messages = { sk, en };
const pluralizationRules = {
  sk: (choice) => (choice === 1 ? 0 : choice > 1 && choice < 5 ? 1 : 2),
  en: (choice) => (choice === 1 ? 0 : 1),
};
const numberFormats = {
  sk: {
    currency: { style: "currency", currency: "EUR" },
    percent: { style: "percent" },
  },
  en: {
    currency: { style: "currency", currency: "EUR" },
    percent: { style: "percent" },
  },
};

export default new VueI18n({
  locale: "sk",
  fallbackLocale: "sk",
  messages,
  pluralizationRules,
  numberFormats,
});
