import _DatabaseModel from "./_DatabaseModel";

class Notification extends _DatabaseModel {
  static model = "notification";
  static cardComponent = "notification-card";
  static bottomNav = { mobile: ["card"], desktop: ["card"] };
  static customSort = false;
  static createNewObject = false;
}

export default Notification;
