<template>
  <div>
    <v-combobox
      ref="combobox"
      :value="value"
      :label="label"
      :type="type"
      multiple
      clearable
      :append-outer-icon="appendOuterIcon"
      @click:append-outer="$emit('addToInner', $event)"
      @input="$emit('input', parseMultipleValuesOnSemicolon($event))"
      @keydown.esc="$event.target.blur()"
    >
      <template #selection="{ item, parent }">
        <v-chip small close @click:close="parent.selectItem(item)">
          {{ item }}
        </v-chip>
      </template>
      <template #append>
        <v-icon v-if="scanner" class="pl-1" @click.stop="openDialog_camera()"
          >{{ mdiBarcodeScan }}
        </v-icon>
      </template>
    </v-combobox>
    <assets-qr-scanner ref="assets_qr_scanner" @input="addItem($event)" />
  </div>
</template>

<script>
import AssetsQrScanner from "../../components/searcher/AssetsQrScanner";
import { mdiBarcodeScan } from "@mdi/js";
export default {
  name: "FilterMultiSearchComponent",
  components: {
    AssetsQrScanner,
  },
  props: {
    value: {
      type: [String, Array],
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    appendOuterIcon: String,
    scanner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiBarcodeScan,
    };
  },
  methods: {
    addItem(data) {
      let arr;
      if (!this.value) {
        arr = [];
      } else {
        arr = this.value;
      }
      arr.push(data.id.toString());
      this.$emit("input", arr);
    },
    openDialog_camera() {
      this.$refs.assets_qr_scanner.open_qr_scanner();
    },
    parseMultipleValuesOnSemicolon(event) {
      return event
        .join(";")
        .split(/;\s?/)
        .filter((item) => item.length !== 0);
    },
  },
};
</script>

<style scoped></style>
