<template>
  <div class="text-center">
    <v-tooltip v-for="(holiday, index) in holidays" :key="index" bottom>
      <template #activator="{ on }">
        <v-chip outlined x-small v-on="on">
          <span class="text-truncate">{{ holiday.name }}</span>
        </v-chip>
      </template>
      <span>{{ holiday.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";

export default {
  name: "HolidaysDisplay",
  mixins: [BasicMixin],
  props: {
    holidays: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
