const ActivityMixins = {
  methods: {
    activityMixins_activityColor(type) {
      let color;
      switch (type) {
        case "update":
        case "archive":
        case "unarchive":
        case "state_change":
          color = "blue";
          break;
        case "create":
          color = "green";
          break;
        case "destroy":
          color = "red";
          break;
        case "log_in":
        case "log_out":
          color = "purple";
          break;
        default:
          color = "grey";
          break;
      }
      return `${color}`;
    },
    expandItem(item, isExpanded, expand, model) {
      if (!item.loaded) {
        this.$http.get(`${this.modelPluralize(model)}/${item.id}`, {}).then(
          (response) => {
            item.loaded = true;
            item.object = response.body.object;
            expand(!isExpanded);
          },
          (response) => this.error_message("load", model, response)
        );
      } else {
        expand(!isExpanded);
      }
    },
    authorName(author, authorType, authorId) {
      if (author) return author.name;

      let typeModel = this.toModel(authorType);
      if (typeModel) {
        let translation = this.$t(`${typeModel}.${typeModel}`);
        return `${translation} ${authorId}`;
      }
      return this.$t("generic.system");
    },
  },
};

export default ActivityMixins;
