const BaseButtonsMixin = {
  props: {
    tooltipTranslation: String,
    tooltipDisabledTranslation: String,
    color: String,
    top: Boolean,
    left: Boolean,
    right: Boolean,
    disabled: Boolean,
    loading: Boolean,
    shortKey: Array,
    icon: String,
  },
  computed: {
    colorComputed() {
      return this.color
        ? this.color
        : this.$vuetify.theme.dark
        ? "text"
        : "primary";
    },
    bottom() {
      return Boolean(!this.top && !this.left && !this.right);
    },
    tooltip() {
      return this.disabled
        ? this.tooltipDisabledTranslation
        : this.tooltipTranslation;
    },
  },
};

export default BaseButtonsMixin;
