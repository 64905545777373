<template>
  <v-expansion-panels v-model="panel" multiple>
    <v-expansion-panel v-if="klass">
      <v-expansion-panel-header :class="{ 'px-3': $vuetify.breakpoint.xsOnly }">
        <strong class="py-2">{{ $t("other.basic_info") }}</strong>
      </v-expansion-panel-header>
      <v-expansion-panel-content :class="{ panel: $vuetify.breakpoint.xsOnly }">
        <data-show-fields
          v-if="klass && Object.keys(input_data.object).length > 0"
          :object="input_data.object"
          :klass="klass"
          @open-assets-issues-tab="$emit('open-assets-issues-tab')"
        ></data-show-fields>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel
      v-for="(item, index) in template_assignments_computed"
      :key="index"
      v-model="panel"
    >
      <v-expansion-panel-header :class="{ 'px-3': $vuetify.breakpoint.xsOnly }">
        <strong>{{ getTranslation(item.name) }}</strong>
        <div v-if="!input_data.public" class="d-flex justify-end align-center">
          <v-progress-circular
            :value="fillInPercent[item.id]"
            color="primary"
            rotate="-90"
            size="25"
            width="6"
            class="mr-2"
          >
          </v-progress-circular>
          <base-icon-button
            v-if="klass"
            :icon="mdiPencil"
            :tooltip-translation="$t('action.edit')"
            :tooltip-disabled-translation="
              $t('tooltips.show_buttons.disabled_buttons.generic')
            "
            :disabled="!item.update_allowed || disabled"
            @click.stop="openEdit(item.id)"
          ></base-icon-button>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content :class="{ panel: $vuetify.breakpoint.xsOnly }">
        <v-simple-table style="width: 100%" dense>
          <template #default>
            <tbody>
              <tr
                v-for="{ field } in filterFields(item.field_assignments)"
                :key="field.key"
              >
                <td style="width: 40%">
                  {{ getTranslation(field.name) }}
                </td>
                <td>
                  <template
                    v-if="['Boolean', 'Switch'].includes(field.data_type)"
                  >
                    {{
                      basicMixin_translateTrueFalse(
                        basicMixin_getSafe(
                          () => input_data.properties[field.key],
                          "0"
                        ) === "1"
                      )
                    }}
                  </template>

                  <template
                    v-else-if="
                      ['Integer'].includes(field.data_type) &&
                      input_data.properties[field.key]
                    "
                  >
                    <span>{{ $n(input_data.properties[field.key]) }}</span>
                  </template>

                  <template
                    v-else-if="['Select', 'Radio'].includes(field.data_type)"
                  >
                    <div v-if="field.multiselect">
                      <ul class="pl-0">
                        <li
                          v-for="option of input_data.properties[field.key]"
                          :key="option"
                        >
                          {{
                            basicMixin_getSafe(
                              () => getTranslation(field.options[option]),
                              ""
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      {{
                        basicMixin_getSafe(
                          () =>
                            getTranslation(
                              field.options[input_data.properties[field.key]]
                            ),
                          ""
                        )
                      }}
                    </div>
                  </template>

                  <template v-else-if="['Date'].includes(field.data_type)">
                    {{
                      basicMixin_getSafe(
                        () => input_data.properties[field.key],
                        ""
                      ) | moment(date_format())
                    }}
                  </template>

                  <template v-else-if="['DateTime'].includes(field.data_type)">
                    {{
                      basicMixin_getSafe(
                        () => input_data.properties[field.key],
                        ""
                      ) | moment(date_time_format())
                    }}
                  </template>

                  <template
                    v-else-if="
                      ['Picture'].includes(field.data_type) &&
                      input_data.properties &&
                      input_data.properties[field.key]
                    "
                  >
                    <v-img
                      max-width="200"
                      max-height="200"
                      class="rounded-lg image-border"
                      :src="`/documents/${
                        input_data.properties[field.key]
                      }/preview`"
                    />
                  </template>

                  <template
                    v-else-if="
                      ['Password'].includes(field.data_type) &&
                      input_data.properties &&
                      input_data.properties[field.key]
                    "
                  >
                    <input-password
                      :value="input_data.properties[field.key]"
                      readonly
                    />
                  </template>

                  <template
                    v-else-if="
                      ['Person'].includes(field.data_type) &&
                      input_data.properties &&
                      input_data.properties[field.key]
                    "
                  >
                    <link-checked
                      v-for="personId in [].concat(
                        input_data.properties[field.key]
                      )"
                      :id="personId"
                      :key="personId"
                      to="persons"
                      :text="getName('person', personId)"
                      truncate
                    ></link-checked>
                  </template>

                  <template
                    v-else-if="
                      ['Asset'].includes(field.data_type) &&
                      input_data.properties &&
                      input_data.properties[field.key]
                    "
                  >
                    <link-checked
                      v-for="assetId in [].concat(
                        input_data.properties[field.key]
                      )"
                      :id="assetId"
                      :key="assetId"
                      to="assets"
                      :text="getName('asset', assetId)"
                      truncate
                    ></link-checked>
                  </template>

                  <template
                    v-else-if="
                      ['Issue'].includes(field.data_type) &&
                      input_data.properties &&
                      input_data.properties[field.key]
                    "
                  >
                    <link-checked
                      v-for="issueId in [].concat(
                        input_data.properties[field.key]
                      )"
                      :id="issueId"
                      :key="issueId"
                      to="issues"
                      :text="getName('issue', issueId)"
                      truncate
                    ></link-checked>
                  </template>

                  <template v-else>
                    <div
                      v-linkified:options="{
                        validate: {
                          url: (value) => /^https?:\/\//.test(value),
                        },
                      }"
                      class="text-pre-wrap"
                      v-text="
                        basicMixin_getSafe(
                          () => input_data.properties[field.key],
                          ''
                        )
                      "
                    ></div>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel
      v-if="
        $store.state.basic_module.permissions.raw_data &&
        template_assignments_computed.length > 0
      "
    >
      <v-expansion-panel-header :class="{ 'px-3': $vuetify.breakpoint.xsOnly }">
        <strong class="py-2">{{ $t("template.raw") }}</strong>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <pre>{{ JSON.stringify(input_data.properties, null, 2) }}</pre>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <template-edit-dialog
      v-if="klass"
      ref="edit_dialog"
      :model="klass.model"
      @on-success="onSuccess($event)"
    ></template-edit-dialog>
  </v-expansion-panels>
</template>

<script>
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import TabsMixin from "../../../_generic/mixins/TabsMixin";
import PrintingDate from "../../../_generic/mixins/PrintingDate";
import LinkChecked from "../components/LinkChecked";
import InputPassword from "../../../_generic/pages/components/inputs/InputPassword";
import DataShowFields from "../components/data_show/components/DataShowFields";
import BaseIconButton from "../../../_generic/pages/components/base/BaseIconButton";
import TemplateEditDialog from "../templates/dialog/TemplateEditDialog";

export default {
  name: "TemplatesDetailTab",
  components: {
    BaseIconButton,
    LinkChecked,
    InputPassword,
    DataShowFields,
    TemplateEditDialog,
  },
  mixins: [BasicMixin, TabsMixin, PrintingDate],
  props: {
    klass: Function,
  },
  data() {
    return {
      panel: [0],
      personNames: [],
      assetNames: [],
      issueNames: [],
    };
  },
  computed: {
    fillInPercent() {
      let value = {};
      for (const ta of this.template_assignments_computed) {
        let keys = this.filterFields(ta.field_assignments).map(
          (p) => p.field.key
        );
        let items = [];
        for (const key of keys) {
          if (this.input_data.properties) {
            let field = this.input_data.properties[key];
            if (
              field &&
              field !== "" &&
              (!Array.isArray(field) || field.length > 0)
            ) {
              items.push(field);
            }
          }
        }
        value[ta.id] = Math.floor((items.length / keys.length) * 100);
      }
      return value;
    },
    template_assignments_computed() {
      if (!this.input_data.template_assignments) return [];
      return this.input_data.template_assignments;
    },
  },
  watch: {
    "input_data.templatable_id"() {
      this.panel = [0];
    },
    template_assignments_computed: {
      handler() {
        if (this.template_assignments_computed.length > 0) {
          this.getNames();
        }
      },
      immediate: true,
    },
  },
  methods: {
    filterFields(field_assignments) {
      return field_assignments.filter(
        (fa) =>
          !fa.field.condition ||
          (this.input_data.properties &&
            Object.entries(fa.field.condition.value).some((val) =>
              []
                .concat(val[1])
                .includes(
                  this.input_data.properties[fa.field.condition.field_key]
                )
            ))
      );
    },
    getName(model, id) {
      const obj = this[`${model}Names`].find((i) => i.id === parseInt(id));
      if (obj) {
        return obj.name;
      }
    },
    getModelObjects(model) {
      let arr = [];
      for (const ta of this.template_assignments_computed) {
        for (const fa of ta.field_assignments) {
          if (
            model === fa.field.data_type &&
            this.input_data.properties?.[fa.field.key]
          ) {
            arr.push(this.input_data.properties[fa.field.key]);
          }
        }
      }
      return arr.flat();
    },
    getNames() {
      for (const model of ["asset", "person", "issue"]) {
        const objects = this.getModelObjects(this.toModelUpperCase(model));
        if (objects.length < 1) continue;

        this.$http
          .get(`${this.modelPluralize(model)}/load_names`, {
            params: {
              ids: objects,
            },
          })
          .then(
            (response) => {
              this[`${model}Names`] = response.body.object;
            },
            (response) => {
              this.error_message("load", model, response);
            }
          );
      }
    },
    openEdit(id) {
      this.$refs.edit_dialog.openDialog(
        this.input_data.object,
        { id: id },
        true
      );
    },
    onSuccess(data) {
      this.input_data.object.properties = data.properties;
      this.input_data.properties = data.properties;
    },
  },
};
</script>

<style lang="scss">
.panel > .v-expansion-panel-content__wrap {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
