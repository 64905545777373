<template>
  <v-dialog
    :max-width="maxWidth"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="innerDialog"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        :model="model"
        :label="label"
        :edit-mode="editMode"
        @input="innerDialog = false"
      >
        <template #title-bottom>
          <slot name="title-bottom"></slot>
        </template>
      </base-v-card-title-dialog-intern>

      <v-card-text class="pt-6">
        <slot></slot>
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        :positive-translation="positiveTranslation"
        negative-translation="action.cancel"
        :loading="loading"
        :disabled="disabled"
        @positive="$emit('positive')"
        @negative="innerDialog = false"
      ></base-v-card-actions-buttons-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import BaseVCard from "./BaseVCard";

export default {
  name: "BaseEditDialog",
  mixins: [BasicMixin, BaseVCard],
  props: {
    dialog: Boolean,
    editMode: Boolean,
    loading: Boolean,
    disabled: Boolean,
    model: String,
    label: String,
    maxWidth: {
      type: String,
      default: "600",
    },
    positiveTranslation: {
      type: String,
      default: "action.save",
    },
  },
  computed: {
    innerDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        this.$emit("update:dialog", dialog);
      },
    },
  },
};
</script>

<style scoped></style>
