import _DatabaseModel from "./_DatabaseModel";

class Soa extends _DatabaseModel {
  static model = "soa";
  static showSize = 8;
  static editDialogComponent = "soas-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.organization_id", value: "person.name" },
    { textKey: "activity.list_label.created_at", value: "created_at" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "activity", "destroy"],
  };

  static filter = {
    parts: {
      fields: ["person_id"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.person.name,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "person.person",
        value: object.person,
        type: "link",
        to: "persons",
      },
    ];
  }
  static tabs = [
    this.detailTab,
    {
      titleKey: "control.controls",
      permission: "soa_tab_controls",
      component: "SoaControlsTab",
      input_data: (context) => context.object.soa_controls,
      disabled: () => false,
    },
  ];
}

export default Soa;
