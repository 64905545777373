<template>
  <v-alert
    outlined
    type="warning"
    :icon="mdiAlertCircle"
    text
    class="my-2 text-left"
  >
    {{ $t("dataTableMessages.emptyData.headerPt1") }}
    {{ name }}{{ $t("dataTableMessages.emptyData.headerPt2") }}:
    <ul>
      <li class="pa-1">
        {{ $t("dataTableMessages.emptyData.emptyDatabaseReason") }}
      </li>
      <li class="pa-1">
        {{ $t("dataTableMessages.emptyData.permissionReason") }}
      </li>
      <li class="pa-1">{{ $t("dataTableMessages.emptyData.filterReason") }}</li>
    </ul>
  </v-alert>
</template>

<script>
import { mdiAlertCircle } from "@mdi/js";
export default {
  name: "BaseEmptyDataAlert",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mdiAlertCircle,
    };
  },
};
</script>

<style scoped></style>
