<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :label="$t('first_login.headers.cookies')"
      ></base-v-card-title-dialog-intern>

      <v-card-text class="pt-6">
        <div>
          <p>
            AUSEMIO s.r.o. používa súbory cookie, ktoré sú potrebné pre správne
            fungovanie jej webových stránok a webových aplikácií.
          </p>

          <p>
            Súbory cookie sú malé textové súbory, ktoré môžu webové stránky
            používať na zefektívnenie používateľskej skúsenosti a ďalšie účely.
            Popis nájdete na
            <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank"
              >https://en.wikipedia.org/wiki/HTTP_cookie</a
            >.
          </p>

          <p>
            Súbory cookie AUSEMIO s.r.o. tiež používa na prispôsobenie obsahu a
            na analýzu návštevnosti. Táto stránka používa rôzne typy súborov
            cookie. Pre pokračovanie v používaní týchto webových stránok je
            potrebný Váš súhlas s používaním cookies.
          </p>

          <p>
            Váš súhlas sa vzťahuje na nasledujúce domény: ausemio.com, ausem.io.
          </p>

          <p>
            Kliknutím na položku
            <strong
              >"Som si vedomý, že aplikácia používa cookies a súhlasím s
              tým”</strong
            >
            pokračujte s nastaveniami cookies, ktoré zabezpečia správnu funkciu
            webových stránok resp. webových aplikácií AUSEMIO s.r.o.
          </p>

          <p>
            Potrebné súbory cookie pomáhajú vytvárať webové stránky pomocou
            použitia základných funkcií, ako je navigácia na stránkach a prístup
            do zabezpečených oblastí webových stránok. Webové stránky nemôžu
            fungovať správne bez týchto cookies:
          </p>

          <ul class="pb-2">
            <li>jwt – autentifikácia</li>
          </ul>

          <p class="pb-0">
            V prípade, že nesúhlasíte s použítim cookies, prosím zmeňte
            nastavenia vášho prehliadača:
            <em>"Zobraziť nastavenia súborov cookie"</em>
          </p>
        </div>
      </v-card-text>
      <base-v-card-actions-buttons-dialog
        negative-translation="action.close"
        @negative="dialog = false"
      ></base-v-card-actions-buttons-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseVCard from "../../components/base/BaseVCard";

export default {
  name: "CookiesDialog",
  mixins: [BaseVCard],
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
