const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const RulesMixin = {
  computed: {
    customRules() {
      return {
        field_required: [
          (v) =>
            (!Array.isArray(v) && !!v) ||
            (Array.isArray(v) && v.length > 0) ||
            String(v) === "0" ||
            this.$t("form.error.required"),
        ],
        field_min_1_max_255: [
          (v) => !!v || this.$t("form.error.required"),
          (v) =>
            (v && v.length <= 255 && v.length >= 1) ||
            this.$t("form.error.length_min_max", {
              min: 1,
              max: 255,
            }),
        ],
        field_min_1_max_30: [
          (v) => !!v || this.$t("form.error.required"),
          (v) =>
            (v && v.length <= 30 && v.length >= 1) ||
            this.$t("form.error.length_min_max", {
              min: 1,
              max: 30,
            }),
        ],
        field_min_3: [
          (v) => !!v || this.$t("form.error.required"),
          (v) =>
            (v && v.length >= 3) ||
            this.$t("form.error.length_min", { min: 3 }),
        ],
        field_json: [
          (v) => this.rulesMixin_isJSON(v) || this.$t("form.error.json"),
        ],
        field_value_min_1: [
          (v) => !!v || this.$t("form.error.required"),
          (v) => (v && v >= 1) || this.$t("form.error.value_min", { min: 1 }),
        ],
        field_value_higher_0: [
          (v) =>
            (v !== null && typeof v !== "undefined" && v !== "") ||
            this.$t("form.error.required"),
          (v) =>
            (v && v > 0) || this.$t("form.error.value_higher", { value: 0 }),
        ],
        field_value_higher_equal_0: [
          (v) =>
            (v !== null && typeof v !== "undefined" && v !== "") ||
            this.$t("form.error.required"),
          (v) =>
            parseFloat(v) >= 0 ||
            this.$t("form.error.value_higher_equal_zero", { value: 0 }),
        ],
        field_email: [
          (v) => !!v || this.$t("form.error.required"),
          (v) => emailRegex.test(v) || this.$t("form.error.email"),
        ],
        field_multiple_email: [
          (v) =>
            !v ||
            v.every((v1) => emailRegex.test(v1)) ||
            this.$t("form.error.multi_email"),
        ],
        field_longitude: [
          (v) => !!v || this.$t("form.error.required"),
          (v) =>
            (v && v <= 180 && v >= -180) ||
            this.$t("form.error.value_min_max", {
              min: -180,
              max: 180,
            }),
        ],
        field_latitude: [
          (v) => !!v || this.$t("form.error.required"),
          (v) =>
            (v && v <= 90 && v >= -90) ||
            this.$t("form.error.value_min_max", {
              min: -90,
              max: 90,
            }),
        ],
      };
    },
    passwordRules() {
      return [
        (v) => !!v || String(v) === "0" || this.$t("form.error.required"),
        (v) =>
          (v && v.length >= 8) || this.$t("form.error.length_min", { min: 8 }),
        (v) => /[0-9]/.test(v) || this.$t("form.error.must_contain_number"),
        (v) => /[a-z]/.test(v) || this.$t("form.error.must_contain_lower"),
        (v) => /[A-Z]/.test(v) || this.$t("form.error.must_contain_upper"),
      ];
    },
  },
  methods: {
    rulesMixin_isJSON(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    later_date(prevDate, prevLabel) {
      return [
        (v) =>
          !v ||
          this.$moment(v).isAfter(prevDate) ||
          this.$t("form.error.later_date", { prev: prevLabel }),
      ];
    },
    higher_equal_0_lower_equal_custom_value(customValue) {
      return [
        (v) =>
          (v !== null && typeof v !== "undefined" && v !== "") ||
          this.$t("form.error.required"),
        (v) =>
          (parseFloat(v) <= customValue && parseFloat(v) >= 0) ||
          this.$t("form.error.value_higher_equal_0_lower_equal_custom_value", {
            value: customValue,
          }),
      ];
    },
    custom_range(min, max) {
      return [
        (v) =>
          (v !== null && typeof v !== "undefined" && v !== "") ||
          this.$t("form.error.required"),
        (v) =>
          (parseFloat(v) >= min && parseFloat(v) <= max) ||
          this.$t("form.error.value_custom_range", { min: min, max: max }),
      ];
    },
    higher_equal_0_lower_equal_custom_value_no_required(customValue) {
      return [
        (v) =>
          (parseFloat(v) <= customValue && parseFloat(v) >= 0) ||
          this.$t("form.error.value_higher_equal_0_lower_equal_custom_value", {
            value: customValue,
          }),
      ];
    },
    higher_custom_value(customValue) {
      return [
        (v) =>
          parseFloat(v) > customValue ||
          this.$t("form.error.value_higher", { value: customValue }),
      ];
    },
    higher_equal_custom_value(customValue) {
      return [
        (v) =>
          parseFloat(v) >= customValue ||
          this.$t("form.error.value_min", { min: customValue }),
      ];
    },
    lower_equal_custom_value(customValue) {
      return [
        (v) =>
          parseFloat(v) <= customValue ||
          this.$t("form.error.value_max", { max: customValue }),
      ];
    },
  },
};

export default RulesMixin;
