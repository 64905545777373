import Activity from "../classes/Activity";
import Asset from "../classes/Asset";
import AssetCategory from "../classes/AssetCategory";
import AssetGroup from "../classes/AssetGroup";
import AssetsIssue from "../classes/AssetsIssue";
import AutomatedAction from "../classes/AutomatedAction";
import Category from "../classes/Category";
import Contract from "../classes/Contract";
import Control from "../classes/Control";
import Dashlet from "../classes/Dashlet";
import DataSet from "../classes/DataSet";
import Document from "../classes/Document";
import ExternalEmail from "../classes/ExternalEmail";
import Field from "../classes/Field";
import Issue from "../classes/Issue";
import Kpi from "../classes/Kpi";
import Person from "../classes/Person";
import PersonCategory from "../classes/PersonCategory";
import PriceItem from "../classes/PriceItem";
import PriceItemsWarehouse from "../classes/PriceItemsWarehouse";
import PriceList from "../classes/PriceList";
import PrimaryAsset from "../classes/PrimaryAsset";
import Report from "../classes/Report";
import Requirement from "../classes/Requirement";
import Role from "../classes/Role";
import Rule from "../classes/Rule";
import Service from "../classes/Service";
import Snippet from "../classes/Snippet";
import Subscription from "../classes/Subscription";
import TagDefinition from "../classes/TagDefinition";
import Threat from "../classes/Threat";
import Translation from "../classes/Translation";
import User from "../classes/User";
import Vulnerability from "../classes/Vulnerability";
import Warehouse from "../classes/Warehouse";
import WarehouseTransactionItem from "../classes/WarehouseTransactionItem";

const ClassesMixin = {
  data: () => ({
    Activity: Activity,
    Asset: Asset,
    AssetCategory: AssetCategory,
    AssetGroup: AssetGroup,
    AssetsIssue: AssetsIssue,
    AutomatedAction: AutomatedAction,
    Category: Category,
    Contract: Contract,
    Control: Control,
    Dashlet: Dashlet,
    DataSet: DataSet,
    Document: Document,
    ExternalEmail: ExternalEmail,
    Field: Field,
    Issue: Issue,
    Kpi: Kpi,
    Person: Person,
    PersonCategory: PersonCategory,
    PriceItem: PriceItem,
    PriceItemsWarehouse: PriceItemsWarehouse,
    PriceList: PriceList,
    PrimaryAsset: PrimaryAsset,
    Report: Report,
    Requirement: Requirement,
    Role: Role,
    Rule: Rule,
    Service: Service,
    Snippet: Snippet,
    Subscription: Subscription,
    TagDefinition: TagDefinition,
    Threat: Threat,
    Translation: Translation,
    User: User,
    Vulnerability: Vulnerability,
    Warehouse: Warehouse,
    WarehouseTransactionItem: WarehouseTransactionItem,
  }),
};

export default ClassesMixin;
