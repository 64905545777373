import _DatabaseModel from "./_DatabaseModel";

class WarehouseTransactionItem extends _DatabaseModel {
  static model = "warehouse_transaction_item";

  static tableHeaders = [
    {
      textKey: "price_item.price_item",
      value: "price_item.name",
      sortable: false,
    },
    { textKey: "price_item.label.quantity", value: "quantity" },
  ];

  static customSort = false;

  static filter = {
    parts: {
      fields: [
        "price_item_id",
        "source_warehouse_id",
        "target_warehouse_id",
        "transaction_type",
        "time_filter",
      ],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.price_item.name,
      subtitle: object.quantity,
    };
  }
}

export default WarehouseTransactionItem;
