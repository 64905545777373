<template>
  <v-tooltip v-if="datetime && $i18n.locale" bottom open-delay="500">
    <template #activator="{ on }">
      <span v-if="preferExactDates" class="text-no-wrap" v-on="on">
        {{ datetime | moment(date_time_format()) }}
      </span>
      <span v-else v-on="on">{{ datetime | moment("from", "now") }}</span>
    </template>
    <span v-if="preferExactDates">{{ datetime | moment("from", "now") }}</span>
    <span v-else class="text-no-wrap">
      {{ datetime | moment(date_time_format()) }}
    </span>
  </v-tooltip>
</template>

<script>
import PrintingDate from "../../../mixins/PrintingDate";

export default {
  name: "BaseTooltipDateData",
  mixins: [PrintingDate],
  props: {
    datetime: String,
  },
  computed: {
    preferExactDates() {
      return this.$store.getters["basic_module/get_personalization"]
        .prefer_exact_dates;
    },
  },
};
</script>

<style scoped></style>
