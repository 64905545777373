import Vue from "vue";
import BasicMixin from "../../../_generic/mixins/BasicMixin";

const state = {
  list: [],
  total_unread: 0,
  last_update: null,
};

const mutations = {
  SET_DATA(state, data) {
    state.list = data.list;
    state.total_unread = data.total_unread;
    state.last_update = new Date();
  },
  DECREMENT(state) {
    state.total_unread -= 1;
  },
};

const getters = {};

const actions = {
  fetchItems(context) {
    Vue.http
      .get("notifications", {
        params: {
          page: 1,
          per_page: 10,
          filter: { not_read: true },
        },
      })
      .then(
        (response) => {
          context.commit("SET_DATA", {
            list: response.body.objects,
            total_unread: response.body.total,
          });
        },
        (response) => {
          BasicMixin.methods.error_message("load", "notification", response);
        }
      );
  },
  decrement(context) {
    context.commit("DECREMENT");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
