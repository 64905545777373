<template>
  <v-tooltip
    open-delay="500"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :disabled="
      $vuetify.breakpoint.xsOnly ||
      (!tooltipTranslation && !tooltipDisabledTranslation)
    "
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          v-shortkey="shortKey"
          :disabled="disabled"
          class="ml-1"
          :color="colorComputed"
          :loading="loading"
          icon
          :large="$vuetify.breakpoint.xsOnly"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import BaseButtonsMixin from "../../../mixins/BaseButtonsMixin";

export default {
  name: "BaseIconButton",
  mixins: [BaseButtonsMixin],
};
</script>

<style scoped></style>
