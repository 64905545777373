<template>
  <v-app id="inspire" :key="renderKey">
    <navigation-drawer
      ref="navigation_drawer"
      @drawer-change="mini = $event"
    ></navigation-drawer>

    <v-app-bar
      v-shortkey="shortCuts"
      color="secondary"
      class="menu px-1"
      dense
      app
      elevation="1"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      @shortkey="goTo"
    >
      <v-app-bar-nav-icon @click.stop="$refs.navigation_drawer.change()">
        <v-icon
          :class="{ rot: mini || $vuetify.breakpoint.smAndDown }"
          :color="$vuetify.theme.dark ? 'text' : 'primary'"
          >{{ mdiMenuOpen }}</v-icon
        >
      </v-app-bar-nav-icon>

      <v-toolbar-title class="hidden-sm-and-down pr-10 pl-5">
        <v-img
          height="35"
          width="158"
          contain
          :src="
            $vuetify.theme.dark
              ? brandingMixin_appNameIconDark
              : brandingMixin_appNameIcon
          "
          style="cursor: pointer"
          @click="goHome()"
        ></v-img>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="header && $vuetify.breakpoint.smAndUp"
        :class="{ 'primary--text': !$vuetify.theme.dark }"
        class="mr-2"
      >
        {{ $t(header) }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <menu-global-searcher
        v-if="$vuetify.breakpoint.smAndUp"
      ></menu-global-searcher>

      <base-icon-button
        v-else
        :tooltip-translation="$t('tooltips.tabs_icons.search')"
        :icon="mdiMagnify"
        @click="search = !search"
      ></base-icon-button>

      <template v-if="search && $vuetify.breakpoint.xsOnly" #extension>
        <menu-global-searcher
          @close-searcher="search = false"
        ></menu-global-searcher>
      </template>

      <template v-if="canSeeAppPart('notifications')">
        <notification-bell></notification-bell>
      </template>

      <base-icon-button
        :tooltip-translation="$t('tooltips.navbar_icons.documentation')"
        :icon="mdiHelpCircleOutline"
        :href="wikiLink"
        rel="noopener"
        target="_blank"
      ></base-icon-button>

      <theme-switch></theme-switch>

      <base-icon-button
        v-if="isImpersonated"
        :tooltip-translation="$t('tooltips.navbar_icons.stop_impersonating')"
        :icon="mdiIncognitoOff"
        color="warning"
        @click="stopImpersonating"
      ></base-icon-button>
      <base-icon-button
        v-else
        :tooltip-translation="$t('tooltips.navbar_icons.log_out')"
        :icon="mdiLogout"
        @click="logOutMixin_logout"
      ></base-icon-button>
    </v-app-bar>

    <v-main class="background">
      <v-container fluid class="pa-1 pa-md-4">
        <v-row no-gutters>
          <v-col>
            <flash-messages></flash-messages>
            <delete-dialog></delete-dialog>
            <first-login-dialog></first-login-dialog>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { APP_MENU, WIKI_URL } from "../../_config/constants";
import FlashMessages from "./components/FlashMessages";
import BasicMixin from "../mixins/BasicMixin";
import NotificationBell from "../../_functions/pages/notifications/NotificationBell";
import FirstLoginDialog from "./legal/FirstLoginDialog";
import ThemeSwitch from "./components/base/ThemeSwitch";
import LogInMixin from "../mixins/LogInMixin";
import LogOutMixin from "../mixins/LogOutMixin";
import MenuGlobalSearcher from "./menu/MenuGlobalSearcher";
import DeleteDialog from "./components/DeleteDialog";
import NavigationDrawer from "./menu/NavigationDrawer";
import BrandingMixin from "../mixins/BrandingMixin";
import BaseIconButton from "./components/base/BaseIconButton";
import {
  mdiHelpCircleOutline,
  mdiLogout,
  mdiIncognitoOff,
  mdiMenuOpen,
  mdiMagnify,
} from "@mdi/js";

export default {
  name: "AppBar",
  components: {
    BaseIconButton,
    NavigationDrawer,
    DeleteDialog,
    MenuGlobalSearcher,
    ThemeSwitch,
    FirstLoginDialog,
    NotificationBell,
    FlashMessages,
  },
  mixins: [BasicMixin, LogInMixin, LogOutMixin, BrandingMixin],
  data: () => ({
    shortCuts: {
      dashboard_screens: ["shift" + "b"],
      issues: ["shift" + "i"],
      persons: ["shift" + "p"],
      assets: ["shift" + "a"],
      documents: ["shift" + "d"],
      services: ["shift" + "s"],
      planning_calendar: ["shift" + "c"],
      warehouses: ["shift" + "w"],
      search: ["s"],
    },
    mini: false,
    search: false,
    renderKey: 0,
    header: "",
    wikiLink: "",
    mdiHelpCircleOutline,
    mdiLogout,
    mdiIncognitoOff,
    mdiMenuOpen,
    mdiMagnify,
  }),
  computed: {
    isImpersonated() {
      return this.$store.getters["basic_module/is_impersonated"];
    },
  },
  watch: {
    "$store.state.basic_module.impersonator"() {
      this.$store.dispatch("loadable/resetAllItems");
      this.$store.commit("app_state/set_dashboard", null);
      if (this.$route.fullPath.includes("dashboard_screens")) {
        this.$router.push("/dashboard_screens");
      }
      this.$store.dispatch("notifications/fetchItems");
      this.renderKey += 1;
    },
    "$route.path"(newPath, oldPath) {
      if (newPath === "/") this.goHome();
      else {
        const menuItemNew = newPath.split("/")[1];
        const menuItemOld = oldPath.split("/")[1];
        if (menuItemNew !== menuItemOld) this.setHeaderAndWikiLink(menuItemNew);
      }
    },
  },
  created() {
    if (this.$route.path === "/") this.goHome();
    else this.setHeaderAndWikiLink(this.$route.path.split("/")[1]);
  },
  methods: {
    stopImpersonating() {
      this.$http
        .post("users/stop_impersonating")
        .then((response) => this.logInMixin_setStore(response.body));
    },
    goHome() {
      const prefHomePage =
        this.$store.getters["basic_module/get_personalization"].home_page;

      this.goTo({ srcKey: prefHomePage || "about" });
    },
    goTo(event) {
      if (event.srcKey === "search") {
        document.getElementById("GlobalSearch").focus();
      } else if (!this.$route.path.includes(event.srcKey)) {
        this.$router.push(`/${event.srcKey}`);
      }
    },
    setHeaderAndWikiLink(menuItem) {
      const menuWrapper = APP_MENU.find((item) =>
        item.perms_name_array.includes(menuItem)
      );
      const selectedMenuItem = menuWrapper?.items.find(
        (item) => item.url === menuItem
      );

      if (selectedMenuItem) {
        this.header = `app_parts.${selectedMenuItem.url}`;
        this.wikiLink = `https://${this.$i18n.locale}.${WIKI_URL}/${selectedMenuItem.wiki_link}`;
      } else {
        this.header = "";
        this.wikiLink = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rot {
  transform: rotate(-180deg);
}
</style>
