const ActionsMixin = {
  data: () => ({
    actionsMixin_running: false,
    progressBar: {
      loaded: 0,
      total: 0,
    },
  }),
  methods: {
    actionsMixin_action(config, data) {
      this.actionsMixin_running = true;
      let requestParams = {};

      if (config.requestParams) {
        requestParams = config.requestParams;
      } else {
        requestParams = {
          dataType: "json",
          contentType: "application/json",
        };
      }

      requestParams.uploadProgress = (e) => {
        if (e.lengthComputable) {
          this.progressBar.loaded = e.loaded;
          this.progressBar.total = e.total;
        }
      };

      return this.$http[config.method](config.url, data, requestParams).then(
        (response) => {
          // success message
          if (config.message && config.message.success) {
            this.success_message(config.message.action, config.message.model);
          }

          // reloadable mixin
          if (config.reload) {
            this.$store.commit("reloading/reload", {
              reload: config.reload,
              data: response.body,
            });
          }

          // close dialog
          if (config.close_dialog) {
            // close dialog
            this.dialog = false;
          }

          // redirect
          if (config.redirect) {
            this.$router.push({ path: config.redirect(response) });
          }

          if (config.after) {
            config.after(response);
          }

          if (this.model) {
            // reload templates
            this.$store.dispatch("loadable/resetItems", this.model);
          }
          if (config.reload_loadable) {
            this.$store.dispatch("loadable/resetItems", config.reload_loadable);
          }
          this.actionsMixin_running = false;
        },
        (response) => {
          if (config.custom_error) {
            config.custom_error(response);
          } else {
            this.error_message(
              config.message.action,
              config.message.model,
              response
            );
          }
          this.actionsMixin_running = false;
        }
      );
    },
  },
};

export default ActionsMixin;
