<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    offset-y
    transition="scale-transition"
    max-width="290px"
  >
    <template #activator="{ attrs }">
      <v-text-field
        ref="time_field"
        v-model="inner_date"
        v-mask="'##:##'"
        v-bind="attrs"
        :label="label"
        :placeholder="$t('form.placeholder.time')"
        clearable
        :readonly="readonly"
        :append-icon="mdiClockOutline"
        :rules="
          rules.concat([
            (v) =>
              /(^([0-1][0-9]|2[0-3]):([0-5][0-9])$)|(^$)/.test(v || '') ||
              $t('form.error.valid'),
          ])
        "
        :disabled="disabled"
        :error-messages="errorMessages"
        validate-on-blur
        :hide-details="hideDetails"
        @click:append="open()"
        @click:clear="$emit('input', '')"
        @change="$emit('change', '')"
      ></v-text-field>
    </template>

    <v-time-picker
      v-model="inner_date_picker"
      color="primary"
      format="24hr"
      @click:minute="close()"
      @change="$emit('change', '')"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import RulesMixin from "../../../mixins/RulesMixin";
import { mask } from "vue-the-mask";
import { mdiClockOutline } from "@mdi/js";

export default {
  name: "InputTimePickerDialog",
  directives: {
    mask,
  },
  mixins: [RulesMixin],
  props: {
    value: String,
    label: String,
    hideDetails: [Boolean, String],
    readonly: Boolean,
    disabled: Boolean,
    errorMessages: Array,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    inner_date: null,
    inner_date_picker: null,
    mdiClockOutline,
  }),
  watch: {
    value: {
      handler() {
        this.inner_date = this.value;
        this.inner_date_picker = this.value;
      },
      immediate: true,
    },
    inner_date(newVar) {
      if (/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(newVar)) {
        this.$emit("input", newVar);
      } else {
        this.$emit("input", "");
      }
    },
    inner_date_picker(newVar) {
      if (/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(newVar)) {
        this.$emit("input", newVar);
      } else {
        this.$emit("input", "");
      }
    },
  },
  methods: {
    open() {
      if (this.readonly) {
        return;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.time_field.focus();
    },
  },
};
</script>

<style scoped></style>
