<template>
  <v-row no-gutters>
    <v-col
      v-show="$vuetify.breakpoint.mdAndUp || $route.path.split('/').length <= 2"
      cols="12"
      :md="viewSize"
    >
      <v-card :elevation="$vuetify.breakpoint.smAndDown ? '0' : ''">
        <v-sheet
          elevation="0"
          class="d-flex align-center justify-space-between px-4 py-2"
        >
          <v-tabs
            v-model="mode"
            height="36"
            hide-slider
            active-class="color"
            :show-arrows="false"
          >
            <!-- https://github.com/vuetifyjs/vuetify/issues/10540 see hash href hack. Probably be fixed in Vuetify 2.7 Nirvana-->
            <div v-for="item in tabByView" :key="item">
              <v-tooltip top :open-delay="500">
                <template #activator="{ on }">
                  <v-tab
                    :key="`tab_${item}`"
                    :href="'#' + item"
                    style="height: 35px; border-radius: 4px; min-width: 55px"
                    class="px-0"
                    v-on="on"
                  >
                    <v-icon>{{ getNavigationIcon(item) }}</v-icon>
                  </v-tab>
                </template>
                <span>{{ $t(`tooltips.navbar_icons.${item}`) }}</span>
              </v-tooltip>
            </div>
          </v-tabs>

          <div class="d-flex align-center">
            <div
              v-if="
                klass.operations &&
                (!klass.filter || (filterActive && $vuetify.breakpoint.mdAndUp))
              "
              class="d-flex justify-end order-0"
              style="width: 100%"
            >
              <base-icon-button
                :short-key="['o']"
                :icon="mdiPlayBoxEditOutline"
                :tooltip-translation="$t('tooltips.show_buttons.operations')"
                @click="openMultiOperations()"
                @shortkey="openMultiOperations()"
              />
            </div>
            <base-list-sort
              v-if="mode === 'card' && klass.customSort"
              :options.sync="sort"
              :sorts="klass.customSorts()"
            />
            <div
              v-if="isModel('asset') && $vuetify.breakpoint.smAndUp"
              class="order-1"
            >
              <base-icon-button
                :icon="mdiBarcodeScan"
                :tooltip-translation="$t('tooltips.show_buttons.find_by_qr')"
                @click="$refs.assets_qr_scanner.open_qr_scanner()"
              />
            </div>

            <div
              v-if="
                klass.filter && ($vuetify.breakpoint.mdAndUp || !filterActive)
              "
              class="order-2"
            >
              <base-icon-button
                :short-key="['f']"
                :tooltip-translation="$t('tooltips.show_buttons.filter')"
                :icon="filterActive ? mdiFilterCheck : mdiFilter"
                @shortkey="$refs.side_filter.openFilter()"
                @click="$refs.side_filter.openFilter()"
              />
            </div>

            <div
              v-if="filterActive && $vuetify.breakpoint.smAndDown"
              class="order-3"
            >
              <v-menu>
                <template #activator="{ on }">
                  <span>
                    <base-icon-button
                      :tooltip-translation="$t('tooltips.show_buttons.filter')"
                      :icon="mdiFilterCheck"
                      v-on="on"
                    />
                  </span>
                </template>
                <v-list>
                  <v-list-item @click="$refs.side_filter.openFilter()">
                    <v-list-item-icon>
                      <v-icon>{{ mdiFilterCog }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("action.update")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="$refs.side_filter.filterClick({})">
                    <v-list-item-icon>
                      <v-icon>{{ mdiFilterOff }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("action.cancel")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="klass.operations"
                    @click="openMultiOperations()"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ mdiPlayBoxEditOutline }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("form.label.operations")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div
              v-if="
                klass.createNewObject &&
                basicMixin_canCreate() &&
                $vuetify.breakpoint.smAndUp
              "
              class="order-3"
            >
              <component
                :is="
                  $vuetify.breakpoint.lgAndUp && !filterActive
                    ? 'base-text-button'
                    : 'base-icon-button'
                "
                :tooltip-translation="
                  $t(`${klass.model}.alert.new`) +
                  ' ' +
                  $t(`${klass.model}.${klass.model}`).replace(
                    /^[A-Z](?![A-Z])/,
                    (m) => m.toLowerCase()
                  ) +
                  ' ( C )'
                "
                :short-key="['c']"
                :icon="mdiPlusCircle"
                :button-text="$t('action.create')"
                color="success"
                @shortkey="openCreateDialog()"
                @click="openCreateDialog()"
              ></component>
            </div>
          </div>
        </v-sheet>

        <v-sheet
          v-if="$vuetify.breakpoint.xsOnly"
          elevation="0"
          class="d-flex align-center justify-center px-4 py-2 fixed"
        >
          <div v-if="isModel('asset')" class="order-0">
            <base-text-button
              :icon="mdiBarcodeScan"
              :button-text="$t('generic.scan')"
              :tooltip-translation="$t('tooltips.show_buttons.find_by_qr')"
              @click="$refs.assets_qr_scanner.open_qr_scanner()"
            />
          </div>
          <div
            v-if="klass.createNewObject && basicMixin_canCreate()"
            class="order-3"
          >
            <base-text-button
              :key="`create_new_object_${klass.model}`"
              :tooltip-translation="
                $t(`${klass.model}.alert.new`) +
                ' ' +
                $t(`${klass.model}.${klass.model}`).replace(
                  /^[A-Z](?![A-Z])/,
                  (m) => m.toLowerCase()
                ) +
                ' ( C )'
              "
              :short-key="['c']"
              :icon="mdiPlusCircle"
              :button-text="$t('action.create')"
              color="success"
              @shortkey="openCreateDialog()"
              @click="openCreateDialog()"
            />
          </div>
        </v-sheet>
        <component
          :is="klass.editDialogComponent"
          ref="edit_dialog"
          :key="`edit_dialog_${klass.model}`"
          :model="klass.model"
          :no-redirect="!klass.show"
          @on-success="onSuccess($event)"
        />

        <side-filter
          v-if="klass.filter"
          ref="side_filter"
          :mode.sync="mode"
          :klass="klass"
          :prev-path="prevPath"
        ></side-filter>
        <v-divider class="mx-4"></v-divider>
        <v-card-text id="BaseCardText" class="card-full-height-switch px-0">
          <v-tabs-items v-model="mode" touchless class="px-4">
            <v-tab-item
              v-for="tabItem in tabByView"
              :key="tabItem"
              :value="tabItem"
            >
              <div v-if="tabItem === 'card'">
                <base-empty-data-alert
                  v-if="totalObjects === 0 && !loading"
                  :name="$t(`${klass.model}.${modelPluralize(klass.model)}`)"
                />
                <div v-for="object in objects" :key="object.id">
                  <component
                    :is="klass.cardComponent"
                    :klass="klass"
                    :item="object"
                    :delete-item="deleteItem"
                    :edit-item="editItem"
                    :move-item="moveItem"
                    :now="now"
                    :total-items="objects.length"
                    :to="itemPath(object.id)"
                    @update-item="updateItem(object, $event)"
                  ></component>
                </div>
              </div>

              <base-datatable
                v-if="tabItem === 'list'"
                :loading="loading"
                :klass="klass"
                :objects="objects"
                :options.sync="sort"
                :selected-item="selectedItem"
                :delete-item="deleteItem"
                :edit-item="editItem"
                :move-item="moveItem"
                :now="now"
                @update:selected-item="visitItem($event[0]?.id)"
              ></base-datatable>

              <div v-if="tabItem === 'tree'">
                <categories-tree-view
                  v-if="isModel('asset_category', 'person_category')"
                  :klass="klass"
                />
                <person-assets-tree-view
                  v-else
                  :model="klass.model"
                  :tab-item="mode"
                  @selected-item="visitItem($event.id)"
                ></person-assets-tree-view>
              </div>

              <data-table-map
                v-if="tabItem === 'map'"
                :klass="klass"
                :tab-item="mode"
                :reloading-models="[
                  'asset',
                  'issue',
                  'location',
                  'assets_issue',
                  'issue_assignee',
                ]"
                @selected-item="visitItem($event.id)"
              />
              <base-calendar
                v-if="tabItem === 'cal'"
                :klass="klass"
                :tab-item="mode"
                @selected-item="visitItem($event.id)"
              ></base-calendar>
            </v-tab-item>
          </v-tabs-items>
          <div
            v-if="loading === false && (mode === 'card' || mode === 'list')"
            class="text-center"
          >
            <base-text-button
              v-if="objects.length < totalObjects"
              :button-text="$t('issue_note.label.loadMore')"
              @click="loadMore"
            />
            <div class="text-caption text--secondary mt-1">
              {{ $t("generic.total_count") }}: {{ $n(totalObjects) }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <assets-qr-scanner ref="assets_qr_scanner" @input="visitItem($event.id)" />

    <v-col :md="showSize" :class="{ 'pl-md-4': viewSize < 12 }">
      <v-slide-x-reverse-transition>
        <base-empty-selection-show
          :model="klass.model"
          :hide-message="!klass.show"
        />
      </v-slide-x-reverse-transition>

      <v-slide-x-reverse-transition hide-on-leave>
        <router-view
          :objects-count="totalObjects"
          @on-success="onSuccess($event)"
        />
      </v-slide-x-reverse-transition>
    </v-col>
  </v-row>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import BaseEmptySelectionShow from "../../../../_generic/pages/components/base/BaseEmptySelectionShow";
import PersonAssetsTreeView from "../../../../_functions/pages/persons/components/PersonAssetsTreeView";
import SideFilter from "../../../../_functions/pages/data_sets/filter/SideFilter";
import BaseCardComponent from "./BaseCardComponent";
import NotificationCard from "../../../../_functions/pages/notifications/NotificationCard";
import DataTableMap from "../../../../_functions/pages/components/data_table/DataTableMap";
import CategoriesTreeView from "../../../../_functions/pages/categories/components/CategoriesTreeView";
import IssueCard from "../../../../_functions/pages/issues/IssueCard";
import BaseListSort from "../../../../_functions/pages/components/BaseListSort";
import ReloadableMixin from "../../../mixins/ReloadableMixin";
import AssetsQrScanner from "../../../../_functions/pages/components/searcher/AssetsQrScanner";
import AllEditDialogMixin from "../../../../_functions/mixins/AllEditDialogMixin";
import IssueChangeState from "../../../../_functions/pages/issues/components/IssueChangeState.vue";
import BaseEmptyDataAlert from "./BaseEmptyDataAlert";
import BaseDatatable from "./BaseDatatable";
import BaseCalendar from "./BaseCalendar";
import BaseIconButton from "./BaseIconButton";
import BaseTextButton from "./BaseTextButton";
import {
  mdiCardText,
  mdiEarth,
  mdiFileTree,
  mdiViewList,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiPencil,
  mdiDelete,
  mdiChevronDown,
  mdiFilter,
  mdiPlusCircle,
  mdiBarcodeScan,
  mdiArrowDownBold,
  mdiArrowUpBold,
  mdiCheckCircle,
  mdiPlay,
  mdiEmail,
  mdiCellphone,
  mdiCalendar,
  mdiFileExport,
  mdiTable,
  mdiFilterCheck,
  mdiPlayBoxEditOutline,
  mdiFilterOff,
  mdiFilterCog,
} from "@mdi/js";
export default {
  name: "BaseView",
  components: {
    BaseCalendar,
    BaseDatatable,
    DataTableMap,
    SideFilter,
    PersonAssetsTreeView,
    BaseEmptySelectionShow,
    BaseCardComponent,
    NotificationCard,
    CategoriesTreeView,
    IssueCard,
    BaseListSort,
    BaseEmptyDataAlert,
    AssetsQrScanner,
    BaseIconButton,
    BaseTextButton,
    IssueChangeState,
  },
  mixins: [BasicMixin, ReloadableMixin, AllEditDialogMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPath = from.path;
    });
  },
  props: {
    klass: Function,
  },
  data() {
    return {
      mode: null,
      prevPath: null,
      now: new Date().toISOString(),
      objects: [],
      totalObjects: 0,
      changePage: false,
      loading: false,
      fetchRdy: true,
      loadMoreAfterUpdateObjects: false,
      selectedItem: [],
      page: 1,
      itemsPerPage: this.$store.state.basic_module.personalization.pagination,
      sort: { sortBy: [], sortDesc: [] },
      mdiCardText,
      mdiEarth,
      mdiFileTree,
      mdiViewList,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
      mdiPencil,
      mdiDelete,
      mdiChevronDown,
      mdiFilter,
      mdiPlusCircle,
      mdiBarcodeScan,
      mdiArrowDownBold,
      mdiArrowUpBold,
      mdiCheckCircle,
      mdiPlay,
      mdiEmail,
      mdiCellphone,
      mdiCalendar,
      mdiFileExport,
      mdiTable,
      mdiFilterCheck,
      mdiPlayBoxEditOutline,
      mdiFilterOff,
      mdiFilterCog,
    };
  },
  computed: {
    filterActive() {
      return Object.keys(this.$route.query).length > 0;
    },
    reloadingModels() {
      return this.klass.show ? ["multi_operation"] : [this.klass.model];
    },
    tabByView() {
      return this.klass.bottomNav[
        `${this.$vuetify.breakpoint.smAndDown ? "mobile" : "desktop"}`
      ];
    },
    viewSize() {
      // IDEA: Uncomment to allow fullscreen map (Import button not handled)
      // if (this.mode === "map" && !this.$route.params.id) return 12;

      return this.$route.path.includes("operations") &&
        this.klass.showSize === 0
        ? 7
        : 12 - this.klass.showSize;
    },
    showSize() {
      return 12 - this.viewSize;
    },
  },
  watch: {
    tabByView: {
      handler() {
        this.loadSelectedView();
      },
      immediate: true,
    },
    mode() {
      this.saveSelectedView();
    },
    "$route.params.id"() {
      this.reselectItem();
    },
    "$store.state.deleting.item"() {
      if (
        this.$store.state.deleting.item !== null &&
        this.klass.model === this.$store.state.deleting.item.model
      ) {
        this.onSuccess(this.$store.state.deleting.item);
      }
    },
    page() {
      //this trigger only when load more is clicked
      if (this.changePage) {
        this.fetchData();
        this.changePage = false;
      }
    },
    sort: {
      handler() {
        this.resetData();
        this.setSortsToStore();
        this.fetchData();
      },
      deep: true,
    },
    "$route.fullPath"(newVar, oldVar) {
      // fix triggering fetchData when query not changes
      const oldModel = oldVar.split("/")[1].split("?")[0];
      const newModel = newVar.split("/")[1].split("?")[0];
      const oldQuery = oldVar.split("?")[1];
      const newQuery = newVar.split("?")[1];
      if (oldQuery !== newQuery && oldModel === newModel) {
        this.resetData();
        this.fetchData();
      } else if (oldModel !== newModel) {
        this.$store.commit("reloading/reload", { reload: "", data: {} });
        this.$store.commit("app_state/set_current_object", null);
        this.fetchRdy = true;
        this.getSortsFromStore();
      }
    },
  },
  mounted() {
    this.getSortsFromStore();
    this.loadSelectedView();
  },
  methods: {
    onSuccess(data) {
      const item = this.objects.find((i) => i.id === data.id);
      const deleteItem = this.$store.state.deleting.item;
      if (item) {
        const index = this.objects.indexOf(item);
        //delete
        if (
          deleteItem &&
          deleteItem.id === item.id &&
          deleteItem.model === this.klass.model
        ) {
          this.objects.splice(index, 1);
          this.totalObjects--;
          this.$store.commit("deleting/remove_item");
        } else {
          // update
          if (this.klass.model === "issue") {
            const hidden_states = this.$store.getters["loadable/get_list"](
              "issue_state"
            )
              .filter((i) => i.hidden === true)
              .map((j) => j.id);
            if (hidden_states.includes(data.issue_state_id)) {
              this.objects.splice(index, 1);
              this.totalObjects--;
              this.loadMoreAfterUpdateObjects = true;
              return;
            }
          }
          this.$set(this.objects, index, data);
        }
      } else {
        // create
        if (this.klass.show) {
          this.objects.unshift(data);
          this.totalObjects++;
        }
      }
      this.loadMoreAfterUpdateObjects = true;
    },
    saveSelectedView() {
      this.$store.commit("app_state/set_tree_mode", {
        model: this.klass.model,
        mode: this.mode,
      });
      this.$emit("input", this.mode);
    },
    loadMore() {
      this.changePage = true;
      const pagination =
        this.$store.state.basic_module.personalization.pagination;
      if (this.loadMoreAfterUpdateObjects) {
        this.itemsPerPage =
          (Math.ceil(this.objects.length / pagination) + 1) * pagination;
        this.resetData();
        this.fetchData();
        this.changePage = false;
      } else {
        this.itemsPerPage = pagination;
        this.page = this.objects.length / pagination + 1;
      }
    },
    loadSelectedView() {
      if (Object.keys(this.$route.query).length > 0) {
        this.mode =
          this.$vuetify.breakpoint.smAndDown || this.isModel("issue")
            ? "card"
            : "list";
      } else {
        this.mode =
          this.$store.state.app_state.mode[this.klass.model] ||
          this.tabByView[0];
      }
    },
    fetchData() {
      if (this.fetchRdy) {
        this.loading = true;
        this.fetchRdy = false;
        const requestModel = this.klass.model;

        this.$http
          .get(this.modelPluralize(this.klass.model), {
            params: {
              page: this.page,
              per_page: this.itemsPerPage,
              sort_by: this.sort.sortBy,
              sort_desc: this.sort.sortDesc,
              filter: this.$route.query,
            },
          })
          .then(
            (response) => {
              if (requestModel !== this.klass.model) return;
              this.objects = this.objects.concat(response.body.objects);
              this.reselectItem();
              this.totalObjects = response.body.total;
              this.loading = false;
            },
            (response) => {
              this.error_message("load", this.klass.model, response);
            }
          )
          .then(() => {
            this.fetchRdy = true;
            this.loadMoreAfterUpdateObjects = false;
          });
      }
    },
    reloadComponent() {
      this.resetData();
      this.fetchData();
    },
    resetData() {
      this.objects = [];
      this.totalObjects = 0;
      this.page = 1;
    },
    getNavigationIcon(name) {
      switch (name) {
        case "card":
          return mdiCardText;
        case "list":
          return mdiViewList;
        case "tree":
          return mdiFileTree;
        case "map":
          return mdiEarth;
        case "cal":
          return mdiCalendar;
      }
    },
    openCreateDialog() {
      if (this.$refs.edit_dialog.dialog === false) {
        let newObject = this.klass.createNewObject.object;
        this.$refs.edit_dialog.openDialog(
          newObject,
          this.klass.createNewObject.params,
          false
        );
      }
    },
    updateItem(item, data) {
      const matchItem = this.objects.find((i) => i.id === item.id);
      const keys = Object.keys(data);
      for (const key of keys) {
        matchItem[key] = data[key];
      }
      if (this.isModel("notification")) {
        this.$store.dispatch("notifications/fetchItems");
      }
    },
    editItem(item) {
      this.$refs.edit_dialog.openDialog(item, {}, true);
    },
    deleteItem(item) {
      const params = {
        id: item.id,
        name: this.getTranslation(item.name),
        model: this.klass.model,
      };

      this.$store.commit("deleting/add_action", params);
    },
    moveItem(item, direction) {
      const config = {
        method: "post",
        message: {
          success: true,
          action: "update",
          model: this.klass.model,
        },
        url: `${this.modelPluralize(this.klass.model)}/${item.id}/move`,
        reload: this.klass.model,
        close_dialog: true,
      };
      this.actionsMixin_action(config, { direction: direction });
    },
    setSortsToStore() {
      this.$store.commit("app_state/set_sortBy", {
        model: this.klass.model,
        data: this.sort.sortBy,
      });

      this.$store.commit("app_state/set_sortDesc", {
        model: this.klass.model,
        data: this.sort.sortDesc,
      });
    },
    getSortsFromStore() {
      this.sort.sortBy =
        this.$store.state.app_state.options.sortBy[this.klass.model] || [];
      this.sort.sortDesc =
        this.$store.state.app_state.options.sortDesc[this.klass.model] || [];
    },
    itemPath(id) {
      const path = `/${this.modelPluralize(this.klass.model)}/${id}`;
      return {
        path: path,
        query: this.$route.query,
      };
    },
    visitItem(id) {
      if (!id) return;

      const path = `/${this.modelPluralize(this.klass.model)}/${id}`;
      if (this.$route.path !== path) {
        this.$router.push({
          path: path,
          query: this.$route.query,
        });
      }
    },
    reselectItem() {
      const id = parseInt(this.$route.params.id);
      this.selectedItem = [this.objects.find((item) => item.id === id)];
    },
    openMultiOperations() {
      if (
        this.$route.path !==
        `/${this.modelPluralize(this.klass.model)}/operations`
      )
        this.$router.push({
          path: `/${this.modelPluralize(this.klass.model)}/operations`,
          query: this.$route.query,
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
}
.color {
  background-color: var(--v-primary-base);
  color: var(--v-secondary-base);
}
</style>
