<template>
  <v-dialog
    v-model="dialog"
    max-width="548"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :label="$t('tooltips.tabs_icons.preview')"
      />
      <v-card-text class="pa-6">
        <document-preview
          :id="id"
          :content-type="contentType"
          :public="publicDocument"
        ></document-preview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseVCardTitleDialogIntern from "./card/BaseVCardTitleDialogIntern";
import DocumentPreview from "../../../../_functions/pages/documents/components/DocumentPreview";
export default {
  name: "BasePreviewDialog",
  components: {
    BaseVCardTitleDialogIntern,
    DocumentPreview,
  },
  props: {
    publicDocument: Boolean,
  },
  data() {
    return {
      id: null,
      contentType: null,
      dialog: false,
    };
  },
  methods: {
    openDialog(id, contentType) {
      this.id = id;
      this.contentType = contentType;
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
