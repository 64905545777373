<template>
  <div :class="{ 'px-16': gallery }">
    <div v-if="txtTypes.includes(contentType)">
      <pre class="pre-display">{{ txt }}</pre>
    </div>

    <div
      v-else-if="['audio/mpeg', 'audio/x-wav'].includes(contentType)"
      class="text-center"
      :class="{ 'pt-2': gallery }"
    >
      <audio controls preload="metadata" style="width: 100%">
        <source :src="`${baseUrl}stream`" :type="contentType" />
      </audio>
    </div>

    <div
      v-else-if="['video/mp4'].includes(contentType)"
      class="text-center"
      :class="{ 'pt-2': gallery }"
    >
      <video controls preload="metadata" style="width: 100%">
        <source :src="`${baseUrl}stream`" :type="contentType" />
      </video>
    </div>

    <div
      v-else-if="
        [
          'image',
          'image/bmp',
          'image/gif',
          'image/jpeg',
          'image/png',
          'image/tiff',
          'image/webp',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(contentType)
      "
    >
      <v-img
        :key="id"
        class="rounded mx-auto"
        contain
        :src="`${baseUrl}preview`"
        min-height="200"
        max-height="500"
        max-width="500"
      >
        <template #placeholder>
          <v-skeleton-loader type="image" />
        </template>
      </v-img>
    </div>

    <div v-else>
      <v-alert type="info" outlined text class="mb-0">
        {{ $t("document.label.noPreview") }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";

export default {
  name: "DocumentPreview",
  mixins: [BasicMixin],
  props: {
    id: [Number, String],
    contentType: String,
    gallery: Boolean,
    public: Boolean,
  },
  data: () => ({
    txt: "",
    txtTypes: [
      "text/plain",
      "text/xml",
      "text/csv",
      "text/markdown",
      "application/xml",
      "application/json",
    ],
  }),
  computed: {
    idAndContentType() {
      return { id: this.id, contentType: this.contentType };
    },
    baseUrl() {
      return `/${this.public ? "public_" : ""}documents/${this.id}/`;
    },
  },
  watch: {
    idAndContentType: {
      handler(val) {
        if (this.txtTypes.includes(val.contentType)) {
          this.getTxtData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getTxtData() {
      fetch(`${this.baseUrl}download`)
        .then((response) => response.text())
        .then((data) => (this.txt = data));
    },
  },
};
</script>

<style scoped lang="scss">
.pre-display {
  white-space: pre-wrap;
}
</style>
