<template>
  <div>
    <v-treeview
      v-if="visible"
      ref="tree"
      transition
      hoverable
      dense
      :items="tree"
      :open.sync="isOpen"
      :load-children="fetchChildren"
      :activatable="!permissions && !multiselect"
      :active.sync="active"
      selection-type="independent"
      :class="{ 'clickable-tree-node': !permissions && !multiselect }"
      @update:active="selectTreeItem($event)"
    >
      <template #prepend="{ item, open }">
        <v-checkbox
          v-if="multiselect && (!filter || !item.disabled)"
          v-model="$store.state.app_state.selected_objects"
          class="inline-checkbox"
          hide-details
          :value="returnObject ? item : item.id"
          :value-comparator="checkboxComparator"
        ></v-checkbox>

        <v-icon v-if="model !== 'document'" :id="`node${item.id}`">{{
          categoryIcons[item.category.icon].icon
        }}</v-icon>
        <v-icon v-else :id="`node${item.id}`">
          {{
            item.folder ? (open ? mdiFolderOpen : mdiFolder) : mdiFileDocument
          }}
        </v-icon>
      </template>

      <template #append="{ item, open }">
        <v-tooltip v-if="!permissions && item.user" bottom>
          <template #activator="{ on }">
            <v-chip color="primary" text-color="white" small v-on="on">
              <v-icon small>
                {{ item.user.blocked ? mdiAccountCancel : mdiAccount, }}
              </v-icon>
            </v-chip>
          </template>
          <span>
            {{ item.user.email }}
          </span>
        </v-tooltip>

        <v-tooltip
          v-if="
            item.children_count !== 0 &&
            (!permissions || !permissions_h.permissions)
          "
          bottom
        >
          <template #activator="{ on }">
            <v-chip color="primary" text-color="white" small v-on="on">
              <span class="font-weight-bold"
                >{{ open ? loadedChildren(item) : null
                }}{{ item.children_count }}</span
              >
            </v-chip>
          </template>
          <span>
            {{ $t("other.tags.children_count") }}
          </span>
        </v-tooltip>

        <div
          v-if="permissions && permissions_h.permissions"
          :style="[
            $vuetify.breakpoint.xs
              ? { width: '160px', 'text-align': 'right' }
              : {},
          ]"
        >
          <permission-setter
            v-for="(h, index) in permissionsHeaders"
            :key="index"
            :header="h"
            :permission="permissions_h.permissions[item.id]"
            :setup="permissions_h.setup"
            :permission-type="model"
            clazz="mx-1 mx-sm-2"
            :disabled="disabled"
            @reload_permissions="reload_permissions()"
          ></permission-setter>

          <base-icon-button
            v-if="permissionsOwner.type === 'person'"
            :icon="mdiMagnify"
            :tooltip-translation="$t('permission.label.detail')"
            @click="$refs.tooltip_dialog.openDialog(item.id)"
          ></base-icon-button>

          <base-icon-button
            :icon="mdiDelete"
            color="error"
            :tooltip-translation="$t('tooltips.tabs_icons.delete')"
            :tooltip-disabled-translation="
              $t('tooltips.show_buttons.disabled_buttons.generic')
            "
            :disabled="disabled"
            @click="reset_permission(item.id)"
          ></base-icon-button>
        </div>
      </template>
    </v-treeview>

    <base-loading-progress v-else></base-loading-progress>

    <permissions-tooltip-dialog
      v-if="permissions && permissionsOwner.type === 'person'"
      ref="tooltip_dialog"
      :headers-key="model"
      :owner-type="permissionsOwner.type"
      :owner-id="permissionsOwner.id"
    ></permissions-tooltip-dialog>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import BaseLoadingProgress from "../../../../_generic/pages/components/base/BaseLoadingProgress";
import PermissionResetMixin from "../../../mixins/PermissionsResetMixin";
import ReloadableMixin from "../../../../_generic/mixins/ReloadableMixin";
import BaseIconButton from "../../../../_generic/pages/components/base/BaseIconButton";
import PermissionsTooltipDialog from "../../permissions/components/PermissionsTooltipDialog";
import PermissionSetter from "../../permissions/components/PermissionSetter";
import Category from "../../../../_generic/classes/Category";
import {
  mdiDelete,
  mdiMagnify,
  mdiFolder,
  mdiFolderOpen,
  mdiFileDocument,
  mdiAccount,
  mdiAccountCancel,
} from "@mdi/js";

export default {
  name: "PersonAssetsTreeView",
  components: {
    BaseIconButton,
    PermissionsTooltipDialog,
    BaseLoadingProgress,
    PermissionSetter,
  },
  mixins: [BasicMixin, PermissionResetMixin, ReloadableMixin],
  props: {
    model: String,
    selectable: Boolean,
    returnObject: Boolean,
    permissions: Boolean,
    permissionsOwner: Object,
    permissionsHeaders: Array,
    multiselect: Boolean,
    tabItem: String,
    filter: Object,
    disabled: Boolean,
  },
  data: () => ({
    tree: [],
    isOpen: [],
    active: [],
    categoryIcons: Category.categoryIcons,
    visible: false,
    permissions_h: {},
    mdiDelete,
    mdiMagnify,
    mdiFolder,
    mdiFolderOpen,
    mdiFileDocument,
    mdiAccount,
    mdiAccountCancel,
  }),
  watch: {
    tabItem() {
      if (this.tabItem === "tree" && this.visible) {
        const id = this.$route.params.id;
        const intId = parseInt(id);
        if (id && !this.active.includes(intId)) {
          const found = this.findInTree(intId);
          if (found) this.openTreeTo(found.parents, intId, true);
          else this.fetchData(intId);
        }
      }
    },
    "$route.params.id"(id) {
      if (!this.permissions && !this.selectable && !this.multiselect) {
        if (this.tabItem === "tree") {
          const intId = parseInt(id);
          if (id) {
            if (!this.active.includes(intId)) {
              const found = this.findInTree(intId);
              if (found) this.openTreeTo(found.parents, intId, true);
              else this.fetchData(intId);
            }
          } else this.active = [];
        }
      }
    },
    active() {
      if (this.selectable && this.active.length > 0 && this.active[0]) {
        const selected = this.returnObject
          ? this.findInTree(this.active[0]).object
          : this.active[0];
        this.$emit("selected", selected);
      }
    },
    model() {
      this.tree = [];
      this.isOpen = [];
      if (!this.permissions && !this.selectable && !this.multiselect) {
        this.fetchData(this.$route.params.id, false);
      } else {
        this.fetchData(undefined, false);
      }
    },
    "permissionsOwner.id"() {
      this.fetchData();
    },
  },
  mounted() {
    if (this.permissions || this.selectable || this.multiselect) {
      this.fetchData();
    } else {
      this.fetchData(this.$route.params.id);
    }
  },
  methods: {
    selectTreeItem(e) {
      if (e.length <= 0) return;

      const found = this.findInTree(e[0]);
      this.$emit("selected-item", found?.object);
    },
    scrollToItem() {
      const el = document.getElementById("BaseCardText");
      setTimeout(() => {
        this.$vuetify.goTo(`#node${this.$route.params.id}`, {
          container: el,
          offset: 350,
        });
      }, 1000);
    },
    openTreeTo(parents, id, activate) {
      this.isOpen = parents;
      if (id && activate) {
        this.active = [id];
        this.scrollToItem();
      }
    },
    findInTree(id) {
      const parents = [];
      const searchTree = (array, id) => {
        for (const item of array) {
          if (item.id === id) {
            return item;
          } else if (item.children) {
            parents.push(item.id);
            const result = searchTree(item.children, id);
            if (result) return result;
          }
        }
        parents.pop();
      };

      const found = searchTree(this.tree, id);
      if (found) return { object: found, parents: parents };
    },
    fetchData(id, merge = true) {
      const intId = parseInt(id);
      const mergeTree = (array, newArray) => {
        for (let i = 0; i < newArray.length; i++) {
          const element = newArray[i];
          let item = array.find((n) => n.id === element.id);
          if (item) {
            if (!element.children && item.children) {
              delete item.children;
            } else if (element.children && item.children) {
              mergeTree(item.children, element.children);
            }
            item = { ...item, ...element };
          } else {
            array.length === i ? array.push(element) : array.unshift(element);
          }
        }
      };

      if (this.permissions) this.visible = false;
      this.$http
        .get(`${this.modelPluralize(this.model)}/tree`, {
          params: {
            to_id: id,
            limit: 1000,
            permissions: this.permissions,
            permissions_owner: this.permissionsOwner,
            filter: this.filter,
          },
        })
        .then(
          (response) => {
            if (this.permissions || !merge) {
              this.tree = response.body.objects;
              // Fix from https://github.com/vuetifyjs/vuetify/issues/10587
              if (this.visible) {
                let nodes = this.$refs.tree.nodes;
                for (const nodeKey in nodes) {
                  if (nodes[nodeKey].vnode) {
                    nodes[nodeKey].vnode.hasLoaded = false;
                  }
                }
              }
            } else {
              mergeTree(this.tree, response.body.objects);
            }
            this.permissions_h = response.body.permissions_h;
            this.visible = true;
            if (this.tabItem === "tree") {
              const found = this.findInTree(intId);
              if (found) this.openTreeTo(found.parents, intId, true);
            }
          },
          () => {
            this.messageCustom("messages.treeLoadError", "error");
          }
        )
        .then(() => {
          this.isOpen.push(this.tree.find((i) => !i.parent_id).id);
        });
    },
    fetchChildren(item) {
      return this.$http
        .get(`${this.modelPluralize(this.model)}/tree`, {
          params: {
            from_id: item.id,
            limit: 1000,
            permissions: this.permissions,
            permissions_owner: this.permissionsOwner,
            filter: this.filter,
          },
        })
        .then(
          (response) => {
            item.children = response.body.objects;

            if (this.permissions) {
              // merge permissions
              const resultPermissionHash = response.body.permissions_h;
              for (const key in resultPermissionHash.permissions) {
                this.permissions_h.permissions[key] =
                  resultPermissionHash.permissions[key];
              }

              // merge inheritance
              for (const header of this.permissionsHeaders) {
                this.permissions_h.setup[header.type].allowed =
                  this.permissions_h.setup[header.type].allowed.concat(
                    resultPermissionHash.setup[header.type].allowed
                  );

                this.permissions_h.setup[header.type].denied =
                  this.permissions_h.setup[header.type].denied.concat(
                    resultPermissionHash.setup[header.type].denied
                  );
              }
            }
          },
          () => {
            this.messageCustom("messages.treeLoadError", "error");
          }
        );
    },
    reload_permissions() {
      if (!this.permissions) return;

      const ids = [];
      for (const k in this.permissions_h.permissions) {
        ids.push(this.permissions_h.permissions[k].entity_id);
      }

      this.$http
        .get(`permissions/reload_hash`, {
          params: {
            ids: ids,
            entity_type: this.model,
            permissions_owner: this.permissionsOwner,
          },
        })
        .then(
          (response) => {
            this.permissions_h = response.body.permissions_h;
          },
          () => {
            this.messageCustom("messages.treeLoadError", "error");
          }
        );
    },
    loadedChildren(item) {
      if (item.children.length < item.children_count) {
        return `${item.children.length} / `;
      }
    },
    reloadComponent() {
      // reload tree based on action
      const response = this.$store.state.reloading.data;
      switch (response.action) {
        case "archive":
          this.isOpen = [];
          this.fetchData(response.object.parent_id, false);
          break;
        case "create":
        case "update":
        case "unarchive":
          this.isOpen = [];
          this.fetchData(response.object.id, false);
          break;
        case "change_parent":
          this.isOpen = [];
          this.fetchData(response.params.parent_id, false);
          break;
        case "archive_multiple":
          this.isOpen = [];
          this.fetchData(undefined, false);
          break;
      }
    },
    checkboxComparator(a, b) {
      return this.returnObject ? a.id === b.id : a === b;
    },
  },
};
</script>

<style scoped></style>
