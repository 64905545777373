const NotesMixin = {
  computed: {
    notesMixin_noteTypes() {
      if (this.$store.state.basic_module.permissions.private_notes) {
        return ["internal", "public", "private"];
      } else {
        return ["internal", "public"];
      }
    },
  },
};

export default NotesMixin;
