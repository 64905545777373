<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="300"
      nudge-right="30"
    >
      <v-card v-if="applySnippets">
        <v-card-text>
          <base-searcher
            v-if="['@', '$', '#', '%'].includes(pressed)"
            ref="searcher"
            :type="mentions.model"
            :label="$t(`${mentions.model}.${mentions.model}`)"
            autofocus
            @input="runMention($event)"
          />

          <base-loadable-autocomplete
            v-if="['*'].includes(pressed)"
            v-model="snippetKey"
            :label="$t(`snippet.snippets`)"
            model="snippet"
            autofocus
            :on-screen="applySnippets"
            @input="runSnippets($event)"
          />
        </v-card-text>
      </v-card>
      <template #activator="{}">
        <v-textarea
          ref="note_text_area"
          :label="label"
          :hint="hint"
          :value="value"
          :rules="rules"
          auto-grow
          rows="2"
          @input.native="onInput($event)"
        />
      </template>
    </v-menu>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import BaseSearcher from "./BaseSearcher";
import BaseLoadableAutocomplete from "../../../../_generic/pages/components/base/BaseLoadableAutocomplete";

export default {
  name: "TextAreaWithMenu",
  components: { BaseLoadableAutocomplete, BaseSearcher },
  mixins: [BasicMixin],
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    applySnippets: Boolean,
  },
  data: () => ({
    menu: false,
    event: null,
    pressed: null,
    snippetKey: null,
    mentions: {
      model: "person",
    },
  }),
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
      if (!this.applySnippets) return;

      // apply snippets
      setTimeout(() => {
        const selStart = event.target.selectionStart;
        const inputType = event.inputType;
        if (inputType === "insertText" && selStart > 0) {
          const lastChar = event.target.value[selStart - 1];
          switch (lastChar) {
            case "@":
              this.menu = true;
              this.pressed = "@";
              this.event = event;
              this.mentions.model = "person";
              break;
            case "$":
              this.menu = true;
              this.pressed = "$";
              this.event = event;
              this.mentions.model = "asset";
              break;
            case "%":
              this.menu = true;
              this.pressed = "%";
              this.event = event;
              this.mentions.model = "document";
              break;
            case "#":
              this.menu = true;
              this.pressed = "#";
              this.event = event;
              this.mentions.model = "issue";
              break;
            case "*":
              this.menu = true;
              this.pressed = "*";
              this.event = event;
              break;
          }
        }
      }, 500);
    },
    runMention(event) {
      // insert mention

      const originalSelectionStart = this.event.target.selectionStart;
      const textStart = this.value.slice(0, originalSelectionStart);
      const textEnd = this.value.slice(originalSelectionStart);
      this.$emit("input", `${textStart}${event}${textEnd}`);
      this.menu = false;
      this.$refs.note_text_area.focus();
      this.pressed = null;
    },
    runSnippets(event) {
      // insert snippet

      const snip = this.$store.getters["loadable/get_list"]("snippet").find(
        (snippet) => {
          return snippet.id === event;
        }
      );
      const originalSelectionStart = this.event.target.selectionStart;
      const textStart = this.value.slice(0, originalSelectionStart - 1);
      const textEnd = this.value.slice(originalSelectionStart);
      this.$emit("input", `${textStart}${snip.value}${textEnd}`);
      this.menu = false;
      this.$refs.note_text_area.focus();
      this.$nextTick(() => {
        this.snippetKey = null;
      });
    },
  },
};
</script>

<style scoped></style>
