<template>
  <div class="d-inline">
    <v-tooltip v-if="innerObject.discarded_at" bottom :open-delay="500">
      <template #activator="{ on }">
        <v-chip
          :class="{ 'mb-1': bottomMargin, 'mt-1': topMargin }"
          color="red"
          text-color="white"
          small
          v-on="on"
        >
          <v-icon small>{{ mdiPackageDown }}</v-icon>
        </v-chip>
      </template>
      <span>
        {{ capitalize($t(`${model}.alert.archive`)) }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="
        (innerObject.valid_to && new Date(innerObject.valid_to) < new Date()) ||
        (innerObject.effective_to &&
          new Date(innerObject.effective_to) < new Date())
      "
      :open-delay="500"
      bottom
    >
      <template #activator="{ on }">
        <v-chip
          :class="{ 'mb-1': bottomMargin, 'mt-1': topMargin }"
          color="warning"
          text-color="white"
          small
          v-on="on"
        >
          <v-icon small>{{ mdiClockOutline }}</v-icon>
        </v-chip>
      </template>
      <span>
        {{ capitalize($t(`${model}.alert.expired`)) }}
      </span>
    </v-tooltip>

    <v-tooltip v-if="innerObject.user" bottom :open-delay="500">
      <template #activator="{ on }">
        <v-chip
          :class="{ 'mb-1': bottomMargin, 'mt-1': topMargin }"
          color="primary"
          text-color="white"
          small
          v-on="on"
        >
          <v-icon small>
            {{ innerObject.user.blocked ? mdiAccountCancel : mdiAccount }}
          </v-icon>
        </v-chip>
      </template>
      <span>
        {{ innerObject.user.email }}
      </span>
    </v-tooltip>

    <v-tooltip v-if="innerObject.subscribed" bottom :open-delay="500">
      <template #activator="{ on }">
        <v-chip
          :class="{ 'mb-1': bottomMargin, 'mt-1': topMargin }"
          color="primary"
          text-color="white"
          small
          v-on="on"
        >
          <v-icon small>{{ mdiRss }}</v-icon>
        </v-chip>
      </template>
      <span>
        {{ $t("other.tags.subscribe") }}
      </span>
    </v-tooltip>

    <span v-if="innerObject.tag_definitions">
      <v-tooltip
        v-for="(tag, index) in innerObject.tag_definitions"
        :key="index"
        bottom
        :open-delay="500"
      >
        <template #activator="{ on }">
          <!--        TAGS      -->
          <v-chip
            :color="tag.color"
            class="text-no-wrap"
            :style="
              closable ? { 'max-width': '225px' } : { 'max-width': '205px' }
            "
            :class="[
              {
                'mb-1': bottomMargin,
                'mt-1': topMargin,
                'ml-1': index !== 0,
              },
              textColor(tag.color),
            ]"
            small
            :close="closable"
            v-on="on"
            @click:close="closeTag(index)"
          >
            <span class="text-truncate">{{ tag.name }}</span>
          </v-chip>
        </template>
        <span>{{ tag.name }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import ColorHelper from "../../../mixins/ColorHelper";
import {
  mdiPackageDown,
  mdiClockOutline,
  mdiRss,
  mdiDelete,
  mdiAccount,
  mdiAccountCancel,
} from "@mdi/js";

export default {
  name: "BaseObjectTags",
  mixins: [BasicMixin, ColorHelper],
  props: {
    model: String,
    object: Object,
    bottomMargin: {
      type: Boolean,
      default: false,
    },
    topMargin: {
      type: Boolean,
      default: false,
    },
    closable: Boolean,
  },
  data() {
    return {
      mdiPackageDown,
      mdiClockOutline,
      mdiRss,
      mdiDelete,
      mdiAccount,
      mdiAccountCancel,
    };
  },
  computed: {
    innerObject: {
      get() {
        return this.object;
      },
      set(object) {
        this.$emit("update:object", object);
      },
    },
  },
  methods: {
    closeTag(index) {
      this.innerObject.tag_definitions.splice(index, 1);
      const config = {
        method: "patch",
        message: { success: true, action: "update", model: this.model },
        url: `${this.modelPluralize(this.model)}/${this.object.id}`,
        after: this.after,
      };
      this.actionsMixin_action(config, this.innerObject);
    },
    after(response) {
      this.$emit("on-success", response.body.object);
    },
  },
};
</script>

<style scoped></style>
