<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-start order-sm-1 justify-sm-end justify-center"
      >
        <v-select
          v-model="calendar.type"
          :items="basicMixin_toSelect(calendarTypeOptions)"
          dense
          hide-details
          solo
          :menu-props="{ top: false, offsetY: false }"
          class="mb-1 pt-1"
          style="max-width: 130px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="8"
        class="d-flex align-center order-sm-0 justify-sm-start justify-center"
      >
        <calendar-date-picker
          :date.sync="calendar.start"
          :type="calendar.type === 'scheduler' ? 'day' : calendar.type"
          @prev="$refs.calendarView.prev()"
          @click-today="
            calendar.start = new Date().toISOString().substring(0, 10)
          "
          @next="$refs.calendarView.next()"
        />
      </v-col>
    </v-row>
    <v-calendar
      ref="calendarView"
      v-model="calendar.start"
      :type="calendar.type"
      color="primary"
      :weekdays="weekdays"
      show-week
      locale-first-day-of-year="4"
      :interval-count="0"
      :events="events_list"
      event-overlap-mode="column"
      :event-color="getEventColor"
      @click:date="viewDay"
      @change="fetchData"
      @click:event="$emit('selected-item', $event.event.issue)"
    >
      <template #event="{ event }">
        <calendar-event-body :event="event" />
      </template>

      <template #day="{ date }">
        <holidays-display :holidays="holidaysJson[date]" />
      </template>

      <template #day-header="{ date }">
        <holidays-display :holidays="holidaysJson[date]" />
      </template>
    </v-calendar>
  </div>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import CalendarEventBody from "../../../../_functions/pages/calendar_persons/components/CalendarEventBody";
import HolidaysDisplay from "../../../../_functions/pages/calendar_persons/components/HolidaysDisplay";
import CalendarDatePicker from "../../../../_functions/pages/calendar_persons/components/CalendarDatePicker";

export default {
  name: "BaseCalendar",
  components: { CalendarEventBody, HolidaysDisplay, CalendarDatePicker },
  mixins: [BasicMixin],
  props: {
    klass: Function,
    tabItem: String,
  },
  data() {
    return {
      calendar: {
        type: "month",
        start: this.$moment(new Date()).format("YYYY-MM-DD"),
        coloring: "default",
      },
      events_list: [],
      calendarTypeOptions: {
        day: "calendar.types.day",
        week: "calendar.types.week",
        month: "calendar.types.month",
      },
    };
  },
  computed: {
    weekdays() {
      if (
        this.$store.state.basic_module.personalization.week_first_day ===
        "monday"
      ) {
        return [1, 2, 3, 4, 5, 6, 0];
      } else {
        return [0, 1, 2, 3, 4, 5, 6];
      }
    },
    holidaysJson() {
      return this.$store.getters["loadable/get_list"]("holiday").reduce(
        (map, obj) => {
          if (map[obj.date]) {
            map[obj.date].push({ name: obj.name, country: obj.country });
          } else {
            map[obj.date] = [{ name: obj.name, country: obj.country }];
          }
          return map;
        },
        {}
      );
    },
  },
  watch: {
    tabItem() {
      if (this.tabItem === "cal") {
        this.fetchData();
      }
    },
    "$route.query"(newVar, oldVar) {
      // fix triggering fetchData when query not changes
      if (
        Object.entries(newVar).toString() !==
          Object.entries(oldVar).toString() &&
        this.tabItem === "cal"
      ) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadable/fetchItems", "holiday");
  },
  methods: {
    fetchData(dates) {
      let start = dates ? dates.start : this.$refs.calendarView.lastStart;
      let end = dates ? dates.end : this.$refs.calendarView.lastEnd;

      if (this.calendar.type === "month") {
        start = this.$refs.calendarView.getStartOfWeek(start);
        end = this.$refs.calendarView.getEndOfWeek(end);
      }

      let filter = JSON.parse(JSON.stringify(this.$route.query));

      if (Object.keys(filter).includes("time_filter")) {
        filter.time_filter.push({
          field: "resolve_at",
          type: "fixed",
          from: this.$moment(start.date).startOf("day").toISOString(),
          to: this.$moment(end.date).endOf("day").toISOString(),
        });
      } else {
        filter = {
          ...this.$route.query,
          ...{
            time_filter: [
              {
                field: "resolve_at",
                type: "fixed",
                from: this.$moment(start.date).startOf("day").toISOString(),
                to: this.$moment(end.date).endOf("day").toISOString(),
              },
            ],
          },
        };
      }
      this.$http.get(`/issues`, { params: { filter: filter } }).then(
        (response) => {
          this.events_list = response.body.objects.map((e) => {
            return {
              name: e.name,
              start: new Date(this.$moment(e.resolve_at)),
              end: new Date(this.$moment(e.resolve_at)),
              timed: false,
              issue: e,
            };
          });
        },
        (response) => {
          this.error_message("load", "issue", response);
        }
      );
    },
    getEventColor(event) {
      return this.basicMixin_getSafe(
        () => event.issue.issue_state.color,
        "primary"
      );
    },
    viewDay({ date }) {
      this.calendar.start = date;
      this.calendar.type = "day";
    },
  },
};
</script>

<style scoped></style>
