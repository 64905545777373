import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { toModel } from "../mixins/HelperFunctions";
import { mdiTag } from "@mdi/js";

class TagDefinition extends _DatabaseModel {
  static model = "tag_definition";
  static editDialogComponent = "tag-definitions-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.id", value: "id", sortable: false },
    { textKey: "form.label.name", value: "name", tag: true },
    { textKey: "form.label.entity_type", value: "entity_type" },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static filter = {
    parts: {
      fields: ["name", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(
        `${toModel(object.entity_type)}.${toModel(object.entity_type)}`
      ),
      actions: ["edit", "delete"],
      src: {
        cardIcon: mdiTag,
        color: object.color,
      },
    };
  }
}

export default TagDefinition;
