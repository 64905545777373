const FilteringMixinFixQuery = {
  methods: {
    filteringMixin_fixData(query) {
      if (typeof query === "undefined" || !query) {
        return {};
      }

      // convert array to numbers - if 1 comes as string, if >2 come as array
      for (const key of [
        "tag_definitions",
        "service_id",
        "category_id",
        "entity_id",
        "author_id",
        "contract_id",
        "creator_id",
        "external_email_id",
        "customer_id",
        "service_organization_id",
        "rule_id",
        "id",
        "connector_id",
        "automated_action_id",
        "asset_id",
        "asset_resource_id",
        "person_id",
        "parent_id",
        "price_list_id",
        "issue_state_id",
        "issue_priority_id",
        "risk_level_id",
        "control_id",
        "person_relation_id",
      ]) {
        if (query[key]) {
          if (typeof query[key] === "object") {
            // parse each item of array
            query[key] = query[key].map((item) => parseInt(item));
          } else if (typeof query[key] === "string") {
            // convert string to array
            query[key] = [parseInt(query[key])];
          }
          // only numbers allowed
          query[key] = query[key].filter(
            (item) => typeof item === "number" && !isNaN(item)
          );
        }
      }

      for (const key of ["author_type", "entity_type"]) {
        if (query[key] && typeof query[key] === "string") {
          // convert string to array
          query[key] = [query[key]];
        }
      }

      Object.keys(query).forEach(
        (key) =>
          (query[key] === null ||
            typeof query[key] === "undefined" ||
            query[key] === "" ||
            (Array.isArray(query[key]) && query[key].length < 1)) &&
          delete query[key]
      );
      return query;
    },
  },
};

export default FilteringMixinFixQuery;
