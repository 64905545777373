<template>
  <v-avatar :color="avatarColor" :size="avatarSize" :rounded="avatarTile">
    <v-img
      v-if="src && src.document_id"
      class="image-border"
      :src="`/documents/${src.document_id}/preview`"
    ></v-img>

    <span v-else-if="src && src.extension">
      {{ src.extension }}
    </span>

    <v-icon v-else :size="iconSize" :color="iconColor" class="rounded-0">
      {{ computedIcon }}
    </v-icon>
  </v-avatar>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import Category from "../../../classes/Category";
import { APP_MENU } from "../../../../_config/constants";
import {
  mdiAutoFix,
  mdiCogs,
  mdiEmail,
  mdiPowerPlug,
  mdiWrench,
  mdiAccount,
  mdiFile,
} from "@mdi/js";
export default {
  name: "BaseAvatar",
  mixins: [BasicMixin],
  props: {
    iconType: String,
    src: Object,
    avatarTile: Boolean,
    avatarColor: {
      type: String,
      default: "background",
    },
    avatarSize: {
      type: Number,
      default: 48,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
    model: String,
  },
  data() {
    return {
      categoryIcons: Category.categoryIcons,
    };
  },
  computed: {
    iconColor() {
      if (this.src && this.src.color) return this.src.color;
      return this.$vuetify.theme.dark ? "white" : "grey darken-2";
    },
    computedIcon() {
      // TODO: refactor classes and constants to simplify computing icons
      if (this.src && this.src.category_id)
        return this.categoryIcon(this.src.category_id);
      else if (this.src && this.src.cardIcon) return this.src.cardIcon;
      else if (this.iconType)
        return this.iconType === "ExternalEmail"
          ? mdiEmail
          : this.iconType === "Person"
          ? mdiAccount
          : this.iconType === "Service"
          ? mdiWrench
          : this.iconType === "AutomatedAction"
          ? mdiAutoFix
          : this.iconType === "Rule" || this.iconType === "System"
          ? mdiCogs
          : this.iconType === "Connector"
          ? mdiPowerPlug
          : "";
      else if (
        this.model &&
        (this.model !== "document" ||
          ![
            "image/bmp",
            "image/gif",
            "image/jpeg",
            "image/png",
            "image/tiff",
            "image/webp",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/plain",
            "text/xml",
            "text/csv",
            "text/markdown",
            "application/xml",
            "application/json",
          ].includes(this.src.doc_content_type))
      ) {
        if (["person_category", "asset_category"].includes(this.model)) {
          return this.categoryIcon(this.src.id);
        }
        let findIcon;
        for (const item of APP_MENU) {
          let x = item.items.find(
            (i) => i.perms_name === this.toModel(this.model, true)
          );
          if (x) {
            findIcon = x.icon;
          }
        }
        return findIcon ? findIcon : mdiFile;
      } else return "";
    },
  },
  mounted() {
    if (this.$route.path.split("/")[1] !== "public") {
      this.$store.dispatch("loadable/fetchItems", "category");
    }
  },
  methods: {
    categoryIcon(category_id) {
      const category = this.$store.getters["loadable/get_list"](
        "category"
      ).find(({ id }) => id === category_id);
      if (category) {
        return this.categoryIcons[category.icon].icon;
      }
    },
  },
};
</script>

<style scoped></style>
