<template>
  <div class="pr-3">
    <span
      class="primary--text"
      :class="{ link: $i18n.locale !== 'en' }"
      @click="switchLocale('en')"
      >EN</span
    >
    /
    <span
      class="primary--text"
      :class="{ link: $i18n.locale !== 'sk' }"
      @click="switchLocale('sk')"
      >SK</span
    >
  </div>
</template>

<script>
export default {
  name: "BaseLocalesSwitch",
  methods: {
    switchLocale(locale) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    },
  },
};
</script>

<style scoped></style>
