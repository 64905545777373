<template>
  <base-loadable-autocomplete
    v-model="inner"
    model="tag_definition"
    :multiple="multiple"
    v-bind="$attrs"
    :filter="
      (item) => {
        return toModel(item.entity_type) === model;
      }
    "
  >
    <template #selection="{ item, index }">
      <v-chip
        v-if="item.id === 0"
        small
        close
        @click:close="inner.splice(index, 1)"
      >
        {{ item.name }}
      </v-chip>
      <v-chip
        v-else
        :color="item.color"
        small
        :class="textColor(item.color)"
        class="text-no-wrap"
        style="max-width: 220px"
        :close="multiple"
        @click:close="inner.splice(index, 1)"
      >
        <span class="text-truncate">{{ item.name }}</span>
      </v-chip>
    </template>
    <template #item="{ item, on, attrs }">
      <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-chip v-if="item.id === 0" small>
              {{ item.name }}
            </v-chip>
            <v-chip
              v-else
              :color="item.color"
              small
              :class="textColor(item.color)"
              class="text-no-wrap"
              style="max-width: 220px"
            >
              <span class="text-truncate">{{ item.name }}</span>
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </base-loadable-autocomplete>
</template>

<script>
import BaseLoadableAutocomplete from "../../../_generic/pages/components/base/BaseLoadableAutocomplete";
import ColorHelper from "../../../_generic/mixins/ColorHelper";
import BasicMixin from "../../../_generic/mixins/BasicMixin";
export default {
  name: "TagDefinitionLoadableAutocomplete",
  components: { BaseLoadableAutocomplete },
  mixins: [BasicMixin, ColorHelper],
  props: {
    value: [String, Number, Array, Object],
    multiple: Boolean,
    model: String,
  },
  computed: {
    inner: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
