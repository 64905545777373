const RolesEditDialog = () =>
  import(
    /* webpackChunkName: "persons" */ "../../_functions/pages/roles/RolesEditDialog"
  );
const DocumentsEditDialog = () =>
  import(
    /* webpackChunkName: "documents" */ "../../_functions/pages/documents/DocumentsEditDialog"
  );
const UsersEditDialog = () =>
  import(
    /* webpackChunkName: "persons" */ "../../_functions/pages/users/UsersEditDialog"
  );
const RulesEditDialog = () =>
  import(
    /* webpackChunkName: "services" */ "../../_functions/pages/rules/RulesEditDialog"
  );
const PriceItemsEditDialog = () =>
  import(
    /* webpackChunkName: "warehouses" */ "../../_functions/pages/price_items/PriceItemsEditDialog"
  );
const WarehousesEditDialog = () =>
  import(
    /* webpackChunkName: "warehouses" */ "../../_functions/pages/warehouses/WarehousesEditDialog"
  );
const PriceListsEditDialog = () =>
  import(
    /* webpackChunkName: "warehouses" */ "../../_functions/pages/price_lists/PriceListsEditDialog"
  );
const DataSetsEditDialog = () =>
  import(
    /* webpackChunkName: "settings" */ "../../_functions/pages/data_sets/DataSetsEditDialog"
  );
const ReportsEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/reports/ReportsEditDialog"
  );
const AutomatedActionsEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/automated_actions/AutomatedActionsEditDialog"
  );
const ServicesEditDialog = () =>
  import(
    /* webpackChunkName: "services" */ "../../_functions/pages/services/ServicesEditDialog"
  );
const ContractsEditDialog = () =>
  import(
    /* webpackChunkName: "services" */ "../../_functions/pages/contracts/ContractsEditDialog"
  );
const MappersEditDialog = () =>
  import(
    /* webpackChunkName: "connectors" */ "../../_functions/pages/mappers/MappersEditDialog"
  );
const SnippetsEditDialog = () =>
  import(
    /* webpackChunkName: "settings" */ "../../_functions/pages/snippets/SnippetsEditDialog"
  );
const TranslationsEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/translations/TranslationsEditDialog"
  );
const TagDefinitionsEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/tag_definitions/TagDefinitionEditDialog"
  );
const KpiCreateWizard = () =>
  import(
    /* webpackChunkName: "dashboards" */ "../../_functions/pages/kpi/components/KpiCreateWizard"
  );
const RemindersEditDialog = () =>
  import(
    /* webpackChunkName: "settings" */ "../../_functions/pages/reminders/RemindersEditDialog"
  );

const PersonRelationsEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/person_relations/PersonRelationsEditDialog"
  );

const HolidaysEditDialog = () =>
  import(
    /* webpackChunkName: "implementation" */ "../../_functions/pages/holidays/HolidaysEditDialog"
  );

const IssueStateAndPrioritiesEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/issue_states_and_priorities/IssueStateAndPrioritiesEditDialog"
  );

const FieldEditDialog = () =>
  import(
    /* webpackChunkName: "administration" */ "../../_functions/pages/fields/FieldEditDialog"
  );

const PersonsAndAssetsEditDialog = () =>
  import(
    /* webpackChunkName: "persons-assets" */ "../../_functions/pages/persons/PersonsAndAssetsEditDialog"
  );

const CategoriesEditDialog = () =>
  import(
    /* webpackChunkName: "persons-assets" */ "../../_functions/pages/categories/CategoriesEditDialog"
  );

const ConnectorsEditDialog = () =>
  import(
    /* webpackChunkName: "connectors" */ "../../_functions/pages/connectors/ConnectorsEditDialog"
  );

const SubscriptionsEditDialog = () =>
  import(
    /* webpackChunkName: "settings" */ "../../_functions/pages/subscriptions/SubscriptionsEditDialog"
  );

const ThreatEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/threats/ThreatsEditDialog"
  );

const VulnerabilitiesEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/vulnerabilities/VulnerabilitiesEditDialog"
  );

const ControlEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/controls/ControlEditDialog"
  );

const ConsequencesEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/consequences/ConsequencesEditDialog"
  );

const LikelihoodsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/likelihoods/LikelihoodsEditDialog"
  );

const RiskLevelsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/risk_levels/RiskLevelsEditDialog"
  );

const PrimaryAssetsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/primary_assets/PrimaryAssetsEditDialog.vue"
  );

const AssetGroupsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/asset_groups/AssetGroupsEditDialog.vue"
  );

const AuditsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/audits/AuditsEditDialog"
  );

const SoasEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/soas/SoasEditDialog"
  );

const StandardsEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/standards/StandardsEditDialog"
  );

const StandardSoasEditDialog = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/standard_soas/StandardSoasEditDialog"
  );

const AllEditDialogMixin = {
  components: {
    AssetGroupsEditDialog,
    AuditsEditDialog,
    AutomatedActionsEditDialog,
    CategoriesEditDialog,
    ConnectorsEditDialog,
    ConsequencesEditDialog,
    ContractsEditDialog,
    ControlEditDialog,
    DataSetsEditDialog,
    DocumentsEditDialog,
    FieldEditDialog,
    HolidaysEditDialog,
    IssueStateAndPrioritiesEditDialog,
    KpiCreateWizard,
    LikelihoodsEditDialog,
    MappersEditDialog,
    PersonRelationsEditDialog,
    PersonsAndAssetsEditDialog,
    PriceItemsEditDialog,
    PriceListsEditDialog,
    PrimaryAssetsEditDialog,
    RemindersEditDialog,
    ReportsEditDialog,
    RiskLevelsEditDialog,
    RolesEditDialog,
    RulesEditDialog,
    ServicesEditDialog,
    SnippetsEditDialog,
    SoasEditDialog,
    StandardSoasEditDialog,
    StandardsEditDialog,
    SubscriptionsEditDialog,
    TagDefinitionsEditDialog,
    ThreatEditDialog,
    TranslationsEditDialog,
    UsersEditDialog,
    VulnerabilitiesEditDialog,
    WarehousesEditDialog,
  },
};

export default AllEditDialogMixin;
