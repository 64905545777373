<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :label="$t('first_login.headers.gdpr')"
      ></base-v-card-title-dialog-intern>

      <v-card-text class="pt-6">
        <div>
          <p>
            V zmysle článku 19 a nasl. zákona č. 18/2018 Zb. o ochrane osobných
            údajov a o zmene a doplnení niektorých zákonov v znení neskorších
            predpisov v súlade s článkami 13 a 14 nariadenia Európskeho
            parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane
            fyzických osôb pokiaľ ide o spracovanie osobných údajov a voľný
            pohyb takýchto údajov a ktorou sa ruší smernica 95/46 / ES
            (nariadenie o všeobecnej ochrane údajov) (ďalej len “GDPR”).
          </p>

          <p>
            Podľa ustanovení článku 19 a nasl. zákona č. 18/2018 Zb. o ochrane
            osobných údajov v znení neskorších predpisov (ďalej len “zákon o
            ochrane osobných údajov”), týmto vám ako subjektu údajov poskytneme
            nasledujúce informácie o spracovaní vašich osobných údajov.
          </p>

          <p>
            <strong>Dohľad:</strong>
          </p>

          <p>
            AUSEMIO s.r.o., so sídlom na adrese Panónska cesta 47, Bratislava -
            mestská časť Petržalka 851 04, IČO: 53 269 021 (ďalej len
            “kontrolór”).
          </p>

          <p>
            <strong>Kontaktné údaje:</strong>
          </p>

          <p>E-mail: <a href="mailto:info@ausemio.com">info@ausemio.com</a></p>

          <p>Telefón: 0911 441 662</p>

          <p>
            <strong>Prístup k osobným údajom:</strong>
          </p>

          <p>
            Pri spracúvaní osobných údajov môžu okrem spracovateľa a spoločností
            spriaznených s AUSEMIO s.r.o. a jeho zamestnancami mať k osobným
            údajom aj iné osoby spracúvajúce osobné údaje ako spracovatelia.
            Špecifický priebežne aktualizovaný zoznam procesorov, t. j.
            príjemcov osobných údajov spracovaných v mene kontrolóra, v
            nasledujúcich kategóriách:
          </p>

          <ul class="pb-4">
            <li>Spoločnosti poskytujúce služby interného a externého auditu</li>
          </ul>

          <p>
            <strong>Právny základ:</strong>
          </p>

          <p>
            Právnym základom na spracovanie osobných údajov je spravidla
            splnenie zákonnej povinnosti (najmä zákona č. 18/2018 Zb. O ochrane
            osobných údajov), uzavretie, správa a plnenie zmluvných záväzkov
            vyplývajúcich zo zamestnania a obchodných zmlúv, legitímny záujem,
            ktorým je ochrana práv a záujmov kontrolóra a jeho zamestnancov
            chránených právom, ako aj súhlas dotknutej osoby. Súhlas so
            spracovaním osobných údajov musí byť udelený dobrovoľne a dotknutá
            osoba môže kedykoľvek odobrať súhlas. Súhlas je však nevyhnutný pre
            kontrolóra, aby zabezpečil poskytovanie prevádzky systému AUSEMIO a
            bez takéhoto súhlasu nemá kontrolór právo poskytovať príslušné
            služby. Ak subjekt údajov odmietne poskytnúť osobný údaj, ktorý je
            potrebný na plnenie zmluvných povinností prevádzkovateľa alebo
            dodržiavať zákon, kontrolór nie je povinný uzavrieť zmluvu alebo
            poskytovať iné služby.
          </p>

          <p>
            <strong>Spracované osobné údaje:</strong>
          </p>

          <p>
            Zhromažďujeme meno zákazníka, e-mailovú adresu, názov spoločnosti,
            adresu IP, informácie o zariadeniach a prehliadačoch, ktoré
            používate. Tieto informácie zhromažďujeme, keď používate alebo
            vstúpite do informačného systému AUSEMIO. Počas návštevy alebo
            prehliadania webovej stránky ausemio zhromažďujeme informácie o
            používanom zariadení a prehliadači, vašom sieťovom pripojení, adrese
            IP a informáciách o súboroch cookies nainštalovaných vo vašom
            zariadení. Takisto zhromažďujeme osobné informácie, ktoré ste
            predložili prostredníctvom ľubovoľnej funkcie na odosielanie správ,
            ktorá je k dispozícii v rámci aplikácie AUSEMIO.
          </p>

          <p>
            <strong> Účel spracovania a zdieľania:</strong>
          </p>

          <p>
            Tieto informácie používame na poskytovanie služieb. Tieto informácie
            používame aj na zlepšenie našich služieb.
          </p>

          <p>
            <strong>Uchovávanie osobných údajov a trvanie spracovania:</strong>
          </p>

          <p>
            Prevádzkovateľ je oprávnený spracúvať osobné údaje dotknutých osôb
            po dobu určenú v súlade s príslušnými právnymi predpismi.
            Spracovanie osobných údajov je možné len na obdobie, na ktoré bol
            súhlas udelený. Súhlas sa poskytne kontrolórovi na nasledujúce tri
            (3) roky alebo do dátumu odňatia súhlasu. Po uplynutí príslušného
            obdobia sa osobné údaje vymažú, ak sa ich skladovanie podľa
            príslušných slovenských právnych predpisov nevyžaduje.
          </p>

          <p>
            <strong
              >Subjekt údajov súvisiaci so spracovaním osobných údajov:</strong
            >
          </p>

          <p>
            Pokiaľ ide o spracovanie osobných údajov, dotknutá osoba môže
            uplatňovať tieto práva:
          </p>

          <ul class="pb-4">
            <li>
              právo na prístup a poskytnutie informácií o ich osobných údajoch;
            </li>
            <li>právo na opravu osobných údajov;</li>
            <li>právo na vymazanie osobných údajov;</li>
            <li>právo na obmedzenie spracovania osobných údajov;</li>
            <li>právo na prenosnosť osobných údajov;</li>
            <li>právo vzniesť námietky voči spracovaniu osobných údajov;</li>
            <li>
              právo na neefektívnosť automatizovaného individuálneho
              rozhodovania vrátane profilovania;
            </li>
            <li>
              právo odobrať súhlas kedykoľvek (ak súhlas je právnym základom
              spracovania);
            </li>
            <li>
              právo podať návrh na začatie konania podľa článku 100 a nasl.
              zákona o ochrane osobných údajov s orgánom dohľadu, Úradom na
              ochranu osobných údajov Slovenskej republiky so sídlom Hraničná
              12, 820 07 Bratislava 27, Slovenská republika, kontaktné údaje:
              +421 2 3231 3214
            </li>
          </ul>

          <p>
            Subjekt údajov môže podliehať ustanoveniam článkov 21 a nasl. zákona
            o ochrane osobných údajov v súlade so zákonom o ochrane osobných
            údajov a GDPR, ako aj inými relevantnými právnymi predpismi.
            Dotknuté osoby môžu uplatniť svoje práva voči kontrolórovi na
            základe písomnej žiadosti zaslanej alebo doručenej do sídla
            kontrolóra: Panónska cesta 47, Bratislava - mestská časť Petržalka
            851 04 alebo elektronickou poštou na adresu:
            <a href="mailto:info@ausemio.com">info@ausemio.com</a>. Žiadosť musí
            obsahovať nasledujúce informácie: meno, priezvisko, dátum narodenia
            a trvalý pobyt, aby mohol kontrolór identifikovať dotknutú osobu.
            Kontrolór môže požiadať o poskytnutie dodatočných informácií
            potrebných na overenie totožnosti dotknutej osoby, ak má dôvodné
            pochybnosti o totožnosti fyzickej osoby. Správca je povinný
            poskytnúť dotknutej osobe informácie o opatreniach prijatých na jej
            žiadosť do jedného (1) mesiaca od prijatia žiadosti. V odôvodnených
            prípadoch, pokiaľ ide o zložitosť a počet žiadostí, môže kontrolór
            predĺžiť vyššie uvedené obdobie o ďalšie dva (2) mesiace, dokonca
            opakovane. Prevádzkovateľ je povinný informovať dotknuté osoby o
            takomto predĺžení do jedného (1) mesiaca od doručenia žiadosti,
            pričom uvedie dôvody na predĺženie lehoty. Ak dotknutá osoba
            predložila žiadosť v elektronickej forme, prevádzkovateľ poskytne
            informácie v elektronickej forme, pokiaľ subjekt údajov nepožiadal o
            informácie iným spôsobom.
          </p>

          <p>
            Ak kontrolór na žiadosť dotknutej osoby neprijme opatrenia, je
            povinný informovať dotknutú osobu o dôvodoch nečinnosti v lehote
            jedného (1) mesiaca od doručenia žiadosti, ako aj o možnosti podania
            žiadosti návrh na začatie konania podľa článku 100 zákona o ochrane
            osobných údajov s Úradom na ochranu osobných údajov. Oznámenia o
            prijatých opatreniach sa poskytujú bezplatne. Ak je žiadosť
            dotknutej osoby zjavne neopodstatnená alebo nevhodná, najmä kvôli
            jej opakujúcej sa povahe, môže prevádzkovateľ požadovať primeraný
            poplatok, ktorý zohľadní administratívne náklady na poskytnutie
            informácií alebo primeraný poplatok zohľadňujúci administratívne
            náklady na oznámenie alebo primeraný poplatok s prihliadnutím na
            administratívne náklady na vykonanie požadovaného opatrenia alebo
            odmietnutie konať na žiadosť. Kontrolór preukáže, že žiadosť je
            neopodstatnená alebo nevhodná.
          </p>

          <p>
            AUSEMIO s.r.o. chápe, že máte právo na vaše osobné údaje a podnikne
            primerané kroky, ktoré vám umožnia prístup k vašim osobným
            informáciám, ich opravu, zmenu, odstránenie alebo obmedzenie. Ak ste
            obchodník alebo partner, môžete aktualizovať mnoho typov osobných
            informácií, ako sú platobné alebo kontaktné informácie, priamo v
            nastaveniach účtu. Ak nemôžete zmeniť svoje osobné údaje v
            nastaveniach účtu alebo ak máte obavy z údajov získaných pri
            návšteve webových stránok ausemio alebo z našich služieb podpory,
            kontaktujte nás, aby ste vykonali požadované zmeny. Je dôležité mať
            na pamäti, že ak odstránite alebo obmedzíte používanie vašich
            osobných informácií, služby nemusia fungovať správne.
          </p>

          <p>
            Ak máte akékoľvek otázky týkajúce sa vašich osobných údajov alebo
            týchto pravidiel, alebo chcete podať sťažnosť na to, ako AUSEMIO
            s.r.o. spracováva vaše osobné údaje, obráťte sa na neho e-mailom na
            adresu
            <a href="mailto:info@ausemio.com">info@ausemio.com</a>
          </p>

          <p class="pb-0">V Bratislave, 28.02.2020</p>
        </div>
      </v-card-text>
      <base-v-card-actions-buttons-dialog
        negative-translation="action.close"
        @negative="dialog = false"
      ></base-v-card-actions-buttons-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseVCard from "../../components/base/BaseVCard";

export default {
  name: "GdprDialog",
  mixins: [BaseVCard],
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
