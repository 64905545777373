import _DatabaseModel from "./_DatabaseModel";

class Standard extends _DatabaseModel {
  static model = "standard";
  static showSize = 8;
  static createNewObject = false;
  static editDialogComponent = "standards-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { textKey: "standard.label.valid_from", value: "valid_from" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["activity", "destroy"],
  };

  static filter = {
    parts: {
      fields: ["name", "id"],
      extended_fields: ["description"],
      time_filter: ["valid_from"],
    },
  };

  static basicInfo(object) {
    return [
      {
        labelKey: "standard.label.valid_from",
        value: object.valid_from,
        type: "date",
      },
      {
        labelKey: "standard.label.valid_to",
        value: object.valid_to,
        type: "date",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "audit.label.requirements",
      permission: "audit_tab_valuation",
      component: "AuditsValuationTab",
      input_data: (context) => context.object.sections,
      disabled: () => false,
    },
  ];
}

export default Standard;
