<template>
  <base-loadable-autocomplete
    v-model="inner"
    model="issue_state"
    :multiple="multiple"
    v-bind="$attrs"
  >
    <template #selection="{ item, index }">
      <v-chip
        v-if="item.id === 0"
        small
        close
        @click:close="inner.splice(index, 1)"
      >
        {{ item.name }}
      </v-chip>
      <issue-state-tag
        v-else
        :state="item"
        :closable="multiple"
        @click:close="inner.splice(index, 1)"
      ></issue-state-tag>
    </template>
    <template #item="{ item, on, attrs }">
      <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-chip v-if="item.id === 0" small>
              {{ item.name }}
            </v-chip>
            <issue-state-tag v-else :state="item"></issue-state-tag>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </base-loadable-autocomplete>
</template>

<script>
import BaseLoadableAutocomplete from "../../../_generic/pages/components/base/BaseLoadableAutocomplete";
import IssueStateTag from "../issues/components/IssueStateTag";
export default {
  name: "IssueStateLoadableAutocomplete",
  components: { BaseLoadableAutocomplete, IssueStateTag },
  props: {
    value: [String, Number, Array, Object],
    multiple: Boolean,
  },
  computed: {
    inner: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
