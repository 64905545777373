<template>
  <v-card
    class="my-2"
    rounded="lg"
    :to="klass.show ? to : ''"
    v-on="klass.show ? { click: () => $emit('selected-item', item) } : {}"
  >
    <v-list-item two-line>
      <v-list-item-avatar
        v-if="klassItem.src"
        :rounded="!isModel('person')"
        class="align-self-start"
      >
        <base-avatar
          :avatar-tile="!isModel('person')"
          :src="klassItem.src"
          :model="klass.model"
        >
        </base-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="pb-1">
        <v-list-item-title>
          {{ klassItem.title }}
        </v-list-item-title>

        <v-list-item-subtitle>
          <span v-if="klassItem.subtitle" class="mr-3">
            {{ klassItem.subtitle }}
          </span>
          <div v-if="klassItem.infoWithLabel" class="d-flex flex-column">
            <span
              v-for="object in klassItem.infoWithLabel"
              :key="object.key"
              class="pb-1"
            >
              {{ $t(`${object.label}`) }}: {{ item[object.key] }}
            </span>
          </div>
          <div v-if="klassItem.date_from" class="pb-1">
            <span>
              {{ klassItem.date_from | moment(date_format()) }}
            </span>
            -
            <span v-if="klassItem.date_to">{{
              klassItem.date_to | moment(date_format())
            }}</span>
            <span v-else>{{ $t("generic.unset") }}</span>
          </div>
          <div v-if="klassItem.date" class="pb-1">
            <span>
              {{ klassItem.date | moment(date_format()) }}
            </span>
          </div>
          <link-checked
            v-if="klassItem.linkToIssue"
            :id="item.issue.id"
            class="pb-1"
            to="issues"
            :text="`#${item.issue.id} ${item.issue.name}`"
            :discarded-at="item.issue.discarded_at"
            truncate
          ></link-checked>
          <base-tooltip-date-data
            v-if="klassItem.time"
            class="pb-1"
            :datetime="klassItem.time"
          ></base-tooltip-date-data>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <link-checked
            v-if="klassItem.linkToPerson && item.person"
            :id="item.person.id"
            to="persons"
            :text="item.person.name"
            :discarded-at="item.person.discarded_at"
          />
        </v-list-item-subtitle>

        <v-card-actions class="px-0 py-1">
          <div v-if="klassItem.tags || klassItem.activeTag || klassItem.hidden">
            <base-object-tags
              v-if="klassItem.tags"
              :object="item"
              :model="klass.model"
              bottom-margin
            />
            <v-chip
              v-if="
                klassItem.activeTag &&
                item.id === $store.state.basic_module.current_user.session_id
              "
              color="primary"
              text-color="white"
              small
              active
            >
              {{ $t("jwt_session.label.actual") }}
            </v-chip>
            <div>
              <span v-if="klassItem.hidden && item['hidden']">
                {{ $t("issue_state.label.hidden") }}</span
              >
            </div>
          </div>
          <div v-if="klassItem.actions && !isDashboard">
            <v-icon
              v-if="klassItem.actions.includes('mail') && item.mail"
              :color="item.mail ? 'primary' : undefined"
              >{{ mdiEmail }}</v-icon
            >
            <v-icon
              v-if="klassItem.actions.includes('push') && item.push"
              :color="item.push ? 'primary' : undefined"
              >{{ mdiCellphone }}</v-icon
            >
            <v-icon
              v-if="klassItem.actions.includes('sms') && item.sms"
              :color="item.sms ? 'primary' : undefined"
              >{{ mdiMessageText }}</v-icon
            >
          </div>
          <v-spacer></v-spacer>
          <div
            v-if="klassItem.actions && !isDashboard"
            class="d-flex justify-end"
          >
            <base-icon-button
              v-if="klassItem.actions.includes('up') && item.order !== 0"
              :icon="mdiArrowUpBold"
              :tooltip-translation="
                $t(
                  `tooltips.tabs_icons.${
                    isModel('issue_priority') ? 'priority_' : ''
                  }move_up`
                )
              "
              @click="moveItem(item, 'up')"
            ></base-icon-button>
            <v-spacer
              v-if="item.order === totalItems - 1"
              style="width: 40px; max-width: 40px"
              class="order-1"
            ></v-spacer>
            <base-icon-button
              v-if="
                klassItem.actions.includes('down') &&
                item.order !== totalItems - 1
              "
              :icon="mdiArrowDownBold"
              :tooltip-translation="
                $t(
                  `tooltips.tabs_icons.${
                    isModel('issue_priority') ? 'priority_' : ''
                  }move_down`
                )
              "
              @click="moveItem(item, 'down')"
            ></base-icon-button>
            <div class="order-2 d-flex flex-nowrap justify-end">
              <base-icon-button
                v-if="klassItem.actions.includes('edit')"
                :icon="mdiPencil"
                :disabled="isModel('reminder') && item.notification_time < now"
                :tooltip-disabled-translation="
                  $t('tooltips.show_buttons.disabled_buttons.reminder_exp')
                "
                :tooltip-translation="$t('tooltips.tabs_icons.edit')"
                @click="editItem(item)"
              ></base-icon-button>
              <base-icon-button
                v-if="klassItem.actions.includes('delete')"
                :icon="mdiDelete"
                color="error"
                :tooltip-translation="$t('tooltips.tabs_icons.delete')"
                @click="deleteItem(item)"
              ></base-icon-button>
            </div>
          </div>
          <base-icon-button
            v-if="klassItem.actions?.includes('preview')"
            :icon="mdiMagnify"
            :tooltip-translation="$t('tooltips.tabs_icons.preview')"
            @click="previewItem(item)"
          ></base-icon-button>
        </v-card-actions>
      </v-list-item-content>
    </v-list-item>
    <component
      :is="klassItem.previewDialog"
      v-if="klassItem.previewDialog"
      ref="preview_dialog"
      :key="`edit_dialog_${klass.cardFields.previewDialog}`"
    />
  </v-card>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import BaseObjectTags from "./BaseObjectTags";
import PrintingDate from "../../../mixins/PrintingDate";
import BaseTooltipDateData from "./BaseTooltipDateData";
import BaseIconButton from "./BaseIconButton";
import BaseTooltipIcon from "./BaseTooltipIcon";
import LinkChecked from "../../../../_functions/pages/components/LinkChecked";
import ChecklistPreviewDialog from "../../../../_functions/pages/issues/components/ChecklistPreviewDialog";
import IssueStateTag from "../../../../_functions/pages/issues/components/IssueStateTag";
import BaseAvatar from "./BaseAvatar";
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiCellphone,
  mdiEmail,
  mdiArrowDownBold,
  mdiArrowUpBold,
  mdiMessageText,
} from "@mdi/js";

export default {
  name: "BaseCardComponent",
  components: {
    BaseIconButton,
    BaseObjectTags,
    BaseTooltipDateData,
    BaseTooltipIcon,
    LinkChecked,
    ChecklistPreviewDialog,
    IssueStateTag,
    BaseAvatar,
  },
  mixins: [BasicMixin, PrintingDate],
  props: {
    klass: Function,
    item: Object,
    deleteItem: Function,
    editItem: Function,
    moveItem: Function,
    now: String,
    totalItems: Number,
    to: [String, Object],
  },
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiCellphone,
      mdiEmail,
      mdiArrowDownBold,
      mdiArrowUpBold,
      mdiMessageText,
    };
  },
  computed: {
    klassItem() {
      return this.klass.cardFields(this.item);
    },
    isDashboard() {
      return this.$route.fullPath.includes("dashboard_screens");
    },
  },
  mounted() {
    this.$store.dispatch("loadable/fetchItems", "category");
  },
  methods: {
    previewItem(item) {
      this.$refs.preview_dialog.openDialog(
        item,
        {
          template_assignments: item.issue.service.template_assignments.filter(
            (ta) => ta.for_checklist === true
          ),
        },
        false
      );
    },
  },
};
</script>

<style scoped></style>
