import {
  getChecklistMarkerIcon,
  getChecklistMarkerColor,
} from "../../_generic/mixins/HelperFunctions";

const ChecklistMixin = {
  data() {
    return {
      getChecklistMarkerColor: getChecklistMarkerColor,
      getChecklistMarkerIcon: getChecklistMarkerIcon,
      solve_status_options: {
        success: "assets_issue.label.success",
        warning: "assets_issue.label.warning",
        fail: "assets_issue.label.fail",
      },
    };
  },
};

export default ChecklistMixin;
