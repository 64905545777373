import _DatabaseModel from "./_DatabaseModel";
import {
  getChecklistMarkerIcon,
  getChecklistMarkerColor,
} from "../mixins/HelperFunctions";

class AssetsIssue extends _DatabaseModel {
  static model = "assets_issue";

  static tableHeaders = [
    { textKey: "asset.asset", value: "asset.name" },
    {
      textKey: "form.label.solve_status",
      value: "solve_status",
      translationPrefix: "assets_issue.label",
    },
  ];

  static filter = {
    parts: {
      fields: ["solve_status", "service_id", "asset_id"],
      extended_fields: [],
    },
  };

  static cardFields(object) {
    return {
      src: {
        cardIcon: getChecklistMarkerIcon(object.solve_status),
        color: getChecklistMarkerColor(object.solve_status),
      },
      title: object.asset.name,
      actions: ["preview"],
      linkToIssue: true,
      previewDialog: "checklist-preview-dialog",
    };
  }
}

export default AssetsIssue;
