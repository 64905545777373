<template>
  <div>
    <div class="text-caption text--secondary">
      {{ $t("document.label.size") }} (kB)
    </div>

    <v-row dense>
      <v-col cols="6">
        <v-text-field
          v-model="innerCondition.from"
          :label="$t('form.label.from')"
          type="number"
          :min="0"
          :step="10"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="innerCondition.to"
          :label="$t('form.label.to')"
          type="number"
          :min="innerCondition.from"
          :step="10"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FilterSizeCondition",
  props: {
    value: Object,
  },
  data: () => ({
    innerCondition: {},
  }),
  watch: {
    innerCondition() {
      this.$emit("input", this.innerCondition);
    },
    value: {
      handler() {
        this.innerCondition = this.value || {};
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
