import _DatabaseModel from "./_DatabaseModel";

class Activity extends _DatabaseModel {
  static model = "activity";
  static bottomNav = { mobile: ["list"], desktop: ["list"] };
  static showSize = 0;
  static createNewObject = false;

  static tableHeaders = [
    { textKey: "activity.list_label.created_at", value: "created_at" },
    {
      textKey: "activity.list_label.created_by",
      value: "created_by",
      sortable: false,
    },
    { textKey: "activity.list_label.activity_type", value: "activity_type" },
    { textKey: "activity.list_label.object_type", value: "entity_type" },
    { textKey: "activity.list_label.object_id", value: "entity_id" },
    { textKey: "", value: "data-table-expand", sortable: false },
  ];

  static isExpandable(object) {
    return ["create", "update", "destroy"].includes(object.activity_type);
  }

  static filter = {
    parts: {
      fields: [
        "person_id",
        "activity_type",
        "entity_type",
        "entity_id",
        "author_system",
      ],
      extended_fields: ["automated_action_id", "connector_id", "time_filter"],
      time_filter: ["created_at"],
    },
  };

  static operations = ["export"];

  static activity_types = [
    "update",
    "create",
    "destroy",
    "other",
    "log_in",
    "log_out",
    "archive",
    "unarchive",
  ];
}

export default Activity;
