<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    max-width="600"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern
        v-model="dialog"
        :label="$t('generic.scan_qr')"
        @input="close_qr_scanner"
      />

      <v-card-text class="pt-6">
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
        <StreamBarcodeReader
          v-else
          ref="reader"
          @decode="startDecoding"
        ></StreamBarcodeReader>
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        negative-translation="action.cancel"
        @negative="close_qr_scanner"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import BaseVCardActionsButtonsDialog from "../../../../_generic/pages/components/base/card/BaseVCardActionsButtonsDialog";
import BaseVCardTitleDialogIntern from "../../../../_generic/pages/components/base/card/BaseVCardTitleDialogIntern";
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import ScannerMixin from "../../../mixins/ScannerMixin";

export default {
  name: "AssetsQrScanner",
  components: {
    BaseVCardTitleDialogIntern,
    BaseVCardActionsButtonsDialog,
    StreamBarcodeReader: () =>
      import("vue-barcode-reader").then((module) => module.StreamBarcodeReader),
  },
  mixins: [BasicMixin, ScannerMixin],
  data() {
    return {
      dialog: false,
      error: "",
      asset: {},
    };
  },
  methods: {
    async open_qr_scanner() {
      await this.checkCameraPermission().then((result) => {
        this.error = result;
      });
      this.dialog = true;
    },
    close_qr_scanner() {
      if (!this.error) {
        this.resetScanner();
      }
      this.dialog = false;
    },
    startDecoding(decodedString) {
      this.load_asset(decodedString);
      this.dialog = false;
      this.resetScanner();
    },
    load_asset(scannedString) {
      let string = scannedString;
      if (scannedString.split("asset=").length > 1) {
        string = scannedString.split("asset=")[1];
      }
      this.$http
        .get(`assets/load_guid`, {
          params: {
            guid: string,
          },
        })
        .then(
          (response) => {
            this.$emit("input", response.body);
          },
          (response) => {
            this.error_message("load", "asset", response);
          }
        );
    },
  },
};
</script>

<style scoped></style>
