<template>
  <div
    v-if="$vuetify.breakpoint.mdAndUp && $route.path.split('/').length <= 2"
    class="text-center"
    style="padding-top: 40vh"
  >
    <div v-if="!hideMessage" class="text-h5 grey--text mb-4">
      {{ $t("messages.emptyShowSelect") }} {{ $t(`${model}.acc`) }}
    </div>
    <base-text-button
      v-if="permitted"
      color="success"
      :icon="mdiFileImport"
      :button-text="$t(`app_parts.import_${modelPluralize(model)}`)"
      :tooltip-translation="$t('tooltips.show_buttons.import')"
      @click="$router.push({ path: `import`, append: true })"
    ></base-text-button>
  </div>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import store from "../../../../_config/store";
import { mdiFileImport } from "@mdi/js";
import BaseTextButton from "./BaseTextButton";
export default {
  name: "BaseEmptySelectionShow",
  components: { BaseTextButton },
  mixins: [BasicMixin],
  props: {
    model: String,
    hideMessage: Boolean,
  },
  data() {
    return {
      mdiFileImport,
    };
  },
  computed: {
    permitted() {
      if (this.model) {
        return store.state.basic_module.permissions.parts.includes(
          `import_${this.modelPluralize(this.model)}`
        );
      } else return false;
    },
  },
};
</script>

<style scoped></style>
