import BasicMixin from "./BasicMixin";
import RulesMixin from "./RulesMixin";
import jsonToFormData from "json-form-data";

const DataEdit = {
  mixins: [BasicMixin, RulesMixin],
  props: {
    noRedirect: Boolean,
  },
  data: () => ({
    edit_object: {},
    params: {},
    dialog: false,
    edit_mode: true,
  }),
  methods: {
    dataEdit_textAreaTabPress(event, definition_name) {
      const text = this[definition_name];
      const originalSelectionStart = event.target.selectionStart;
      const textStart = text.slice(0, originalSelectionStart);
      const textEnd = text.slice(originalSelectionStart);

      this[definition_name] = `${textStart}    ${textEnd}`;
      event.target.value = this[definition_name];
      event.target.selectionEnd = event.target.selectionStart =
        originalSelectionStart + 4;
    },
    openDialog(originalObject, params, editMode) {
      this.dialog = true;
      this.params = params;
      this.edit_mode = editMode;
      this.edit_object = JSON.parse(JSON.stringify(originalObject));
    },
    dataEdit_defaultSave() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.$refs.template_form && !this.$refs.template_form.validate()) {
        return;
      }

      if (!this.edit_mode) {
        this.dataEdit_create();
      }
      if (this.edit_mode) {
        this.dataEdit_update();
      }
    },
    dataEdit_create(saveParams) {
      if (!saveParams) {
        saveParams = {};
      }
      let redirectResult;

      if (this.noRedirect) {
        redirectResult = null;
      } else {
        redirectResult = (response) => {
          return `${saveParams.scope || ""}/${this.modelPluralize(
            this.model
          )}/${response.body.object.id}`;
        };
      }
      const config = {
        method: "post",
        message: { success: true, action: "create", model: this.model },
        url: this.modelPluralize(this.model),
        reload: this.model,
        close_dialog: true,
        redirect: redirectResult,
        after: this.after,
      };
      this.actionsMixin_action(config, this.edit_object);
    },
    dataEdit_update() {
      if (this.edit_mode) {
        const config = {
          method: "patch",
          message: { success: true, action: "update", model: this.model },
          url: `${this.modelPluralize(this.model)}/${this.edit_object.id}`,
          reload: this.model,
          close_dialog: true,
          after: this.after,
        };
        this.actionsMixin_action(config, this.edit_object);
      }
    },
    after(response) {
      this.$emit("on-success", response.body.object);
    },
    setImages(images) {
      this.images = { ...images };
    },
    async saveData(parent_folder) {
      if (this.$refs.form && !this.$refs.form.validate()) {
        return;
      }
      await this.saveImages(parent_folder);
      await this.saveDialog();
    },
    async saveImages(parent_folder) {
      for (const [key, item] of Object.entries(this.images)) {
        // If loaded img => Save img then save form
        this.document.name = item.name;
        this.document.doc = item;
        this.document.parent_id =
          this.$store.state.basic_module.settings.doc_default_folders[
            parent_folder
          ];
        const formDataDocument = jsonToFormData(this.document, {
          showLeafArrayIndexes: false,
        });
        const config = {
          method: "post",
          message: { success: true, action: "create", model: "document" },
          url: `documents`,
          requestParams: {
            headers: { "Content-Type": "multipart/form-data" },
          },
          after: (response) => {
            this.imagesId = {
              ...this.imagesId,
              ...{ [key]: response.body.object.id },
            };
            // save document id from success response
          },
        };
        await this.actionsMixin_action(config, formDataDocument);
      }
      for (const [key, id] of Object.entries(this.imagesId)) {
        this.edit_object.properties[key] = id;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
        // reset template from
        if (this.$refs.template_form) {
          this.$refs.template_form.resetValidation();
        }
      },
      immediate: true,
    },
  },
};

export default DataEdit;
