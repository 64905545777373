import { mdiPencil, mdiDelete, mdiPlusCircle, mdiContentSave } from "@mdi/js";
const TabsMixin = {
  props: {
    klass: Function,
    input_data: {
      type: [Object, Array],
      default: () => {},
    },
    parameters: Object,
    active: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    newObject: {},
    objects: [],
    totalObjects: 0,
    loading: false,
    changeRoute: true,
    page: 1,
    itemsPerPage: 10,
    sort: { sortBy: [], sortDesc: [] },
    mdiPencil,
    mdiDelete,
    mdiPlusCircle,
    mdiContentSave,
  }),
  methods: {
    fetchDataWrapper() {
      if (!this.active) return;
      if (this.fetchData === undefined) return;

      this.loading = true;
      this.changeRoute = false;
      this.fetchData().then(
        (response) => {
          this.objects = response.body.objects;
          this.totalObjects = response.body.total;
          this.afterFetch(response);
          this.loading = false;
        },
        (response) => {
          this.error_message("load", this.model, response);
          this.loading = false;
        }
      );
    },
    resetPageAndFetch() {
      if (this.page !== 1) this.page = 1;
      else this.fetchDataWrapper();
    },
    afterFetch() {},
    deleteObject(id, name) {
      const params = { id: id, name: name, model: this.model };
      this.$store.commit("deleting/add_action", params);
    },
    newModelText(modelSingular) {
      return (
        this.$t(`${modelSingular}.alert.new`) +
        " " +
        this.$t(`${modelSingular}.${modelSingular}`)
      );
    },
  },
  watch: {
    "$route.params.id"() {
      this.changeRoute = true;
      this.objects = [];
      this.newObject = {};
      this.totalObjects = 0;
      this.resetPageAndFetch();
    },
    sort: {
      handler() {
        this.resetPageAndFetch();
      },
      deep: true,
    },
    active() {
      if (this.changeRoute) this.fetchDataWrapper();
    },
    page() {
      this.fetchDataWrapper();
    },
  },
};

export default TabsMixin;
