<template>
  <div v-if="klass.filter">
    <v-navigation-drawer
      id="sideFilter"
      v-model="filterDrawer"
      app
      hide-overlay
      right
      disable-route-watcher
      temporary
      :width="$vuetify.breakpoint.xsOnly ? '100svw' : '400px'"
      height="100svh"
    >
      <v-card flat>
        <v-card-title class="text-h5">
          Filter
          <v-spacer></v-spacer>
          <base-icon-button
            v-if="
              filterOwner &&
              selected_filter &&
              (!Array.isArray(selected_filter) || selected_filter.length > 0)
            "
            :icon="mdiContentSaveEdit"
            :tooltip-translation="$t('action.save')"
            @click="updateClick()"
          />
          <base-icon-button
            :icon="mdiContentSave"
            :tooltip-translation="$t('action.save_new')"
            @click="saveNewClick()"
          />
          <v-menu
            :left="$vuetify.breakpoint.smAndDown"
            absolute
            :close-on-content-click="false"
            :min-width="$vuetify.breakpoint.mdAndUp ? '200px' : '20vw'"
            max-height="70vh"
          >
            <template #activator="{ on }">
              <base-icon-button
                :icon="mdiCog"
                left
                :tooltip-translation="$t('tooltips.show_buttons.filter_config')"
                v-on="on"
              >
              </base-icon-button>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in fields" :key="index">
                <v-list-item-action>
                  <v-checkbox
                    v-model="selectedFields"
                    :value="item"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>{{
                  $t(makeTranslation(item))
                }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="d-flex justify-end align-center">
            <base-icon-button
              :icon="mdiClose"
              :tooltip-translation="$t('action.close')"
              @click="filterDrawer = false"
            ></base-icon-button>
          </div>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="selected_filter"
            :items="saved_filters"
            :label="$t('filter.actions.subheaders.savedFilters')"
            item-text="name"
            item-value="id"
            return-object
            outlined
            hide-details
            class="pb-2"
            @change="
              filterClick(
                selected_filter && selected_filter.definition
                  ? JSON.parse(JSON.stringify(selected_filter.definition))
                  : {}
              )
            "
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.person.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>

          <base-filter-inner
            v-if="klass.filter.parts"
            v-model="searchData"
            :model="klass.model"
            :parts="klass.filter.parts"
            :selected-fields="selectedFields"
            :klass="klass"
            :filter-drawer="filterDrawer"
          />
        </v-card-text>
      </v-card>

      <template #append>
        <div class="text-center py-4">
          <base-text-button
            :short-key="['shift' + 'backspace']"
            :color="$vuetify.theme.dark ? 'text' : 'primary'"
            :disabled="
              Object.keys($route.query).length < 1 &&
              $route.fullPath !== '/permissions' &&
              $route.fullPath !== `/roles/${$route.params.id}`
            "
            text
            :outlined="false"
            :tooltip-translation="
              Object.keys($route.query).length < 1 &&
              $route.fullPath !== '/permissions' &&
              $route.fullPath !== `/roles/${$route.params.id}`
                ? null
                : $t('tooltips.show_buttons.clear_filter_shortcut')
            "
            :button-text="$t('form.label.cancel')"
            @shortkey="filterClick({})"
            @click="filterClick({})"
          >
            {{ $t("form.label.cancel") }}
          </base-text-button>

          <base-text-button
            :short-key="['shift' + 'enter']"
            color="primary"
            :button-text="$t('form.label.apply')"
            :outlined="false"
            @shortkey="filterClick(searchData)"
            @click="filterClick(searchData)"
          >
          </base-text-button>
        </div>
      </template>
    </v-navigation-drawer>
    <data-sets-edit-dialog
      ref="edit_dialog"
      :selected-fields="selectedFields"
      no-redirect
    />
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import ReloadableMixin from "../../../../_generic/mixins/ReloadableMixin";
import FilteringMixinFixQuery from "../../../mixins/FilteringMixinFixQuery";
import BaseFilterInner from "../../../../_generic/pages/components/base/BaseFilterInner";
import DataSetsEditDialog from "../DataSetsEditDialog";
import BaseIconButton from "../../../../_generic/pages/components/base/BaseIconButton";
import {
  mdiClose,
  mdiFilter,
  mdiMenu,
  mdiContentSave,
  mdiContentSaveEdit,
  mdiCog,
} from "@mdi/js";
import BaseTextButton from "../../../../_generic/pages/components/base/BaseTextButton";
export default {
  name: "SideFilter",
  components: {
    BaseTextButton,
    BaseFilterInner,
    DataSetsEditDialog,
    BaseIconButton,
  },
  mixins: [BasicMixin, ReloadableMixin, FilteringMixinFixQuery],
  props: {
    prevPath: String,
    klass: Function,
    mode: String,
    searchModel: String,
    noRouterPush: Boolean,
  },
  data: () => ({
    filterDrawer: false,
    searchData: {},
    saved_filters: [],
    selectedFields: [],
    selected_filter: null,
    allowFilterCheck: {},
    reloadingModels: ["data_set"],
    mdiClose,
    mdiFilter,
    mdiCog,
    mdiContentSave,
    mdiContentSaveEdit,
    mdiMenu,
  }),
  computed: {
    fields() {
      return this.klass.filter.parts.fields.concat(
        this.klass.filter.parts.extended_fields
      );
    },
    filterOwner() {
      if (this.selected_filter) {
        return (
          this.selected_filter.person_id ===
          this.$store.state.basic_module.current_user.person_id
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    "$route.query"() {
      if (Object.keys(this.$route.query).length < 1) {
        this.allowFilterCheck = {};
      }
    },
    selectedFields(newVar, oldVar) {
      // remove field after uncheck
      delete this.searchData[oldVar.find((i) => !newVar.includes(i))];
    },
    "klass.model"() {
      this.selected_filter = null;
      this.searchData = {};
      this.searchData = {
        ...this.filteringMixin_fixData(this.searchData),
        ...this.filteringMixin_fixData(this.$route.query),
      };
      this.allowFilterCheck = JSON.parse(JSON.stringify(this.searchData));
      this.setFields();
      this.loadSavedFilters();
    },
    selected_filter() {
      this.$emit("selected-filter", this.selected_filter);
    },
  },
  created() {
    this.searchData = {
      ...this.filteringMixin_fixData(this.searchData),
      ...this.filteringMixin_fixData(this.$route.query),
    };
    this.allowFilterCheck = JSON.parse(JSON.stringify(this.searchData));
    this.loadSavedFilters();
    this.setFields();
  },
  methods: {
    filterClick(filter) {
      const jsonCompare = (obj1, obj2) => {
        if (
          Object.prototype.toString.call(obj1) ===
          Object.prototype.toString.call(obj2)
        ) {
          if (
            Object.prototype.toString.call(obj1) === "[object Object]" ||
            Object.prototype.toString.call(obj1) === "[object Array]"
          ) {
            if (Object.keys(obj1).length !== Object.keys(obj2).length) {
              return false;
            }
            return Object.keys(obj1).every(function (key) {
              return jsonCompare(obj1[key], obj2[key]);
            });
          }
          return obj1 === obj2;
        }
        return false;
      };
      // setTimeout is fix for combobox
      setTimeout(() => {
        if (!jsonCompare(filter, this.allowFilterCheck)) {
          // run search
          if (filter) {
            this.allowFilterCheck = this.filteringMixin_fixData(
              JSON.parse(JSON.stringify(filter))
            );
          }
          if (!this.noRouterPush) {
            this.$router.push({
              path: `/${
                this.searchModel || this.modelPluralize(this.klass.model)
              }`,
              query: this.filteringMixin_fixData(filter),
            });
          }
          this.searchData = this.filteringMixin_fixData(filter);
          this.setFields();
          this.$emit("filter", this.searchData);

          if (this.$vuetify.breakpoint.smAndDown) {
            this.filterDrawer = false;
          }
        }
      }, 20);
    },
    openFilter() {
      this.$store.dispatch("loadable/fetchItems", "report");
      this.filterDrawer = !this.filterDrawer;
      if (this.mode === "tree") {
        this.$emit("update:mode", "list");
      }
    },
    loadSavedFilters() {
      this.$http
        .get("data_sets", {
          params: {
            filter: { target_entity: this.klass.model },
            sort_by: ["person"],
            sort_desc: [false],
          },
        })
        .then(
          (response) => {
            this.saved_filters = response.body.objects;
            if (this.prevPath && this.prevPath.split("/")[1] === "data_sets") {
              this.selected_filter = this.saved_filters.find(
                (i) => i.id === parseInt(this.prevPath.split("/")[2])
              );
            }
            if (
              this.$store.state.reloading &&
              this.$store.state.reloading.reloading_model === "data_set" &&
              this.$store.state.reloading.data.object &&
              this.$store.state.reloading.data.object.id
            ) {
              this.selected_filter = this.saved_filters.find(
                (i) => i.id === this.$store.state.reloading.data.object.id
              );
              if (this.selected_filter) {
                this.setFields();
                this.filterClick(this.selected_filter.definition);
              }
            }
          },
          (response) => {
            this.error_message("load", "filter", response);
          }
        );
    },
    setFields() {
      this.selectedFields = [];
      this.removeUnusedFields();
      const searchKeys = Object.keys(this.searchData);
      if (this.fields) {
        if (
          searchKeys.length === 0 ||
          (searchKeys.length === 1 && searchKeys[0] === "persistence")
        ) {
          this.selectedFields = this.klass.filter.parts.fields;
          if (searchKeys.length === 0) {
            this.selected_filter = null;
          }
        } else {
          this.selectedFields = searchKeys.filter((i) =>
            this.fields.includes(i)
          );
        }
      }
    },
    makeTranslation(item) {
      if (
        (this.isModel("issue") && item === "person_id") ||
        (this.isModel("activity") &&
          ["entity_id", "person_id", "connector_id"].includes(item))
      ) {
        return `form.label.${this.klass.model}.${item}`;
      }

      return `form.label.${item}`;
    },
    saveNewClick() {
      const dataSet = {
        target_entity: this.toModelUpperCase(this.klass.model),
        definition: this.searchData,
      };
      this.$refs.edit_dialog.openDialog(dataSet, {}, false);
    },
    removeUnusedFields() {
      for (const key of Object.keys(this.searchData)) {
        if (
          (Array.isArray(this.searchData[key]) &&
            this.searchData[key].length === 0) ||
          this.searchData[key] === null ||
          this.searchData[key] === undefined ||
          this.searchData[key] === "" ||
          (typeof this.searchData[key] === "object" &&
            Object.keys(this.searchData[key]).length === 0)
        ) {
          delete this.searchData[key];
        }
      }
    },
    updateClick() {
      this.removeUnusedFields();
      this.selected_filter.definition = this.searchData;
      const config = {
        method: "patch",
        message: { success: true, action: "update", model: "data_set" },
        url: `data_sets/${this.selected_filter.id}`,
        reload: "data_set",
        close_dialog: true,
      };
      this.actionsMixin_action(config, this.selected_filter);
    },
    reloadComponent() {
      this.loadSavedFilters();
    },
  },
};
</script>

<style scoped></style>
