<template>
  <div>
    <v-btn-toggle
      :value="value"
      class="btn-toggle"
      active-class="light-active"
      @change="$emit('input', $event)"
    >
      <v-btn
        v-for="(item, key) in basicMixin_toSelect(options)"
        :key="key"
        :value="item.value"
        text
        class="btn-toggle-btn"
        small
      >
        {{ item.text }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";

export default {
  name: "FilterToggle",
  mixins: [BasicMixin],
  props: {
    value: {
      type: [Number, String],
    },
    options: Object,
  },
};
</script>

<style scoped lang="scss">
.btn-toggle {
  min-width: 100%;

  .btn-toggle-btn {
    width: 50%;
  }
}
</style>
