const ReloadableMixin = {
  watch: {
    "$store.state.reloading.timestamp"() {
      if (
        (this.reloadingModels || [this.model]).includes(
          this.$store.state.reloading.reloading_model
        )
      ) {
        this.reloadComponent();
      }
    },
  },
};

export default ReloadableMixin;
