<template>
  <div
    class="mr-2"
    :class="{
      'mb-2': bottomMargin,
      'mt-2': topMargin,
      'text-truncate': truncate,
      'd-inline-block': !truncate,
    }"
  >
    <base-avatar
      v-if="src || iconType"
      :avatar-tile="to === 'assets'"
      :avatar-size="30"
      :icon-size="20"
      :icon-type="iconType"
      :src="src"
    >
    </base-avatar>

    <base-tooltip-icon
      v-else-if="icon"
      :icon="icon"
      :label="label"
      small
    ></base-tooltip-icon>

    <router-link
      v-if="activeLink"
      :to="`/${to}/${id}`"
      @click.native="$event.stopImmediatePropagation()"
      >{{ text }}</router-link
    >

    <span v-else>{{ text }}</span>

    <span v-if="appendText">{{ appendText }}</span>
  </div>
</template>

<script>
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import BaseAvatar from "../../../_generic/pages/components/base/BaseAvatar";
import BaseTooltipIcon from "../../../_generic/pages/components/base/BaseTooltipIcon";

export default {
  name: "LinkChecked",
  components: { BaseAvatar, BaseTooltipIcon },
  mixins: [BasicMixin],
  props: {
    id: {
      type: [Number, String],
    },
    to: String,
    text: String,
    src: Object,
    discardedAt: String,
    icon: String,
    iconType: String,
    appendText: String,
    bottomMargin: Boolean,
    topMargin: Boolean,
    disabled: Boolean,
    truncate: {
      type: Boolean,
      default: false,
    },
    label: String,
  },
  computed: {
    activeLink() {
      return this.canSeeAppPart(this.to) && !this.discardedAt && !this.disabled;
    },
  },
};
</script>

<style scoped></style>
