const state = {
  actions: [],
  item: null,
};

const mutations = {
  add_action(state, action) {
    state.actions.push(action);
  },
  add_item(state, item) {
    state.item = item;
  },
  remove_item(state) {
    state.item = null;
  },
  remove_action(state) {
    state.actions.pop();
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
