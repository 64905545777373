import colors from "vuetify/lib/util/colors";

const ColorHelper = {
  data() {
    return {
      allColors: [
        "black",
        "blue-grey",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "red",
        "pink",
        "brown",
        "grey",
      ],
      lightColors: ["yellow", "lime", "amber", "grey"],
    };
  },
  methods: {
    textColor(color) {
      return this.lightColors.includes(color) ? "black--text" : "white--text";
    },
    colorToHex(color) {
      let ccColor = color.replace(/-([a-z])/g, (_, p) => p.toUpperCase());
      return colors[ccColor].base;
    },
  },
};

export default ColorHelper;
