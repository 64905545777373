import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { getTranslation } from "../mixins/HelperFunctions";

class Field extends _DatabaseModel {
  static model = "field";
  static editDialogComponent = "field-edit-dialog";
  static createNewObject = {
    object: { condition: { field_key: null } },
    params: {},
  };

  static tableHeaders = [
    {
      textKey: "form.label.name",
      sortable: false,
      translated: true,
      value: "name",
    },
    {
      textKey: "template.label.key",
      sortable: false,
      value: "key",
    },
    {
      textKey: "template.label.data_type",
      sortable: false,
      value: "data_type",
      translationPrefix: "field.data_types",
    },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["key", "time_filter", "data_type"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static data_types = [
    "String",
    "Text",
    "Integer",
    "Date",
    "DateTime",
    "Time",
    "Boolean",
    "Switch",
    "Select",
    "Radio",
    "Picture",
    "Person",
    "Asset",
    "Issue",
    "Password",
  ];

  static operations = ["export"];

  static cardFields(object) {
    return {
      title: getTranslation(object.name),
      subtitle: i18n.t(`field.data_types.${object.data_type}`),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "template.label.key",
        value: object.key,
        type: "plain",
      },
      {
        labelKey: "template.label.data_type",
        value: i18n.t(`field.data_types.${object.data_type}`),
        type: "plain",
      },
    ];
  }
  static tabs = [
    this.detailTab,
    {
      titleKey: "template_assignment.usage",
      permission: "fields_tab_usage",
      component: "FieldUsageTab",
      input_data: (context) => context.object,
      disabled: () => false,
    },
  ];
}

export default Field;
