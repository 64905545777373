<template>
  <v-row>
    <v-col cols="6">
      <input-date-picker-dialog
        v-model="innerDate"
        :label="label"
        :rules="rules"
      ></input-date-picker-dialog>
    </v-col>

    <v-col cols="6">
      <input-time-picker-dialog
        v-model="innerTime"
        :rules="rules"
      ></input-time-picker-dialog>
    </v-col>
  </v-row>
</template>

<script>
import InputDatePickerDialog from "./InputDatePickerDialog";
import InputTimePickerDialog from "./InputTimePickerDialog";
import RulesMixin from "../../../mixins/RulesMixin";
export default {
  name: "InputDateTimePickerDialog",
  components: { InputDatePickerDialog, InputTimePickerDialog },
  mixins: [RulesMixin],
  props: {
    value: String,
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      innerDate: null,
      innerTime: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.innerDate = this.value
          ? this.$moment(this.value).format("YYYY-MM-DD")
          : "";
        this.innerTime = this.value
          ? this.$moment(this.value).format("HH:mm")
          : "";
      },
      immediate: true,
    },
    innerDate(newVar) {
      if (newVar) {
        if (!isNaN(Date.parse(newVar)) && [0, 10].includes(newVar.length)) {
          let momentObj = this.$moment(
            `${newVar} ${this.innerTime}`,
            "YYYY-MM-DD HH:mm"
          );
          let dateTime = momentObj.toDate().toISOString();
          this.$emit("input", dateTime);
        }
      } else {
        this.$emit("input", "");
      }
    },
    innerTime(newVar) {
      if (newVar) {
        let momentObj = this.$moment(
          `${this.innerDate} + ${newVar}`,
          "YYYY-MM-DD HH:mm"
        );
        let dateTime = momentObj.toDate().toISOString();
        this.$emit("input", dateTime);
      }
    },
  },
};
</script>

<style scoped></style>
