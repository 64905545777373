<template>
  <v-tooltip
    v-if="state"
    bottom
    open-delay="500"
    :disabled="
      $vuetify.breakpoint.smAndDown || !checkTranslation(state.description)
    "
  >
    <template #activator="{ on }">
      <v-chip
        :color="!group || active ? state.color : undefined"
        class="text-no-wrap"
        :class="[
          { 'mb-1': bottomMargin, 'mr-1': rightMargin },
          !group || active ? textColor(state.color) : '',
        ]"
        :small="small"
        style="max-width: 220px"
        :close="closable"
        :value="value"
        v-on="group ? { ...on, click: () => $emit('click', null) } : on"
        @click:close="$emit('click:close', null)"
      >
        <v-icon
          v-if="!group || active"
          left
          small
          :class="textColor(state.color)"
          >{{ mdiFlag }}</v-icon
        >
        <span class="text-truncate">{{ getTranslation(state.name) }}</span>
      </v-chip>
    </template>
    <span v-if="checkTranslation(state.description)">{{
      getTranslation(state.description)
    }}</span>
  </v-tooltip>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import ColorHelper from "../../../../_generic/mixins/ColorHelper";
import { mdiFlag } from "@mdi/js";

export default {
  name: "IssueStateTag",
  mixins: [BasicMixin, ColorHelper],
  props: {
    state: Object,
    closable: Boolean,
    bottomMargin: Boolean,
    rightMargin: Boolean,
    value: Object,
    group: Boolean,
    active: Boolean,
    small: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mdiFlag,
  }),
};
</script>

<style scoped></style>
