import Category from "../classes/Category";

class PersonCategory extends Category {
  static model = "person_category";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static createNewObject = {
    ...Category.createNewObject,
    object: { icon: "person" },
  };
}

export default PersonCategory;
