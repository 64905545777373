import _DatabaseModel from "./_DatabaseModel";
import { mdiAccount, mdiAccountCancel } from "@mdi/js";

class User extends _DatabaseModel {
  static model = "user";
  static editDialogComponent = "users-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.email", value: "email" },
    { textKey: "person.person", value: "person.name" },
    {
      textKey: "license.label.type",
      value: "tier",
      translationPrefix: "user.tier_types",
    },
    { textKey: "user.label.two_factor_auth", value: "otp_required_for_login" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "resend",
      "twoFactorAuth",
      "impersonate",
      "activity",
      "destroy",
    ],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: [
        "email",
        "person_id",
        "tier",
        "time_filter",
        "blocked",
        "otp_required_for_login",
      ],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static otp_required_for_login_options = {
    true: "filter.otp_required_for_login_levels.two_factor_auth",
    false: "filter.otp_required_for_login_levels.without_two_factor_auth",
  };

  static blocked_options = {
    true: "filter.blocked_levels.blocked",
    false: "filter.blocked_levels.not_blocked",
  };

  static tier_types = ["full", "limited", "external", "support", "admin"];

  static cardFields(object) {
    return {
      src: {
        cardIcon: object.blocked ? mdiAccountCancel : mdiAccount,
        color: object.blocked ? "error" : "success",
      },
      title: object.email,
      subtitle: object.person.name,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "person.person",
        value: object.person,
        type: "link",
        to: "persons",
      },
      {
        labelKey: "user.label.phone",
        value: object.phone,
        type: "plain",
      },
      {
        labelKey: "user.label.block_status",
        value: object.blocked,
        type: "boolean",
      },
      {
        labelKey: "user.label.two_factor_auth",
        value: object.otp_required_for_login,
        type: "boolean",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [this.detailTab];
}

export default User;
