import _DatabaseModel from "./_DatabaseModel";

class Control extends _DatabaseModel {
  static model = "control";
  static showSize = 8;
  static editDialogComponent = "control-edit-dialog";
  static tableHeaders = [{ textKey: "form.label.name", value: "name" }];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];
}

export default Control;
