<template>
  <v-card class="my-2" rounded="lg">
    <v-card-text>
      <div :class="{ 'not-read': !item.read_at }">
        {{ item.message }}
        <v-tooltip
          v-if="item.ref"
          right
          open-delay="500"
          :disabled="$vuetify.breakpoint.smAndDown"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              :color="$vuetify.theme.dark ? 'text' : 'primary'"
              small
              :ripple="false"
              class="mb-1"
              @click="$router.push(item.ref)"
              v-on="on"
              ><v-icon small>
                {{ mdiOpenInNew }}
              </v-icon></v-btn
            >
          </template>
          <span>{{ $t("notification.label.open_object") }}</span>
        </v-tooltip>
      </div>

      <v-row class="align-baseline">
        <v-col cols="8" md="6">
          {{ $t("notification.label.created") }}:
          <base-tooltip-date-data
            :datetime="item.created_at"
          ></base-tooltip-date-data>
        </v-col>

        <v-col cols="4" md="6" class="text-right">
          <base-icon-button
            v-if="!item.read_at"
            :icon="mdiEye"
            :tooltip-translation="$t('notification.label.mark_as_read')"
            @click="read"
          />
          <template v-else>
            <base-tooltip-icon
              v-if="$vuetify.breakpoint.xsOnly"
              :icon="mdiEyeCheck"
              :label="$t('notification.label.read')"
              clazz="mr-2"
            />
            <span v-if="null !== item.read_at && !$vuetify.breakpoint.xs">{{
              $t("notification.label.read")
            }}</span>
            <base-tooltip-date-data
              v-if="null !== item.read_at && !$vuetify.breakpoint.xs"
              :datetime="item.read_at"
            ></base-tooltip-date-data>

            <template v-else>
              {{
                $vuetify.breakpoint.smAndUp
                  ? $t("form.label.information_unavailable")
                  : undefined
              }}
            </template>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BasicMixin from "../../../_generic/mixins/BasicMixin";
import BaseTooltipDateData from "../../../_generic/pages/components/base/BaseTooltipDateData";
import BaseIconButton from "../../../_generic/pages/components/base/BaseIconButton";
import BaseTooltipIcon from "../../../_generic/pages/components/base/BaseTooltipIcon";
import { mdiEyeCheck, mdiEye, mdiOpenInNew } from "@mdi/js";

export default {
  name: "NotificationCard",
  components: {
    BaseIconButton,
    BaseTooltipDateData,
    BaseTooltipIcon,
  },
  mixins: [BasicMixin],
  props: {
    item: Object,
  },
  data: () => ({
    mdiEyeCheck,
    mdiEye,
    mdiOpenInNew,
  }),
  methods: {
    read() {
      const config = {
        method: "post",
        message: { action: "load", model: "notification" },
        url: `notifications/${this.item.id}/read`,
        after: this.after,
      };
      this.actionsMixin_action(config, {});
    },
    after(response) {
      this.$store.dispatch("notifications/decrement");
      this.$emit("update-item", { read_at: response.body.read_at });
    },
  },
};
</script>

<style scoped lang="scss">
.not-read {
  font-weight: bold;
}
</style>
