<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    max-width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern v-model="dialog" :label="title" />

      <v-card-text class="pt-6">
        <person-assets-tree-view
          ref="tree"
          :model="type"
          return-object
          :selectable="!multiselect"
          :multiselect="multiselect"
          :filter="filter"
          @selected="selectInTree($event)"
        />
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        positive-translation="action.select"
        negative-translation="action.cancel"
        @positive="submit()"
        @negative="dialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAssetsTreeView from "../../persons/components/PersonAssetsTreeView";
import BaseVCard from "../../../../_generic/pages/components/base/BaseVCard";
import BasicMixin from "../../../../_generic/mixins/BasicMixin";

export default {
  name: "TreeSelect",
  components: { PersonAssetsTreeView },
  mixins: [BaseVCard, BasicMixin],
  props: {
    title: String,
    type: String,
    multiselect: Boolean,
    filter: Object,
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.tree.$data.isOpen = [];
        this.$refs.tree.$data.active = [];
      }
    },
  },
  methods: {
    selectInTree(item) {
      this.$store.state.app_state.selected_objects = item;
    },
    submit() {
      this.$emit("input", this.$store.state.app_state.selected_objects);
      this.dialog = false;
    },
    openDialog(items) {
      this.$store.state.app_state.selected_objects = items;
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
