import {
  mdiDelete,
  mdiEye,
  mdiPencil,
  mdiPlusThick,
  mdiSquareEditOutline,
  mdiClipboardOutline,
  mdiClipboardEditOutline,
} from "@mdi/js";

const headersSeeUpdateDestroyManage = [
  { icon: mdiEye, type: "see", bit: 0 },
  { icon: mdiPencil, type: "update", bit: 4 },
  { icon: mdiDelete, type: "destroy", bit: 6 },
  { icon: mdiSquareEditOutline, type: "manage_permissions", bit: 8 },
];

const headersTree = [
  { icon: mdiEye, type: "see", bit: 0 },
  { icon: mdiPencil, type: "update", bit: 4 },
  { icon: mdiPlusThick, type: "create", bit: 2 },
  { icon: mdiDelete, type: "destroy", bit: 6 },
  { icon: mdiSquareEditOutline, type: "manage_permissions", bit: 8 },
];

export default Object.freeze({
  person: headersTree,
  asset: headersTree,
  document: headersTree,
  contract: headersSeeUpdateDestroyManage,
  service: [
    ...headersSeeUpdateDestroyManage,
    { icon: mdiClipboardOutline, type: "see_issues", bit: 10 },
    { icon: mdiClipboardEditOutline, type: "update_issues", bit: 12 },
  ],
  kpi: headersSeeUpdateDestroyManage,
  warehouse: headersSeeUpdateDestroyManage,
  price_list: headersSeeUpdateDestroyManage,
  role: headersSeeUpdateDestroyManage,
  automated_action: headersSeeUpdateDestroyManage,
  template_assignment: [
    { icon: mdiEye, type: "see_in_object", bit: 10 },
    { icon: mdiPencil, type: "update_in_object", bit: 12 },
    { icon: mdiSquareEditOutline, type: "manage_permissions", bit: 8 },
  ],
  application_part: [
    { icon: mdiEye, type: "see", bit: 0 },
    { icon: mdiSquareEditOutline, type: "manage_permissions", bit: 8 },
  ],
});
