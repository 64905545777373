import _DatabaseModel from "./_DatabaseModel";
import {
  mdiAccount,
  mdiAccountGroup,
  mdiAccountTie,
  mdiAlarmPanelOutline,
  mdiAudioInputRca,
  mdiBattery,
  mdiBedKingOutline,
  mdiBoomGate,
  mdiBullhorn,
  mdiBullhornOutline,
  mdiCar,
  mdiCarBattery,
  mdiCarEstate,
  mdiCarInfo,
  mdiCarMultiple,
  mdiCarWrench,
  mdiCardAccountDetails,
  mdiCctv,
  mdiCellphoneBasic,
  mdiCertificate,
  mdiChairRolling,
  mdiCog,
  mdiConsoleNetworkOutline,
  mdiCreditCardWirelessOutline,
  mdiCrown,
  mdiDatabase,
  mdiDatabaseLockOutline,
  mdiDatabaseRefreshOutline,
  mdiDesk,
  mdiDesktopTower,
  mdiDomain,
  mdiDoor,
  mdiEarth,
  mdiElectricSwitch,
  mdiEvStation,
  mdiFan,
  mdiFileCabinet,
  mdiFileTableBoxOutline,
  mdiFire,
  mdiFireExtinguisher,
  mdiFireHydrant,
  mdiFolderAccountOutline,
  mdiFolderLock,
  mdiFolderOutline,
  mdiGarageVariant,
  mdiGateArrowRight,
  mdiGestureTapButton,
  mdiGraphOutline,
  mdiHammerWrench,
  mdiHandshake,
  mdiHarddisk,
  mdiHeatingCoil,
  mdiHome,
  mdiHomeBattery,
  mdiHomeGroup,
  mdiHomeRoof,
  mdiKeyboard,
  mdiLadder,
  mdiLan,
  mdiLaptop,
  mdiLeak,
  mdiLightbulbGroupOutline,
  mdiLightbulbOutline,
  mdiMapMarker,
  mdiMeterElectricOutline,
  mdiMeterGasOutline,
  mdiMonitor,
  mdiMonitorDashboard,
  mdiMotionSensor,
  mdiMouse,
  mdiNoteTextOutline,
  mdiOfficeBuilding,
  mdiPackageVariantClosed,
  mdiPineTree,
  mdiPostLamp,
  mdiPowerPlug,
  mdiPrinter,
  mdiRadioTower,
  mdiRecordPlayer,
  mdiRouterWireless,
  mdiSafeSquare,
  mdiScale,
  mdiSecurityNetwork,
  mdiServer,
  mdiServerNetwork,
  mdiServerSecurity,
  mdiSetTopBox,
  mdiSim,
  mdiSitemap,
  mdiSmartCardReaderOutline,
  mdiSmokeDetectorVariant,
  mdiSnowflake,
  mdiSolarPanelLarge,
  mdiSolarPowerVariant,
  mdiSolarPowerVariantOutline,
  mdiSprinklerFire,
  mdiStairs,
  mdiSwitch,
  mdiTablet,
  mdiTelevision,
  mdiThermometer,
  mdiThermometerHigh,
  mdiTools,
  mdiTrainCarFlatbedCar,
  mdiTransmissionTower,
  mdiTurbine,
  mdiTurnstileOutline,
  mdiVideo,
  mdiWardrobeOutline,
  mdiWaterBoiler,
  mdiWifi,
  mdiWindTurbine,
  mdiWindowClosedVariant,
  mdiCloudCheckOutline,
  mdiCloudLockOutline,
  mdiCloudLockOpenOutline,
  mdiCloudSyncOutline,
  mdiClouds,
  mdiCloudUploadOutline,
  mdiVpn,
  mdiWan,
  mdiLanConnect,
  mdiServerNetworkOutline,
  mdiNas,
  mdiApplicationOutline,
  mdiDatabaseCogOutline,
} from "@mdi/js";

class Category extends _DatabaseModel {
  static model = "category";
  static bottomNav = { mobile: ["card", "tree"], desktop: ["list", "tree"] };
  static showSize = 7;
  static autofillParent = true;
  static editDialogComponent = "categories-edit-dialog";

  static createNewObject = {
    object: {},
    params: { direct: true },
  };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "removeFromHierarchy",
      "filterCategory",
      "manageSubscription",
      "activity",
      "destroy",
    ],
    reloadingModels: [
      "asset_category",
      "person_category",
      "template_assignment",
      "category_threat",
      "threat",
    ],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.name,
      src: { category_id: object.id },
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "template.templates",
      permission: "categories_tab_templates",
      component: "TemplatesAssignmentsTab",
      input_data: (context) => context.object.template_assignments,
      parameters: { entity_type: "Category" },
      disabled: (context) =>
        !context.$store.state.basic_module.permissions.configuration,
    },
  ];

  static categoryIcons = {
    accountGroup: { icon: mdiAccountGroup, type: ["persons"] },
    accountTie: { icon: mdiAccountTie, type: ["persons"] },
    address: { icon: mdiCardAccountDetails, type: ["persons"] },
    alarmPanelOutline: { icon: mdiAlarmPanelOutline, type: ["security"] },
    applicationOutline: { icon: mdiApplicationOutline, type: ["ikt"] },
    asset: { icon: mdiCog, type: ["basic", "cyber_sec"] },
    audioInputRca: { icon: mdiAudioInputRca, type: ["security"] },
    battery: { icon: mdiBattery, type: ["basic", "energetics"] },
    bedKingOutline: { icon: mdiBedKingOutline, type: ["buildings"] },
    boomGate: { icon: mdiBoomGate, type: ["security", "cars"] },
    bullhorn: { icon: mdiBullhorn, type: ["security"] },
    bullhornOutline: { icon: mdiBullhornOutline, type: ["security"] },
    car: { icon: mdiCar, type: ["cars"] },
    carBattery: {
      icon: mdiCarBattery,
      type: ["security", "cars", "energetics"],
    },
    carEstate: { icon: mdiCarEstate, type: ["cars"] },
    carInfo: { icon: mdiCarInfo, type: ["cars"] },
    carMultiple: { icon: mdiCarMultiple, type: ["cars"] },
    carWrench: { icon: mdiCarWrench, type: ["cars"] },
    cctv: { icon: mdiCctv, type: ["security"] },
    cellphoneBasic: { icon: mdiCellphoneBasic, type: ["ikt"] },
    certificate: { icon: mdiCertificate, type: ["persons", "ikt"] },
    chairRolling: { icon: mdiChairRolling, type: ["buildings"] },
    cloudCheckOutline: { icon: mdiCloudCheckOutline, type: ["ikt"] },
    cloudLockOpenOutline: { icon: mdiCloudLockOpenOutline, type: ["ikt"] },
    cloudLockOutline: { icon: mdiCloudLockOutline, type: ["ikt"] },
    clouds: { icon: mdiClouds, type: ["ikt"] },
    cloudSyncOutline: { icon: mdiCloudSyncOutline, type: ["ikt"] },
    cloudUploadOutline: { icon: mdiCloudUploadOutline, type: ["ikt"] },
    company: { icon: mdiHome, type: ["basic"] },
    consoleNetworkOutline: {
      icon: mdiConsoleNetworkOutline,
      type: ["cyber_sec"],
    },
    creditCardWirelessOutline: {
      icon: mdiCreditCardWirelessOutline,
      type: ["security"],
    },
    crown: { icon: mdiCrown, type: ["cyber_sec"] },
    database: { icon: mdiDatabase, type: ["ikt"] },
    databaseCogOutline: { icon: mdiDatabaseCogOutline, type: ["ikt"] },
    databaseLockOutline: { icon: mdiDatabaseLockOutline, type: ["cyber_sec"] },
    databaseRefreshOutline: {
      icon: mdiDatabaseRefreshOutline,
      type: ["cyber_sec"],
    },
    desk: { icon: mdiDesk, type: ["buildings"] },
    desktopTower: { icon: mdiDesktopTower, type: ["ikt"] },
    domain: { icon: mdiDomain, type: ["organization"] },
    door: { icon: mdiDoor, type: ["buildings"] },
    electricSwitch: { icon: mdiElectricSwitch, type: ["security"] },
    evStation: { icon: mdiEvStation, type: ["energetics"] },
    extinguisher: { icon: mdiFireExtinguisher, type: ["security"] },
    fan: { icon: mdiFan, type: ["buildings"] },
    fileCabinet: { icon: mdiFileCabinet, type: ["buildings"] },
    fileTableBoxOutline: { icon: mdiFileTableBoxOutline, type: ["security"] },
    fire: { icon: mdiFire, type: ["security"] },
    fireHydrant: { icon: mdiFireHydrant, type: ["security"] },
    folderAccountOutline: {
      icon: mdiFolderAccountOutline,
      type: ["cyber_sec"],
    },
    folderLock: { icon: mdiFolderLock, type: ["basic"] },
    folderOutline: { icon: mdiFolderOutline, type: ["basic"] },
    garageVariant: { icon: mdiGarageVariant, type: ["buildings"] },
    gateArrowRight: { icon: mdiGateArrowRight, type: ["security", "cars"] },
    gestureTapButton: { icon: mdiGestureTapButton, type: ["security"] },
    graphOutline: { icon: mdiGraphOutline, type: ["organization"] },
    hammerWrench: { icon: mdiHammerWrench, type: ["basic"] },
    handshake: { icon: mdiHandshake, type: ["cyber_sec"] },
    hdd: { icon: mdiHarddisk, type: ["security", "ikt"] },
    heatingCoil: { icon: mdiHeatingCoil, type: ["buildings"] },
    homeBattery: { icon: mdiHomeBattery, type: ["energetics"] },
    homeGroup: { icon: mdiHomeGroup, type: ["basic"] },
    homeRoof: { icon: mdiHomeRoof, type: ["buildings"] },
    keyboard: { icon: mdiKeyboard, type: ["ikt"] },
    ladder: { icon: mdiLadder, type: ["buildings"] },
    lan: { icon: mdiLan, type: ["ikt", "cyber_sec"] },
    lanConnect: { icon: mdiLanConnect, type: ["ikt"] },
    laptop: { icon: mdiLaptop, type: ["ikt"] },
    leak: { icon: mdiLeak, type: ["security"] },
    lightbulbOutline: { icon: mdiLightbulbOutline, type: ["buildings"] },
    lightbulbGroupOutline: {
      icon: mdiLightbulbGroupOutline,
      type: ["buildings"],
    },
    marker: { icon: mdiMapMarker, type: ["basic"] },
    meterElectricOutline: {
      icon: mdiMeterElectricOutline,
      type: ["buildings"],
    },
    meterGasOutline: { icon: mdiMeterGasOutline, type: ["buildings"] },
    monitor: { icon: mdiMonitor, type: ["security", "ikt"] },
    monitorDashboard: { icon: mdiMonitorDashboard, type: ["security", "ikt"] },
    motionSensor: { icon: mdiMotionSensor, type: ["security"] },
    mouse: { icon: mdiMouse, type: ["security", "ikt"] },
    nas: { icon: mdiNas, type: ["ikt"] },
    noteTextOutline: { icon: mdiNoteTextOutline, type: ["ikt"] },
    officeBuilding: { icon: mdiOfficeBuilding, type: ["basic"] },
    packageVariantClosed: {
      icon: mdiPackageVariantClosed,
      type: ["buildings"],
    },
    person: { icon: mdiAccount, type: ["persons"] },
    plug: {
      icon: mdiPowerPlug,
      type: ["cars", "ikt", "cyber_sec", "energetics"],
    },
    postLamp: { icon: mdiPostLamp, type: ["energetics"] },
    print: { icon: mdiPrinter, type: ["ikt"] },
    recordPlayer: { icon: mdiRecordPlayer, type: ["security"] },
    region: { icon: mdiEarth, type: ["organization", "basic"] },
    routerWireless: { icon: mdiRouterWireless, type: ["ikt"] },
    safeSquare: { icon: mdiSafeSquare, type: ["buildings"] },
    scale: { icon: mdiScale, type: ["security", "cars"] },
    securityNetwork: { icon: mdiSecurityNetwork, type: ["cyber_sec"] },
    server: { icon: mdiServer, type: ["ikt"] },
    serverNetwork: { icon: mdiServerNetwork, type: ["cyber_sec"] },
    serverNetworkOutline: { icon: mdiServerNetworkOutline, type: ["ikt"] },
    serverSecurity: { icon: mdiServerSecurity, type: ["cyber_sec"] },
    setTopBox: { icon: mdiSetTopBox, type: ["security", "ikt"] },
    sim: { icon: mdiSim, type: ["ikt"] },
    sitemap: { icon: mdiSitemap, type: ["organization"] },
    smartCardReaderOutline: { icon: mdiSmartCardReaderOutline, type: ["ikt"] },
    smokeDetectorVariant: { icon: mdiSmokeDetectorVariant, type: ["security"] },
    snowflake: { icon: mdiSnowflake, type: ["buildings"] },
    solarPanelLarge: { icon: mdiSolarPanelLarge, type: ["energetics"] },
    solarPowerVariant: { icon: mdiSolarPowerVariant, type: ["energetics"] },
    solarPowerVariantOutline: {
      icon: mdiSolarPowerVariantOutline,
      type: ["energetics"],
    },
    sprinklerFire: { icon: mdiSprinklerFire, type: ["security"] },
    stairs: { icon: mdiStairs, type: ["buildings"] },
    switch: { icon: mdiSwitch, type: ["ikt"] },
    tabletAndroid: { icon: mdiTablet, type: ["ikt"] },
    thermometer: { icon: mdiThermometer, type: ["buildings"] },
    thermometerHigh: { icon: mdiThermometerHigh, type: ["technology"] },
    tools: { icon: mdiTools, type: ["buildings"] },
    tower: { icon: mdiRadioTower, type: ["ikt"] },
    trainCarFlatbedCar: { icon: mdiTrainCarFlatbedCar, type: ["cars"] },
    transmissionTower: { icon: mdiTransmissionTower, type: ["energetics"] },
    tree: { icon: mdiPineTree, type: ["basic"] },
    turbine: { icon: mdiTurbine, type: ["energetics"] },
    turnstileOutline: { icon: mdiTurnstileOutline, type: ["security"] },
    tv: { icon: mdiTelevision, type: ["buildings"] },
    video: { icon: mdiVideo, type: ["security"] },
    vpn: { icon: mdiVpn, type: ["ikt"] },
    wan: { icon: mdiWan, type: ["ikt"] },
    wardrobeOutline: { icon: mdiWardrobeOutline, type: ["buildings"] },
    waterBoiler: { icon: mdiWaterBoiler, type: ["energetics"] },
    wifi: { icon: mdiWifi, type: ["ikt"] },
    windowClosedVariant: { icon: mdiWindowClosedVariant, type: ["buildings"] },
    windTurbine: { icon: mdiWindTurbine, type: ["energetics"] },
  };
}

export default Category;
