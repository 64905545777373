<template>
  <v-tooltip
    v-if="priority"
    bottom
    open-delay="500"
    :disabled="
      $vuetify.breakpoint.smAndDown || !checkTranslation(priority.description)
    "
  >
    <template #activator="{ on }">
      <v-chip
        :color="priority.color"
        class="text-no-wrap"
        :class="[{ 'mb-1': bottomMargin }, textColor(priority.color)]"
        small
        style="max-width: 220px"
        :close="closable"
        v-on="on"
        @click:close="$emit('click:close', null)"
      >
        <v-icon left small>{{ mdiExclamationThick }}</v-icon>
        <span class="text-truncate">{{ getTranslation(priority.name) }}</span>
      </v-chip>
    </template>
    <span v-if="checkTranslation(priority.description)">{{
      getTranslation(priority.description)
    }}</span>
  </v-tooltip>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import ColorHelper from "../../../../_generic/mixins/ColorHelper";
import { mdiExclamationThick } from "@mdi/js";

export default {
  name: "IssuePriorityTag",
  mixins: [BasicMixin, ColorHelper],
  props: {
    priority: Object,
    closable: Boolean,
    bottomMargin: Boolean,
  },
  data: () => ({
    mdiExclamationThick,
  }),
};
</script>

<style scoped></style>
