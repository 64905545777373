<template>
  <v-menu v-model="active" bottom offset-y :open-on-click="false">
    <template #activator="{}">
      <v-text-field
        id="GlobalSearch"
        v-model="search"
        type="text"
        :loading="loading"
        :color="$vuetify.theme.dark ? 'text' : 'primary'"
        hide-details
        :placeholder="$t('form.hint.global_search')"
        autocomplete="off"
        class="search"
        @keydown.enter="$event.target.blur()"
        @keydown.esc="
          $event.target.blur();
          search = '';
        "
      >
        <v-tooltip slot="prepend-inner" bottom open-delay="1000">
          <template #activator="{ on }">
            <v-icon :color="$vuetify.theme.dark ? 'text' : 'primary'" v-on="on"
              >{{ mdiMagnify }}
            </v-icon>
          </template>
          <span
            >{{ $t("tooltips.navbar_icons.search") }} <br />
            {{ $t("tooltips.navbar_icons.search_shortcut") }}
          </span>
        </v-tooltip>
      </v-text-field>
    </template>
    <v-list v-if="!loading && search.length > 0" dense class="pb-0">
      <v-virtual-scroll
        :bench="2"
        :items="items"
        height="300"
        item-height="50"
        @scroll="loadNextItemsOnScroll"
      >
        <template #default="{ item }">
          <v-list-item
            :to="`/${fix_link(item.model, item.issue_id)}/${item.id}`"
          >
            <v-list-item-icon>
              <v-icon>{{ icons[item.model] }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                item.model === "issue" ? `#${item.id} ${item.name}` : item.name
              }}</v-list-item-title>

              <v-list-item-subtitle>{{
                $t(`${item.model}.${item.model}`)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-list>
  </v-menu>
</template>

<script>
import BasicMixin from "../../mixins/BasicMixin";
import {
  mdiMagnify,
  mdiAccountGroup,
  mdiOfficeBuilding,
  mdiFileDocument,
  mdiBriefcaseVariant,
  mdiWrench,
  mdiWarehouse,
  mdiCurrencyUsd,
  mdiClipboardOutline,
  mdiSpeedometer,
  mdiMessageOutline,
  mdiPackageVariantClosed,
  mdiFilter,
  mdiViewDashboardVariant,
  mdiEmailOutline,
  mdiCalendarMonth,
} from "@mdi/js";

export default {
  name: "MenuGlobalSearcher",
  mixins: [BasicMixin],
  data() {
    return {
      active: false,
      search: "",
      loading: false,
      loadingMore: false,
      items: [],
      page: 1,
      itemsPerPage: 20,
      mdiMagnify,
      icons: {
        asset: mdiOfficeBuilding,
        contract: mdiBriefcaseVariant,
        dashboard_screen: mdiViewDashboardVariant,
        data_set: mdiFilter,
        document: mdiFileDocument,
        issue_note: mdiMessageOutline,
        issue: mdiClipboardOutline,
        kpi: mdiSpeedometer,
        person: mdiAccountGroup,
        price_item: mdiPackageVariantClosed,
        price_list: mdiCurrencyUsd,
        service: mdiWrench,
        warehouse: mdiWarehouse,
        rule: mdiCalendarMonth,
        user: mdiAccountGroup,
        external_email: mdiEmailOutline,
      },
    };
  },
  watch: {
    search() {
      if (this.search && this.search.length >= 1) {
        this.loading = true;
        this.querySelections(this.search);
      } else {
        this.items = [];
        this.loading = false;
      }
    },
    "$route.path"() {
      // reset
      this.search = "";
      this.items = [];
      this.$nextTick(() => {
        this.$emit("close-searcher");
      });
    },
    items() {
      this.active = this.items.length > 0;
    },
  },
  methods: {
    loadNextItemsOnScroll(el) {
      if (
        Math.ceil(el.srcElement.offsetHeight + el.srcElement.scrollTop) >=
          el.srcElement.scrollHeight - 200 &&
        !this.loadingMore
      ) {
        this.loadingMore = true;
        this.page++;
        this.$http
          .get("search", {
            params: {
              query: this.search,
              page: this.page,
              itemsPerPage: this.itemsPerPage,
            },
          })
          .then(
            (response) => {
              this.items = this.items.concat(response.body);
              this.loadingMore = response.body.length < this.itemsPerPage;
            },
            () => {
              this.messageCustom("messages.globalSearchError", "error");
              this.loadingMore = false;
            }
          );
      }
    },
    querySelections(written) {
      this.page = 1;
      this.loadingMore = false;
      setTimeout(() => {
        if (this.search === written) {
          this.$http
            .get("search", {
              params: {
                query: this.search,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
              },
            })
            .then(
              (response) => {
                this.items = response.body;
                this.loading = false;
              },
              () => {
                this.messageCustom("messages.globalSearchError", "error");
                this.loading = false;
              }
            );
        }
      }, 500);
    },
    fix_link(to, issueId) {
      switch (to) {
        case "issue_note":
          return `issues/${issueId}/notes`;
        default:
          return this.modelPluralize(to);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  max-width: 400px;
}
</style>
