import _DatabaseModel from "./_DatabaseModel";

class RiskLevel extends _DatabaseModel {
  static model = "risk_level";
  static editDialogComponent = "risk-levels-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name", sortable: false, tag: true },
    { textKey: "form.label.maximum", value: "max", sortable: false },
    {
      textKey: "form.label.acceptable",
      value: "acceptable",
      sortable: false,
      align: "center",
    },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static cardFields(object) {
    return {
      title: object.name,
      actions: ["edit", "delete"],
    };
  }
}

export default RiskLevel;
