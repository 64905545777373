<template>
  <v-treeview
    :items="tree"
    item-children="children"
    item-text="name"
    item-key="tree_position"
    transition
    :open.sync="open"
    hoverable
    activatable
    :active.sync="active"
    dense
    class="clickable-tree-node"
    @update:active="selectTreeItem()"
  >
    <template #prepend="{ item }">
      <v-icon>
        {{ klass.categoryIcons[item.icon].icon }}
      </v-icon>
    </template>

    <template #label="{ item }">
      <div>{{ item.name }}</div>
    </template>
  </v-treeview>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import ReloadableMixin from "../../../../_generic/mixins/ReloadableMixin";

export default {
  name: "CategoriesTreeView",
  mixins: [BasicMixin, ReloadableMixin],
  props: {
    klass: Function,
  },
  data: () => ({
    tree: [],
    open: [],
    reloadingModels: ["category", "asset_category", "person_category"],
    active: [],
  }),
  watch: {
    "klass.model"() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    selectTreeItem() {
      let routeId;
      if (this.active.length > 0) {
        const parts = this.active[0].split("/");
        routeId = parts[parts.length - 2];
      }
      this.$router.push(
        this.active.length > 0
          ? `/${this.modelPluralize(this.klass.model)}/${routeId}`
          : `/${this.modelPluralize(this.klass.model)}`
      );
    },
    fetchData() {
      this.$http
        .get(`${this.modelPluralize(this.klass.model)}/tree`, {
          params: { id: this.$route.params.id },
        })
        .then(
          (response) => {
            this.tree = response.body.objects;
            this.open = response.body.open;
          },
          () => {
            this.messageCustom("messages.treeLoadError", "error");
          }
        );
    },
    reloadComponent() {
      this.fetchData();
    },
  },
};
</script>

<style scoped></style>
