<template>
  <v-row dense>
    <v-col cols="12">
      <v-select
        v-model="newCondition.field"
        :items="availableDateFields"
        :label="$t('form.label.time_filter')"
        hide-details
      />
    </v-col>

    <time-filter-form
      v-if="newCondition.field"
      ref="form"
      v-model="newCondition"
    >
      <v-col cols="auto" class="pt-6 ml-auto">
        <v-icon @click="addToInner()">{{ mdiPlus }}</v-icon>
      </v-col>
    </time-filter-form>

    <v-col v-for="(item, index) of innerCondition" :key="index" cols="12">
      <strong>{{ $t(dateFields[item.field]) }}</strong>
      <v-icon class="float-right" @click="removeFromInner(index)">
        {{ mdiClose }}
      </v-icon>
      <div>{{ timeFilterToString(item) }}</div>
    </v-col>
  </v-row>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import PrintingDate from "../../../../_generic/mixins/PrintingDate";
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import TimeFilterForm from "./TimeFilterForm.vue";
import { mdiClose, mdiPlus } from "@mdi/js";

export default {
  name: "FilterTimeCondition",
  components: { TimeFilterForm },
  mixins: [BasicMixin, PrintingDate, RulesMixin],
  props: {
    fields: Array,
    value: Array,
  },
  data: () => ({
    innerCondition: [],
    newCondition: {},
    dateFields: {
      created_at: "form.label.created_at",
      updated_at: "form.label.updated_at",
      planned_at: "issue.label.planned_at",
      resolve_at: "issue.label.resolve_at",
      closed_at: "issue.label.closed_at",
      sla_response_time_estimated: "issue.label.sla_response_time_estimated",
      sla_response_time_actual: "issue.label.sla_response_time_actual",
      sla_resolution_time_estimated:
        "issue.label.sla_resolution_time_estimated",
      sla_resolution_time_actual: "issue.label.sla_resolution_time_actual",
    },
    mdiClose,
    mdiPlus,
  }),
  computed: {
    availableDateFields() {
      const filtered = {};
      for (const f of this.fields) {
        if (!this.innerCondition.some((i) => i.field === f))
          filtered[f] = this.dateFields[f];
      }

      return this.basicMixin_toSelect(filtered);
    },
  },
  watch: {
    innerCondition() {
      this.$emit("input", this.innerCondition);
    },
    value: {
      handler() {
        this.innerCondition = this.value || [];
      },
      immediate: true,
    },
  },
  methods: {
    addToInner() {
      const tf = this.newCondition;
      if (!tf.field || !this.$refs.form.validate()) return;

      this.innerCondition.push(tf);
      this.newCondition = {};
    },
    removeFromInner(index) {
      this.innerCondition.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
