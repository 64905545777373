<template>
  <div>
    <v-autocomplete
      ref="atc"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :value="innerModel"
      :search-input.sync="search"
      :loading="loading"
      :readonly="autocompleteOff"
      :disabled="disabled"
      :hide-details="hideDetails"
      :multiple="multiple"
      :rules="getRule"
      :autofocus="autofocus"
      :items="itemsComputed"
      :append-outer-icon="appendIcon"
      item-text="name"
      item-value="id"
      return-object
      hide-no-data
      no-filter
      validate-on-blur
      :class="{ 'pt-0': noTopPadding, 'my-2': yMargin }"
      :solo="solo"
      :outlined="outlined"
      :dense="dense"
      @blur="items = []"
      @click:append-outer="$emit('append-click-method')"
      @input="submit($event)"
    >
      <template #selection="data">
        <v-chip
          :small="smallChips"
          close
          :disabled="disabled"
          @click:close="removeItem(data.index)"
        >
          <span class="chip-label">{{
            data.item.name ? data.item.name : data.item.id
          }}</span>
        </v-chip>
      </template>
      <template #append>
        <v-icon
          v-if="!searchOff && ['asset', 'person', 'document'].includes(type)"
          class="pl-1"
          :disabled="disabled"
          @click.stop="openDialog()"
          >{{ mdiFileTree }}
        </v-icon>
        <v-icon
          v-if="!scannerOff && ['asset'].includes(type)"
          class="pl-1"
          :disabled="disabled"
          @click.stop="openDialog_camera()"
          >{{ mdiBarcodeScan }}
        </v-icon>
        <v-icon
          v-if="!selfOff && type === 'person' && !alreadyAssigned"
          class="pl-1"
          :disabled="disabled"
          @click.stop="assignToMe()"
          >{{ mdiAccount }}
        </v-icon>
      </template>
    </v-autocomplete>

    <tree-select
      ref="tree_select"
      :title="title || label"
      :type="type"
      :multiselect="multiple"
      :filter="filter"
      @input="submit($event)"
    />

    <assets-qr-scanner ref="assets_qr_scanner" @input="submit($event)" />
  </div>
</template>

<script>
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import BaseVCard from "../../../../_generic/pages/components/base/BaseVCard";
import AssetsQrScanner from "./AssetsQrScanner";
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import TreeSelect from "./TreeSelect";
import { mdiFileTree, mdiBarcodeScan, mdiAccount } from "@mdi/js";

export default {
  name: "BaseSearcher",
  components: {
    TreeSelect,
    AssetsQrScanner,
  },
  mixins: [RulesMixin, BaseVCard, BasicMixin],
  props: {
    type: String,
    model: {
      type: [Object, Array],
    },
    value: {
      type: [Number, String, Array],
    },
    label: String,
    title: String,
    placeholder: String,
    hint: String,
    required: Boolean,
    hideDetails: Boolean,
    noTopPadding: Boolean,
    yMargin: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    solo: Boolean,
    outlined: Boolean,
    dense: Boolean,
    smallChips: {
      type: Boolean,
      default: true,
    },
    autocompleteOff: Boolean,
    searchOff: Boolean,
    scannerOff: Boolean,
    selfOff: Boolean,
    api: String,
    loadNames: Boolean,
    autofocus: Boolean,
    appendIcon: String,
    rules: {
      type: Array,
      default: () => [],
    },
    ids: {
      type: Array,
      default: undefined,
    },
    filter: Object,
  },
  data: () => ({
    innerModel: [],
    items: [],
    search: "",
    loading: false,
    mdiAccount,
    mdiBarcodeScan,
    mdiFileTree,
  }),
  computed: {
    alreadyAssigned() {
      const current_id =
        this.$store.getters["basic_module/get_current_user_person_id"];
      if (this.innerModel) {
        if (Array.isArray(this.innerModel)) {
          return !!this.innerModel.find((i) => i.id === current_id);
        } else {
          return this.innerModel.id === current_id;
        }
      }

      return false;
    },
    getRule() {
      if (this.required) {
        return this.rules.concat(this.customRules.field_required);
      } else {
        return this.rules;
      }
    },
    itemsComputed() {
      let newItems = this.items;
      if (this.innerModel) {
        newItems = newItems.concat(this.innerModel);
      }

      return newItems;
    },
  },
  watch: {
    model() {
      this.initData();
    },
    value() {
      this.initData();
    },
    search(newValue) {
      if (newValue && newValue.length >= 1 && !this.autocompleteOff) {
        this.loading = true;
        this.querySelections(newValue);
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    submit(item) {
      if (this.multiple) {
        // if multiple - push on submit
        if (item instanceof Array) {
          // input from autocomplete - type array
          this.innerModel = item;
        } else {
          this.innerModel.push(item);
        }
        this.$emit("update:model", this.innerModel);
        this.$emit(
          "input",
          this.innerModel.map((item) => item.id)
        );
      } else {
        // else replace single object
        this.innerModel = item;

        // send data
        this.$emit("update:model", this.innerModel);
        if (this.innerModel) {
          this.$emit("input", this.innerModel.id);
        } else {
          this.$emit("input", null);
        }
      }
      this.$refs.atc.resetValidation();
    },
    openDialog() {
      if (this.disabled) {
        return;
      }
      this.$refs.tree_select.openDialog(this.multiple ? this.innerModel : []);
    },
    openDialog_camera() {
      if (this.disabled) {
        return;
      }
      this.$refs.assets_qr_scanner.open_qr_scanner();
    },
    initData() {
      if (!this.loadNames) {
        // pass model to inner
        this.innerModel = this.model;
      } else {
        // load names for ids
        if (this.value) {
          // test if reloading of app is needed
          if (
            JSON.stringify(this.value) !==
            JSON.stringify([].concat(this.innerModel).map((item) => item.id))
          ) {
            this.$http
              .get(`${this.modelPluralize(this.type)}/load_names`, {
                params: {
                  ids: this.value,
                },
              })
              .then(
                (response) => {
                  this.innerModel = this.multiple
                    ? response.body.object
                    : response.body.object[0];
                },
                (response) => {
                  this.error_message("load", this.type, response);
                }
              );
          }
        } else {
          this.innerModel = [];
        }
      }
    },
    removeItem(index) {
      if (this.multiple) {
        // if multiple remove form array
        this.innerModel.splice(index, 1);
        this.$emit("update:model", this.innerModel);
        this.$emit(
          "input",
          this.innerModel.map((item) => item.id)
        );
      } else {
        // reset model
        this.innerModel = null;
        this.$emit("update:model", null);
        this.$emit("input", null);
      }
    },
    querySelections(written) {
      setTimeout(() => {
        if (this.search === written) {
          this.$http
            .get(
              `${this.modelPluralize(this.type)}/${
                this.api || "load_autocomplete"
              }`,
              {
                params: {
                  filter: { name: this.search, ...this.filter },
                  ids: this.ids,
                },
              }
            )
            .then(
              (response) => {
                this.items = response.body.objects;
                this.loading = false;
              },
              () => {
                this.messageCustom("messages.globalSearchError", "error");
                this.loading = false;
              }
            );
        }
      }, 500);
    },
    assignToMe() {
      this.submit({
        id: this.$store.getters["basic_module/get_current_user_person_id"],
        name: this.$store.getters["basic_module/get_current_user_name"],
      });
      this.$refs.atc.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 190px;
}
</style>
