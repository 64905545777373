<template>
  <div>
    <v-alert
      v-if="error"
      outlined
      type="warning"
      :icon="mdiAlertCircle"
      text
      class="mt-8 mx-4 text-left"
    >
      {{ $t("messages.mapTooMuchData") }}
    </v-alert>

    <custom-map
      v-else
      :features="features"
      :center="center"
      :height="mapHeight"
      :zoom="1"
      save-zoom
      center-markers
      :no-overlay="noOverlay"
      :draggable="draggable"
      :klass="klass"
      @selected-item="$emit('selected-item', $event)"
    />
  </div>
</template>

<script>
import CustomMap from "../../locations/CustomMap";
import ReloadableMixin from "../../../../_generic/mixins/ReloadableMixin";
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import Category from "../../../../_generic/classes/Category";
import { mdiAlertCircle } from "@mdi/js";

export default {
  name: "DataTableMap",
  components: { CustomMap },
  mixins: [ReloadableMixin, BasicMixin],
  props: {
    klass: Function,
    reloadingModels: Array,
    noOverlay: Boolean,
    draggable: Boolean,
    tabItem: String,
  },
  data: () => ({
    objects: [],
    categoryIcons: Category.categoryIcons,
    error: false,
    center: [0, 0],
    mdiAlertCircle,
  }),
  computed: {
    mapHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "calc(100svh - 165px)";
      } else if (this.$vuetify.breakpoint.smOnly) {
        return "calc(100svh - 142px)";
      } else return "calc(100svh - 215px)";
    },
    features() {
      return this.objects.map((item) => {
        const category = this.$store.getters["loadable/get_list"](
          "category"
        ).find(({ id }) => id === item.category_id);
        let color = "";
        if (item.assignees) {
          if (item.assignees.length === 0) {
            color = "#00f";
          } else {
            for (let i = 0; i < item.assignees.length; i++) {
              if (typeof item.assignees[i].plan_start === "string") {
                color = "#f00";
                break;
              } else {
                color = "#00f";
              }
            }
          }
        } else {
          color = "#00f";
        }
        return {
          type: "Feature",
          id: item.id,
          properties: {
            item: item,
            name: item.name,
            url: `${this.modelPluralize(this.klass.model)}/${item.id}`,
            color: color,
            id: item.id,
            img: `${
              this.categoryIcons[(category && category.icon) || "marker"].icon
            }`,
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(item.location.longitude),
              parseFloat(item.location.latitude),
            ],
          },
        };
      });
    },
  },
  watch: {
    tabItem() {
      if (this.tabItem === "map") {
        this.fetchData();
      }
    },
    "klass.model"() {
      if (this.tabItem === "map") {
        this.fetchData();
      }
    },
    "$route.query"(newVar, oldVar) {
      // fix triggering fetchData when query not changes
      if (
        Object.entries(newVar).toString() !==
          Object.entries(oldVar).toString() &&
        this.tabItem === "map"
      ) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get(`${this.modelPluralize(this.klass.model)}/load_map`, {
          params: {
            filter: this.$route.query,
          },
        })
        .then(
          (response) => {
            this.objects = response.body.objects;
            this.error = false;
            if (this.objects.length > 0) {
              this.center = [
                parseFloat(this.objects[0].location.longitude),
                parseFloat(this.objects[0].location.latitude),
              ];
            }
          },
          (response) => {
            if (response.body.too_much_data) {
              this.error = true;
            } else {
              this.error_message("load", this.klass.model, response);
            }
          }
        );
    },
    reloadComponent() {
      this.fetchData();
    },
  },
};
</script>

<style scoped></style>
