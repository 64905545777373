import _DatabaseModel from "./_DatabaseModel";

class Reminder extends _DatabaseModel {
  static model = "reminder";
  static showSize = 0;
  static editDialogComponent = "reminders-edit-dialog";

  static tableHeaders = [
    { textKey: "reminder.label.message", value: "message" },
    { textKey: "issue.issue", value: "issue.name" },
    { textKey: "form.label.created_at", value: "created_at" },
    { textKey: "reminder.label.notification_time", value: "notification_time" },
    {
      textKey: "notification.tooltip.mail",
      value: "mail",
      align: "center",
      sortable: false,
    },
    {
      textKey: "notification.tooltip.push",
      value: "push",
      align: "center",
      sortable: false,
    },
    {
      textKey: "notification.tooltip.sms",
      value: "sms",
      align: "center",
      sortable: false,
    },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static cardFields(object) {
    return {
      title: object.message,
      time: object.notification_time,
      actions: ["mail", "push", "sms", "edit", "delete"],
      linkToIssue: true,
    };
  }
}

export default Reminder;
