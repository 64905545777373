<template>
  <v-menu v-model="menu">
    <template #activator="{ on }">
      <v-icon v-on="on">{{ mdiHelpCircleOutline }}</v-icon>
    </template>
    <v-card>
      <v-card-text class="px-0 py-2">
        <v-row dense>
          <v-col class="text-right" tag="strong" cols="6">
            {{ $t(`template.label.required`) }}:
          </v-col>

          <v-col cols="6">
            {{
              field.required
                ? $t(`template.label.yes`)
                : $t(`template.label.no`)
            }}
          </v-col>
          <v-col class="text-right" tag="strong" cols="6">
            {{ $t(`template.label.unique`) }}:
          </v-col>

          <v-col cols="6">
            {{
              field.unique ? $t(`template.label.yes`) : $t(`template.label.no`)
            }}
          </v-col>

          <template
            v-if="
              field.unique &&
              ['Text', 'String', 'Integer'].includes(field.data_type)
            "
          >
            <v-col class="text-right" tag="strong" cols="6">
              {{ $t(`template.label.uniqueLastValue`) }}:
            </v-col>

            <v-col cols="6">
              <v-skeleton-loader
                :loading="loading"
                type="text@1"
                class="mx-auto"
                :class="{ 'mt-1 mr-2': loading }"
              >
                {{ last_field_value }}
              </v-skeleton-loader>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import { mdiHelpCircleOutline } from "@mdi/js";

export default {
  name: "InfoTooltip",
  components: {},
  mixins: [BasicMixin],
  props: {
    field: Object,
  },
  data: () => ({
    menu: false,
    loading: false,
    last_field_value: null,
    mdiHelpCircleOutline,
  }),
  watch: {
    menu() {
      this.loadLastUniqValue();
    },
  },
  methods: {
    loadLastUniqValue() {
      if (
        !this.menu ||
        !this.field.unique ||
        !["Text", "String", "Integer"].includes(this.field.data_type)
      ) {
        return;
      }

      this.loading = true;

      this.$http
        .get(
          `template_assignments/${this.field.template_assignment_id}/last_field_value`,
          { params: { key: this.field.key, type: this.field.data_type } }
        )
        .then(
          (response) => {
            this.last_field_value = response.body.value;
            this.loading = false;
          },
          (response) => {
            this.error_message("load", "template", response);
          }
        );
    },
  },
};
</script>

<style scoped></style>
