<template>
  <div>
    <v-row v-for="(field, index) in fields" :key="index" dense>
      <v-col v-if="fieldOn(field, 'String')">
        <v-text-field
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          type="text"
          :readonly="readonly"
          :rules="get_rule(field)"
          validate-on-blur
          :disabled="disabled"
        >
          <template #append>
            <info-tooltip :field="field" />
          </template>
        </v-text-field>
      </v-col>

      <v-col v-if="fieldOn(field, 'Integer')">
        <v-text-field
          v-model.number="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          type="number"
          :readonly="readonly"
          :rules="get_rule(field)"
          validate-on-blur
          :disabled="disabled"
        >
          <template #append>
            <info-tooltip :field="field" />
          </template>
        </v-text-field>
      </v-col>

      <v-col v-if="fieldOn(field, 'Text')">
        <v-textarea
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          :rules="get_rule(field)"
          validate-on-blur
          :disabled="disabled"
          auto-grow
          rows="2"
        >
          <template #append>
            <info-tooltip :field="field" />
          </template>
        </v-textarea>
      </v-col>

      <v-col v-if="fieldOn(field, 'Date')">
        <input-date-picker-dialog
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          :rules="get_rule(field)"
          :disabled="disabled"
        ></input-date-picker-dialog>
      </v-col>

      <v-col v-if="fieldOn(field, 'Time')">
        <input-time-picker-dialog
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          :rules="get_rule(field)"
          :disabled="disabled"
        ></input-time-picker-dialog>
      </v-col>

      <v-col v-if="fieldOn(field, 'DateTime')">
        <input-date-time-picker-dialog
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          :rules="get_rule(field)"
          :disabled="disabled"
        ></input-date-time-picker-dialog>
      </v-col>

      <v-col v-if="fieldOn(field, 'Boolean')">
        <v-checkbox
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          true-value="1"
          false-value="0"
          :disabled="disabled"
        ></v-checkbox>
      </v-col>

      <v-col v-if="fieldOn(field, 'Switch')">
        <v-switch
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          true-value="1"
          false-value="0"
          :disabled="disabled"
        ></v-switch>
      </v-col>

      <v-col v-if="fieldOn(field, 'Select')">
        <v-autocomplete
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          item-value="id"
          item-text="name"
          :filter="customFilter"
          clearable
          :multiple="field.multiselect"
          :items="
            field.condition ? toSelectCondition(field) : toSelect(field.options)
          "
          :readonly="readonly"
          :rules="get_rule(field)"
          :disabled="disabled"
          @change="onChange(field)"
        ></v-autocomplete>
      </v-col>

      <v-col v-if="fieldOn(field, 'Picture') && !isPublic">
        <input-file
          v-model="images[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :disabled="disabled"
          :rules="get_rule(field)"
          accept="image/*"
        />
      </v-col>

      <v-col v-if="fieldOn(field, 'Person') && !isPublic">
        <base-searcher
          v-model="value[field.key]"
          type="person"
          :label="getTranslation(field.name)"
          :rules="get_rule(field)"
          :multiple="field.multiselect"
          :disabled="disabled"
          load-names
        />
      </v-col>

      <v-col v-if="fieldOn(field, 'Asset') && !isPublic">
        <base-searcher
          v-model="value[field.key]"
          type="asset"
          :label="getTranslation(field.name)"
          :rules="get_rule(field)"
          :multiple="field.multiselect"
          :disabled="disabled"
          load-names
        ></base-searcher>
      </v-col>

      <v-col v-if="fieldOn(field, 'Issue') && !isPublic">
        <base-searcher
          v-model="value[field.key]"
          type="issue"
          :label="getTranslation(field.name)"
          :rules="get_rule(field)"
          :disabled="disabled"
          load-names
        ></base-searcher>
      </v-col>

      <v-col v-if="fieldOn(field, 'Radio')">
        <v-radio-group
          v-model="value[field.key]"
          hide-details="auto"
          :label="getTranslation(field.name)"
          :readonly="readonly"
          :rules="get_rule(field)"
          :disabled="disabled"
        >
          <v-radio
            v-for="(value1, key) in field.condition
              ? toSelectCondition(field)
              : field.options"
            :key="key"
            :label="field.condition ? value1.name : getTranslation(value1)"
            :value="field.condition ? value1.id : key"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="fieldOn(field, 'Password') && !isPublic">
        <input-password
          v-model="value[field.key]"
          :rules="get_rule(field)"
          :label="getTranslation(field.name)"
          :disabled="disabled"
        ></input-password>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicMixin from "../../../../_generic/mixins/BasicMixin";
import RulesMixin from "../../../../_generic/mixins/RulesMixin";
import InputDatePickerDialog from "../../../../_generic/pages/components/inputs/InputDatePickerDialog";
import InputTimePickerDialog from "../../../../_generic/pages/components/inputs/InputTimePickerDialog";
import InputFile from "../../../../_generic/pages/components/inputs/InputFile";
import InfoTooltip from "./InfoTooltip";
import BaseSearcher from "../../components/searcher/BaseSearcher";
import InputPassword from "../../../../_generic/pages/components/inputs/InputPassword";
import InputDateTimePickerDialog from "../../../../_generic/pages/components/inputs/InputDateTimePickerDialog";

export default {
  name: "TemplateVisualizationInner",
  components: {
    InputPassword,
    InfoTooltip,
    InputTimePickerDialog,
    InputDatePickerDialog,
    InputFile,
    BaseSearcher,
    InputDateTimePickerDialog,
  },
  mixins: [BasicMixin, RulesMixin],
  props: {
    readonly: Boolean,
    disabled: Boolean,
    value: Object,
    fieldAssignments: {
      type: Array,
      default: () => [],
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: {},
    };
  },
  computed: {
    fields() {
      return this.fieldAssignments.map((fa) => {
        return {
          ...fa.field,
          required: fa.required,
          unique: fa.unique,
          template_assignment_id: fa.template_assignment_id,
        };
      });
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    images() {
      this.$emit("select-images", this.images);
    },
  },
  methods: {
    fieldOn(field, type) {
      return (
        (field.data_type === type && !field.condition) ||
        this.conditionFieldTypeMatch(field, type)
      );
    },
    conditionFieldTypeMatch(field, type) {
      if (field.condition) {
        const options = this.getOptions(field);
        return options.length > 0 && field.data_type === type;
      } else {
        return false;
      }
    },
    toSelectCondition(field) {
      const options = this.getOptions(field);
      return options.map((item) => {
        return {
          id: item[0],
          name: this.getTranslation(field.options[item[0]]),
        };
      });
    },
    getOptions(field) {
      const key = this.value[field.condition.field_key];
      return Object.entries(field.condition.value).filter((i) =>
        [].concat(i[1]).includes(key)
      );
    },
    onChange(field) {
      const items = this.fields.filter(
        (f) => f.condition && f.condition.field_key === field.key
      );
      for (const item of items) {
        const noMatch =
          item.condition.value[this.value[item.key]] !== this.value[field.key];
        if (!this.value[field.key] || noMatch) {
          this.value[item.key] = null;
          this.onChange(item);
        }
      }
    },
    toSelect(options) {
      return Object.keys(options).map((item) => {
        return {
          id: item,
          name: this.getTranslation(options[item]),
        };
      });
    },
    get_rule(item) {
      if (!this.readonly && item.required) {
        return this.customRules.field_required;
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped></style>
