import Vue from "vue";
import VueResource from "vue-resource";
import { ScopeSet } from "@azure/msal-common";

Vue.use(VueResource);

Vue.http.interceptors.push(function (request, next) {
  // intercept response
  next(function (response) {
    if (response.status === 401 && response.body.reason === "jwt") {
      this.$store.commit("app_state/set_auth", false);
      // TODO: Not sure about this
      if (
        this.$store.state.basic_module.settings.aad &&
        this.$msal.isAuthenticated()
      ) {
        const scopes = new ScopeSet([
          this.$store.state.basic_module.settings.aad.scope,
        ]);
        return this.$msal.acquireToken(scopes).then((token) =>
          Vue.http
            .post(
              "login",
              { aad_access_token: token.accessToken },
              { dataType: "json", contentType: "application/json" }
            )
            .then(() =>
              Vue.http(request).then((data) => {
                return data;
              })
            )
        );
      } else {
        this.$vuetify.theme.dark = false;
        this.$store.dispatch("loadable/resetAllItems");
        this.$store.commit("app_state/reset");
        this.messageCustom("messages.pageLoadAuthenticationError", "error");
        if (this.$route.path !== "/public/auth/login") {
          this.$router.push("/public/auth/login");
        }

        this.$store.state.basic_module.loaded_next = "/";
        this.$store.state.basic_module.loaded_next_query = {};
      }
    }
  });
});

export default {};
