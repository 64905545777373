<template>
  <v-data-table
    :headers="[
      { value: 'labelKey', cellClass: 'label-column' },
      { value: 'value' },
    ]"
    :items="
      klass
        .basicInfo(object)
        .filter((i) => i.condition || i.condition === undefined)
    "
    hide-default-header
    hide-default-footer
    disable-sort
    disable-pagination
    dense
    :mobile-breakpoint="0"
    style="width: 100%"
  >
    <template #item.labelKey="{ item }">
      <span>{{ $t(item.labelKey) }}</span>
    </template>

    <template #item.value="{ item }">
      <div>
        <!--        PLAIN TEXT       -->
        <div v-if="item.type === 'plain' && item.value" class="text-pre-wrap">
          <span>{{ item.value }}</span>
        </div>

        <template v-if="item.type === 'notification_show'">
          <ul class="pl-0">
            <li v-for="i of item.value" :key="i">
              {{ $t(`notification.types.${i}`) }}
            </li>
          </ul>
        </template>

        <template v-if="item.type === 'interval'">
          <!--        INTERVAL       -->
          {{ basicMixin_translateCountBackEndDate(item.value) }}
        </template>

        <template v-if="item.type === 'boolean'">
          <!--        BOOLEAN       -->
          {{ basicMixin_translateTrueFalse(item.value) }}
        </template>

        <!--        LINK      -->
        <link-checked
          v-if="item.type === 'link' && item.value"
          :id="item.value.id"
          :to="item.to"
          :text="item.value.name || item.value.email"
          :discarded-at="item.value.discarded_at"
        />

        <template v-if="item.type === 'link_array' && item.value">
          <!--        LINK ARRAY     -->

          <div
            v-if="
              isModel('issue') && item.value?.length > 1 && item.to === 'assets'
            "
            class="d-flex align-center"
          >
            <span class="pr-1">{{ item.value.length }}</span>
            <span class="link" @click="$emit('open-assets-issues-tab')">{{
              $t("asset.label.view_all")
            }}</span>
          </div>
          <div v-else class="d-flex flex-row flex-wrap">
            <div
              v-for="(u, index_link) in item.value"
              :key="`link_${index_link}`"
            >
              <link-checked
                :id="u.id"
                :to="item.to"
                :text="u.name"
                :discarded-at="u.discarded_at"
                :append-text="index_link !== item.value.length - 1 ? ',' : ''"
              />
            </div>
          </div>
        </template>

        <template v-if="item.type === 'issue_links_array' && item.value">
          <!--        ISSUE LINKS ARRAY     -->
          <div
            v-for="(u, index_link) in item.value"
            :key="`link_${index_link}`"
          >
            <link-checked
              :id="u.id"
              to="issues"
              :text="`#${u.id} ${u.name}`"
              :discarded-at="u.discarded_at"
            />
          </div>
        </template>

        <!--        ISSUE STATE       -->
        <issue-state-tag
          v-if="item.type === 'issue_state' && item.value"
          :state="item.value"
        />

        <!--        ISSUE PRIORITY       -->
        <issue-priority-tag
          v-if="item.type === 'issue_priority' && item.value"
          :priority="item.value"
        />

        <template v-if="item.type === 'date'">
          <!--        DATE        -->
          <span v-if="item.value">
            {{ item.value | moment(date_format()) }}
          </span>

          <template v-else-if="item.display_unset">
            {{ $t("generic.unset") }}
          </template>
        </template>

        <!--        DATE TIME      -->
        <base-tooltip-date-data
          v-if="item.type === 'date_time' && item.value"
          :datetime="item.value"
        />

        <!--        SLA PLAIN and DATE TIME     -->
        <v-row
          v-if="item.type === 'plain_sla' && item.value"
          class="text-pre-wrap"
        >
          <v-col cols="12" sm="6">
            <span>{{ $t("sla.label.response") }}:</span>
            <span>{{ item.value[0] }}</span>
          </v-col>
          <v-col cols="12" sm="6">
            <span>{{ $t("sla.label.resolution") }}:</span>
            <span>{{ item.value[1] }}</span>
          </v-col>
        </v-row>

        <v-row v-if="item.type === 'date_time_sla' && item.value">
          <v-col v-if="item.value[0]" cols="12" sm="6">
            <span>{{ $t("sla.label.response") }}:</span>
            <base-tooltip-date-data :datetime="item.value[0]" />
          </v-col>
          <v-col v-if="item.value[1]" cols="12" sm="6">
            <span>{{ $t("sla.label.resolution") }}:</span>
            <base-tooltip-date-data :datetime="item.value[1]" />
          </v-col>
        </v-row>

        <!--        ICON      -->
        <v-icon v-if="item.type === 'icon' && item.value">
          {{ categoryIcons[item.value].icon }}
        </v-icon>

        <!--        FILE SIZE      -->
        <template v-if="item.type === 'file_size' && item.value">
          {{ convertFileSize(item.value) }}
        </template>

        <!--        PUBLIC LINK    -->
        <div v-if="item.type === 'public_link'">
          {{ basicMixin_translateTrueFalse(item.value) }}
          <v-btn
            v-if="item.value"
            :color="$vuetify.theme.dark ? 'text' : 'primary'"
            small
            icon
            :href="`/#/public/${modelPluralize(klass.model)}/${
              object.public_token
            }`"
            target="_blank"
          >
            <v-icon small>
              {{ mdiOpenInNew }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import BasicMixin from "../../../../../_generic/mixins/BasicMixin";
import PrintingDate from "../../../../../_generic/mixins/PrintingDate";
import BaseTooltipDateData from "../../../../../_generic/pages/components/base/BaseTooltipDateData";
import IssueStateTag from "../../../issues/components/IssueStateTag";
import LinkChecked from "../../LinkChecked";
import IssuePriorityTag from "../../../issues/components/IssuePriorityTag";
import Category from "../../../../../_generic/classes/Category";
import { mdiOpenInNew } from "@mdi/js";

export default {
  name: "DataShowFields",
  components: {
    IssuePriorityTag,
    LinkChecked,
    IssueStateTag,
    BaseTooltipDateData,
  },
  mixins: [BasicMixin, PrintingDate],
  props: {
    object: Object,
    klass: Function,
  },
  data() {
    return {
      categoryIcons: Category.categoryIcons,
      mdiOpenInNew,
    };
  },
};
</script>

<style lang="scss">
.label-column {
  width: 40%;
}
</style>
