import _DatabaseModel from "./_DatabaseModel";

class Device extends _DatabaseModel {
  static model = "jwt_session";
  static bottomNav = { mobile: ["card"], desktop: ["card"] };
  static showSize = 7;
  static createNewObject = false;

  static tableHeaders = [
    { textKey: "form.label.updated_at", value: "updated_at" },
  ];

  static cardFields(object) {
    return {
      title: object.browser,
      subtitle: object.last_ip,
      actions: ["delete"],
      activeTag: true,
      time: object.updated_at,
    };
  }
}

export default Device;
