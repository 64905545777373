const BrandingMixin = {
  computed: {
    brand() {
      return this.$store.state.basic_module.settings.brand;
    },
    brandingMixin_appNameIcon() {
      if (!this.brand) return "";
      return `/images/branding/${this.brand}/logo.png`;
    },
    brandingMixin_appNameIconDark() {
      if (!this.brand) return "";
      return `/images/branding/${this.brand}/logo_dark.png`;
    },
    brandingMixin_backgroundImage() {
      if (!this.brand) return "";
      return `/images/branding/${this.brand}/background.jpg`;
    },
    brandingMixin_loadingImg() {
      if (!this.brand) return "";
      let suffix = this.$vuetify.theme.dark ? "dark" : "light";
      return `/images/branding/${this.brand}/loading_${suffix}.png`;
    },
    brandingMixin_logo() {
      if (!this.brand) return "";
      return `/images/branding/${this.brand}/logo.png`;
    },
  },
};

export default BrandingMixin;
