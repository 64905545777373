import _DatabaseModel from "./_DatabaseModel";
import { getTranslation } from "../mixins/HelperFunctions";

class PersonRelation extends _DatabaseModel {
  static model = "person_relation";
  static editDialogComponent = "person-relations-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.id", value: "id" },
    { textKey: "form.label.name", value: "name", translated: true },
    { textKey: "form.label.entity_type", value: "entity_type" },
    {
      textKey: "other.actions",
      value: "actions",
      align: "right",
      sortable: false,
    },
  ];

  static operations = ["export"];

  static customSort = false;

  static cardFields(object) {
    return {
      title: getTranslation(object.name),
      actions: ["edit", "delete"],
    };
  }
}

export default PersonRelation;
