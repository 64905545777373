import i18n from "../translations/i18n";
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiHelpCircleOutline,
} from "@mdi/js";

function toModel(data, pluralize = false) {
  if (data) {
    const model = data.replace(/([a-z\d])([A-Z])/g, "$1_$2").toLowerCase();
    if (pluralize) {
      return modelPluralize(model);
    } else {
      return model;
    }
  }
}

function toModelUpperCase(model) {
  return (
    model.charAt(0).toUpperCase() +
    model.slice(1).replace(/_([a-z])/g, (_, p) => p.toUpperCase())
  );
}

function modelPluralize(model) {
  if (model.match(/sis$/)) {
    return model.replace(/sis$/, "ses");
  } else if (model.match(/(x|ch|ss|sh)$/)) {
    return model.replace(/(x|ch|ss|sh)$/, "$1es");
  } else if (model.match(/([^aeiouy]|qu)y$/)) {
    return model.replace(/([^aeiouy]|qu)y$/, "$1ies");
  } else {
    return model.replace(/$/, "s");
  }
}

function checkTranslation(key) {
  if (key && key.includes("$t$")) {
    return i18n.te(`custom.${key.split("$t$")[1]}`);
  } else {
    return key && typeof key === "string" && key.length > 0;
  }
}

function getTranslation(key) {
  if (!key) {
    return "";
  } else if (key.split("$t$").length > 1) {
    return i18n.t(`custom.${key.split("$t$")[1]}`);
  } else {
    return key;
  }
}

function getChecklistMarkerIcon(solveStatus) {
  switch (solveStatus) {
    case "not_run":
      return mdiHelpCircleOutline;
    case "success":
      return mdiCheckCircleOutline;
    case "fail":
      return mdiCloseCircleOutline;
    case "warning":
      return mdiAlertCircleOutline;
  }
}

function getChecklistMarkerColor(solveStatus) {
  switch (solveStatus) {
    case "not_run":
      return "grey";
    case "success":
      return "green";
    case "fail":
      return "red";
    case "warning":
      return "orange";
  }
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function removeAccents(string) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export {
  toModel,
  toModelUpperCase,
  modelPluralize,
  getTranslation,
  checkTranslation,
  getChecklistMarkerIcon,
  getChecklistMarkerColor,
  capitalize,
  removeAccents,
};
