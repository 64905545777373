<template>
  <v-card-title color="primary" class="text-h5 py-2">
    <v-col cols="12" class="pa-0">
      <v-row no-gutters>
        <v-col cols="6" sm="9" class="d-flex align-center">
          <slot name="changeState"></slot>
          <v-slide-group
            v-if="['asset', 'person', 'document'].includes(model)"
            v-model="slideModel"
            :show-arrows="false"
            center-active
            class="pr-4 align-center"
          >
            <v-slide-item
              v-for="(b, index) in breadcrumbsItems"
              :key="index"
              class="pa-0"
              style="list-style: none"
            >
              <div class="d-flex flex-row">
                <span class="text-body-2 pr-3">/</span>
                <v-breadcrumbs-item :href="b.href" class="text-body-2 pr-3">{{
                  b.text.length > 20 ? b.text.slice(0, 20) + "..." : b.text
                }}</v-breadcrumbs-item>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col cols="6" sm="3" class="d-flex justify-end align-center">
          <slot name="report"></slot>
          <slot name="download"></slot>
          <slot name="apply"></slot>
          <slot name="edit"></slot>
          <slot name="menu"></slot>
          <base-icon-button
            v-if="closable"
            :icon="mdiClose"
            :tooltip-translation="$t('action.close')"
            @click="close"
          >
          </base-icon-button>
        </v-col>
      </v-row>
    </v-col>
  </v-card-title>
</template>

<script>
import BasicMixin from "../../../../mixins/BasicMixin";
import FilteringMixinFixQuery from "../../../../../_functions/mixins/FilteringMixinFixQuery";
import { mdiClose } from "@mdi/js";
import BaseIconButton from "../BaseIconButton";

export default {
  name: "BaseVCardTitleShow",
  components: { BaseIconButton },
  mixins: [BasicMixin, FilteringMixinFixQuery],
  props: {
    model: String,
    object: Object,
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mdiClose,
    slideModel: null,
  }),
  computed: {
    breadcrumbsItems() {
      if (
        Object.keys(this.object)?.length > 0 &&
        this.object.ancestors?.length > 0
      ) {
        return this.object.ancestors.map((i) => {
          return {
            text: i.name,
            href: `/#/${this.modelPluralize(this.model)}/${i.id}`,
          };
        });
      } else return [];
    },
  },
  watch: {
    breadcrumbsItems() {
      this.slideModel = this.breadcrumbsItems?.length - 1;
    },
  },

  methods: {
    close() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({
          path: `/${this.modelPluralize(this.model)}`,
          query: this.filteringMixin_fixData(this.$route.query),
        });
      } else this.$router.push(`/${this.modelPluralize(this.model)}`);
    },
  },
};
</script>

<style scoped></style>
