import _DatabaseModel from "./_DatabaseModel";

class Translation extends _DatabaseModel {
  static model = "translation";
  static editDialogComponent = "translations-edit-dialog";

  static tableHeaders = [
    { textKey: "translation.label.key", value: "key" },
    { textKey: "translation.label.en", value: "en" },
    { textKey: "translation.label.sk", value: "sk" },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static filter = {
    parts: {
      fields: ["key", "en", "sk"],
      extended_fields: [],
    },
  };

  static operations = ["export"];

  static cardFields(object) {
    return {
      title: object.key,
      infoWithLabel: {
        en: { label: "translation.label.en_short", key: "en" },
        sk: { label: "translation.label.sk_short", key: "sk" },
      },
      actions: ["edit", "delete"],
    };
  }
}

export default Translation;
