<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    offset-y
    transition="scale-transition"
    max-width="290px"
  >
    <template #activator="{ attrs }">
      <v-text-field
        ref="date_field"
        v-model="inner_date"
        v-mask="'####-##-##'"
        v-bind="attrs"
        :label="label"
        :hint="hint"
        :placeholder="$t('form.placeholder.date')"
        clearable
        :readonly="readonly"
        :append-icon="mdiCalendarMonth"
        :rules="allRules"
        :error-messages="errorMessages"
        validate-on-blur
        :hide-details="hideDetails"
        :outlined="outlined"
        :dense="dense"
        @click:append="open()"
        @click:clear="$emit('input', '')"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="inner_date_picker"
      color="primary"
      :first-day-of-week="firstDayOfWeek"
      @input="close()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import RulesMixin from "../../../mixins/RulesMixin";
import { mask } from "vue-the-mask";
import { mdiCalendarMonth } from "@mdi/js";
export default {
  name: "InputDatePickerDialog",
  directives: {
    mask,
  },
  mixins: [RulesMixin],
  props: {
    value: String,
    label: String,
    hint: String,
    hideDetails: [Boolean, String],
    outlined: Boolean,
    dense: Boolean,
    readonly: Boolean,
    errorMessages: Array,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    inner_date: null,
    inner_date_picker: null,
    mdiCalendarMonth,
  }),
  computed: {
    firstDayOfWeek() {
      if (
        this.$store.state.basic_module.personalization.week_first_day ===
        "monday"
      ) {
        return 1;
      } else {
        return 0;
      }
    },
    allRules() {
      return [
        (v) =>
          !v ||
          this.$moment(v, "YYYY-MM-DD", true).isValid() ||
          this.$t("form.error.valid"),
        ...this.rules,
      ];
    },
  },
  watch: {
    value: {
      handler() {
        this.inner_date = this.value;
        this.inner_date_picker = this.value;
      },
      immediate: true,
    },
    inner_date(newVar) {
      if (newVar) {
        if (!isNaN(Date.parse(newVar)) && [0, 10].includes(newVar.length)) {
          this.$emit("input", newVar);
        }
      } else {
        this.$emit("input", "");
      }
    },
    inner_date_picker(newVar) {
      if (newVar) {
        if (!isNaN(Date.parse(newVar))) {
          this.$emit("input", newVar);
        }
      } else {
        this.$emit("input", "");
      }
    },
  },
  methods: {
    open() {
      if (this.readonly) {
        return;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.date_field.focus();
    },
  },
};
</script>

<style scoped></style>
