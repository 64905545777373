<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :mini-variant="mini"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :expand-on-hover="mini"
    height="100%"
    class="pl-1"
    style="display: list-item"
  >
    <template v-if="logo" #prepend>
      <v-img :src="logo" height="56" contain class="ma-2"></v-img>
    </template>

    <v-list dense nav flat :class="logo ? 'fix-height' : 'pb-2'">
      <template v-for="(item, index) in menuItems">
        <v-list-group
          v-if="item.multiple && canSeeOneOfAppParts(item.perms_name_array)"
          :key="index"
          :value="openGroup(item.perms_name_array)"
          no-action
        >
          <template #activator>
            <template v-if="item.perms_name === 'profile'">
              <v-list-item-avatar class="ml-n2 mr-6">
                <base-avatar :src="user_avatar"></base-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user_email }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <template v-else>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t(item.title_t) }}</v-list-item-title>
            </template>
          </template>

          <v-list-item
            v-for="(sum_item, index2) in item.items.filter((i) =>
              canSeeAppPart(i.perms_name)
            )"
            :key="index2"
            :to="`/${sum_item.url}`"
            color="white"
            active-class="primary white--text"
          >
            <v-tooltip
              right
              open-delay="500"
              :disabled="!sum_item.tooltip || $vuetify.breakpoint.smAndDown"
            >
              <template #activator="{ on }">
                <v-list-item-title v-on="on">
                  {{ $t(sum_item.title_t) }}
                </v-list-item-title>
              </template>
              <span>{{ sum_item.tooltip }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { APP_MENU } from "../../../_config/constants";
import BasicMixin from "../../mixins/BasicMixin";
import BaseAvatar from "../components/base/BaseAvatar";

export default {
  name: "NavigationDrawer",
  components: { BaseAvatar },
  mixins: [BasicMixin],
  data: () => ({
    mini: false,
    drawer: null,
    menuItems: APP_MENU,
  }),
  computed: {
    user_avatar() {
      return this.$store.getters["basic_module/get_current_user_avatar"];
    },
    user_name() {
      return this.$store.getters["basic_module/get_current_user_name"];
    },
    user_email() {
      return this.$store.getters["basic_module/get_current_user_email"];
    },
    logo() {
      return this.$store.state.basic_module.settings.logo_url;
    },
  },
  methods: {
    change() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.mini = !this.mini;
      } else {
        this.mini = false;
        this.drawer = !this.drawer;
      }
      this.$emit("drawer-change", this.mini);
    },
    openGroup(array) {
      let route = this.$route.fullPath.split("/")[1];
      if (route.split("?").length > 0) {
        route = route.split("?")[0];
      }
      return !!array.includes(route);
    },
  },
};
</script>

<style scoped>
.fix-height {
  padding-bottom: 80px; /* logo height 56 + margin 16 + bottom padding 8 */
}
</style>
