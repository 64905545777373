<template>
  <div class="py-2">
    <v-simple-table dense>
      <tbody>
        <tr v-for="(diff, index) in computedDiffs" :key="index">
          <td style="width: 25%">{{ diff.attribute }}</td>
          <td>
            <div class="d-flex">
              <div
                v-if="diff.previous !== null && diff.previous !== undefined"
                class="text-decoration-line-through text--disabled px-2 mr-3"
                :class="previousClass"
                style="word-break: break-word"
              >
                {{ diff.previous }}
              </div>
              <div
                v-if="diff.current !== null && diff.current !== undefined"
                class="px-2"
                :class="currentClass"
                style="word-break: break-word"
              >
                {{ diff.current }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ActivityDiffCard",
  props: {
    activity: Object,
  },
  computed: {
    computedDiffs() {
      return this.activity.diff.map((a) => {
        return {
          attribute: a[1],
          previous: a[0] === "+" ? null : a[2],
          current: a[0] === "-" ? null : a[0] === "~" ? a[3] : a[2],
        };
      });
    },
    previousClass() {
      return this.$vuetify.theme.dark ? "error" : "error lighten-4";
    },
    currentClass() {
      return this.$vuetify.theme.dark ? "success" : "success lighten-4";
    },
  },
};
</script>

<style scoped></style>
