<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <base-v-card-title-dialog-intern :label="$t('first_login.welcome')">
        <base-locales-switch />
      </base-v-card-title-dialog-intern>

      <v-card-text class="pt-6">
        <div class="d-flex justify-space-between align-center">
          <v-checkbox v-model="cookies" class="flex-grow-1">
            <template #label>
              {{ $t("first_login.cookies") }}
            </template>
          </v-checkbox>
          <base-icon-button
            :icon="mdiMagnify"
            @click="$refs.cookies_dialog.open()"
          />
        </div>

        <div class="d-flex justify-space-between align-center">
          <v-checkbox v-model="gdpr" class="flex-grow-1">
            <template #label>
              {{ $t("first_login.personal_info_GDPR") }}
            </template>
          </v-checkbox>
          <base-icon-button
            :icon="mdiMagnify"
            @click="$refs.gdpr_dialog.open()"
          />
        </div>

        <div class="d-flex justify-space-between align-center">
          <v-checkbox v-model="license" class="flex-grow-1">
            <template #label>
              {{ $t("first_login.license") }}
            </template>
          </v-checkbox>
          <base-icon-button
            :icon="mdiMagnify"
            href="/EULA-AUSEMIO-2021-04-01.pdf"
            target="_blank"
          />
        </div>
      </v-card-text>

      <base-v-card-actions-buttons-dialog
        positive-translation="first_login.accept"
        :disabled="!cookies || !gdpr || !license"
        negative-translation="first_login.decline"
        :loading="actionsMixin_running"
        @positive="submit"
        @negative="logOutMixin_logout"
      ></base-v-card-actions-buttons-dialog>
    </v-card>

    <cookies-dialog ref="cookies_dialog"></cookies-dialog>

    <gdpr-dialog ref="gdpr_dialog"></gdpr-dialog>
  </v-dialog>
</template>

<script>
import LogOutMixin from "../../mixins/LogOutMixin";
import BasicMixin from "../../mixins/BasicMixin";
import CookiesDialog from "./first_login_dialogs/CookiesDialog";
import BaseVCard from "../components/base/BaseVCard";
import GdprDialog from "./first_login_dialogs/GdprDialog";
import BaseLocalesSwitch from "../components/base/BaseLocalesSwitch.vue";
import BaseIconButton from "../components/base/BaseIconButton";
import { mdiMagnify } from "@mdi/js";

export default {
  name: "FirstLoginDialog",
  components: { BaseIconButton, GdprDialog, CookiesDialog, BaseLocalesSwitch },
  mixins: [LogOutMixin, BasicMixin, BaseVCard],
  data: () => ({
    dialog: false,
    cookies: false,
    gdpr: false,
    license: false,
    mdiMagnify,
  }),
  mounted() {
    this.dialog =
      !this.$store.getters["basic_module/get_current_user_eula_accepted_at"];
  },
  methods: {
    submit() {
      if (this.cookies && this.gdpr && this.license) {
        const config = {
          method: "post",
          message: { action: "update", model: "user" },
          url: "profile/accept_eula",
          close_dialog: true,
        };
        this.actionsMixin_action(config, {});
      }
    },
  },
};
</script>

<style scoped></style>
