<template>
  <div>
    <v-btn
      text
      small
      rounded
      :max-height="20"
      :color="$vuetify.theme.dark ? 'text' : 'primary'"
      @click="$emit('prev')"
    >
      <v-icon>{{ mdiChevronLeft }}</v-icon>
    </v-btn>
    <v-menu
      v-model="dialog"
      :close-on-content-click="false"
      offset-y
      origin="center center"
      transition="scale-transition"
      class="d-inline-block"
    >
      <template #activator="{ on }">
        <v-btn
          text
          rounded
          :color="$vuetify.theme.dark ? 'text' : 'primary'"
          :height="20"
          v-on="on"
          @click="dialog = true"
        >
          {{ buttonText }}
        </v-btn>
      </template>

      <v-date-picker
        v-model="innerDate"
        width="300"
        class="elevation-0"
        color="primary"
        :type="['day', 'week'].includes(type) ? 'date' : 'month'"
        :first-day-of-week="firstDayOfWeek"
        @input="onInput($event)"
      />
    </v-menu>

    <v-tooltip bottom open-delay="500">
      <template #activator="{ on }">
        <v-btn
          icon
          small
          :color="$vuetify.theme.dark ? 'text' : 'primary'"
          @click="$emit('click-today')"
          v-on="on"
        >
          <v-icon small>{{ mdiCalendarToday }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("calendar.today") }}</span>
    </v-tooltip>

    <v-btn
      text
      small
      rounded
      :max-height="20"
      :color="$vuetify.theme.dark ? 'text' : 'primary'"
      @click="$emit('next')"
    >
      <v-icon>{{ mdiChevronRight }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight, mdiCalendarToday } from "@mdi/js";

export default {
  name: "CalendarDatePicker",
  props: {
    date: String,
    type: {
      type: String,
      default: "month",
    },
  },
  data: () => ({
    dialog: false,
    innerDate: null,
    mdiChevronLeft,
    mdiChevronRight,
    mdiCalendarToday,
  }),
  computed: {
    firstDayOfWeek() {
      return this.$store.state.basic_module.personalization.week_first_day ===
        "monday"
        ? 1
        : 0;
    },
    buttonText() {
      if (this.type === "month")
        return this.$moment(this.date).format("MMM YYYY");
      else if (this.type === "week")
        return `${this.$moment(this.date).format("MMM YYYY (W)")}`;
      else return this.$moment(this.date).format("DD MMM YYYY");
    },
  },
  watch: {
    date: {
      handler(newVar) {
        if (["day", "week"].includes(this.type)) {
          this.innerDate = newVar;
        } else {
          this.innerDate = this.$moment(newVar).format("YYYY-MM");
        }
      },
      immediate: true,
    },
  },
  methods: {
    onInput(event) {
      this.dialog = false;
      this.$emit("update:date", this.$moment(event).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style scoped></style>
