<template>
  <v-tooltip
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    open-delay="500"
    :disabled="$vuetify.breakpoint.smAndDown || !label"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-icon
          v-shortkey="shortKey"
          :class="[clazz]"
          :color="color"
          :small="small"
          :large="large"
          :size="size"
          :style="iconStyle"
          v-on="$listeners"
        >
          {{ icon }}
        </v-icon>
      </span>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "BaseTooltipIcon",
  props: {
    large: Boolean,
    small: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    color: String,
    icon: String,
    label: String,
    clazz: String,
    size: String,
    iconStyle: [Object, String],
    shortKey: Array,
  },
  computed: {
    top() {
      return Boolean(!this.bottom && !this.left && !this.right);
    },
  },
};
</script>

<style scoped></style>
