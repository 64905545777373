import _DatabaseModel from "./_DatabaseModel";

class PriceItemsWarehouse extends _DatabaseModel {
  static model = "price_items_warehouse";

  static tableHeaders = [
    {
      textKey: "warehouse.warehouse",
      value: "warehouse.name",
      sortable: false,
    },
    {
      textKey: "price_items_warehouse.price_items_warehouse",
      value: "price_item.name",
      sortable: false,
    },
    {
      textKey: "warehouse.label.balance",
      value: "balance",
      class: "text-no-wrap",
    },
  ];

  static customSort = false;

  static filter = {
    parts: {
      fields: ["warehouse_id", "price_item_id", "below_min", "above_opt"],
      extended_fields: [],
    },
  };

  static below_min_options = {
    true: "filter.below_min_levels.below",
    false: "filter.below_min_levels.above",
  };

  static above_opt_options = {
    true: "filter.above_opt_levels.above",
    false: "filter.above_opt_levels.below",
  };

  static cardFields(object) {
    return {
      title: object.price_item.name,
      subtitle: object.balance,
    };
  }
}

export default PriceItemsWarehouse;
