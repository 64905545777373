<template>
  <v-autocomplete
    v-model="inner"
    :items="items"
    item-value="id"
    item-text="name"
    :label="label ? label : $t(`form.label.${model}_id`)"
    :rules="getRule"
    :chips="!!inner"
    :small-chips="!!inner"
    :deletable-chips="!!inner"
    :filter="customFilter"
    :append-icon="appendIconComputed"
    :multiple="multiple"
    :return-object="returnObject"
    v-bind="$attrs"
    @click:append="inner = selectAllItems"
    @click:append-outer="$emit('append-outer')"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-autocomplete>
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import { mdiCheckAll } from "@mdi/js";
import RulesMixin from "../../../mixins/RulesMixin";

export default {
  name: "BaseLoadableAutocomplete",
  mixins: [RulesMixin, BasicMixin],
  props: {
    value: [String, Number, Array, Object],
    model: {
      type: String,
      required: true,
    },
    label: String,
    validate: Boolean,
    appendIcon: String,
    required: Boolean,
    multiple: Boolean,
    returnObject: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    transform: {
      type: Function,
      default: undefined,
    },
    onScreen: {
      type: Boolean,
      default: true,
    },
    without: Boolean,
    filter: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      mdiCheckAll,
    };
  },
  computed: {
    inner: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    items() {
      let items = this.$store.getters["loadable/get_list"](this.model);
      if (this.filter) {
        items = items.filter(this.filter);
      }
      if (this.transform) {
        items = items.map(this.transform);
      }
      if (this.without) {
        items = [
          {
            id: 0,
            name: this.$t(`${this.model}.label.without`),
          },
          ...items,
        ];
      }
      return items;
    },
    selectAllItems() {
      return this.items.filter((i) => i.id !== 0).map((i) => i.id);
    },
    getRule() {
      if (this.required) {
        return this.rules.concat(this.customRules.field_required);
      } else {
        return this.rules;
      }
    },
    appendIconComputed() {
      return this.appendIcon
        ? this.appendIcon
        : this.multiple && !this.returnObject
        ? mdiCheckAll
        : null;
    },
  },
  watch: {
    model() {
      this.$store.dispatch("loadable/fetchItems", this.model);
    },
    onScreen: {
      handler() {
        if (this.onScreen) {
          this.$store.dispatch("loadable/fetchItems", this.model);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
